import React from 'react';
import image4a from '../images/image4a.png';
import image4b from '../images/image4b.png';
import image4c from '../images/image4c.png';
import image4d from '../images/image4d.png';
import CaptionedImage from '../CaptionedImage';

const IntroContent = (
  <div>
    <h1 className="docsectiontitle">Unsubscribe</h1>
    <section className="sectionmaterial">
      <p>
        You can Unsubscribe to the “RocketCX Enterprise Connector” SaaS
        subscription by following the steps below:
      </p>
      <h2 className="subheading">Login to the AWS Console</h2>
      <CaptionedImage src={image4a} label="AWS Console" />
      <h2 className="subheading">Navigate to Marketplace Subscriptions page</h2>
      <p>
        Navigate to the Marketplace Subscriptions page as shown below by
        visiting this link:{' '}
        <a href="https://console.aws.amazon.com/marketplace/home">
          https://console.aws.amazon.com/marketplace/home
        </a>{' '}
        or by searching for “aws marketplace subscriptions” in the AWS’s search
        service field.
      </p>
      <CaptionedImage src={image4b} label="Manage Subscriptions" />
      <h2 className="subheading">Select the "RocketCX Enterprise Connector"</h2>
      <p>
        Click “Manage” button for the RocketCX Enterprise Connector’s
        Subscription as shown in the image below.
      </p>
      <CaptionedImage
        src={image4c}
        label='Select "RocketCX Enterprise Connector" subscription'
      />
      <h2 className="subheading">Cancel Subscription</h2>
      <p>
        Click “Cancel subscription” under “Actions” menu on top right corner as
        shown in the image below.
      </p>
      <CaptionedImage src={image4d} label="Cancel Subscription" />
    </section>
  </div>
);

export const UnsubscribeContent = {
  content: IntroContent,
};
