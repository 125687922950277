import { useRef, useEffect } from 'react';
import useOnFirstRun from './useOnFirstRun';

const useSignal = (setupController = (controller: AbortController) => {}) => {
  const controller = useRef(new AbortController());
  useOnFirstRun(() => {
    setupController(controller.current);
  });

  useEffect(() => {
    return () => {
      controller.current.abort();
    };
  }, []);

  function createNewAbortController(
    setupController = (controller: AbortController) => {}
  ) {
    controller.current = new AbortController();
    setupController(controller.current);
  }

  const { signal } = controller.current;

  return { signal, createNewAbortController };
};

export default useSignal;
