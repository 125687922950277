import React from 'react';
import image101a from '../images/101a.png';
import image101b from '../images/101b.png';
import image1021a from '../images/1021a.png';
import image1022a from '../images/1022a.png';
import image1022b from '../images/1022b.png';
import image1022c from '../images/1022c.png';
import image1023a from '../images/1023a.png';
import image103a from '../images/103a.png';
import image103b from '../images/103b.png';
import { Link } from 'react-router-dom';
import Screenshot from '../Screenshot';

const AdminContent = (
  <div>
    <h1 className="docsectiontitle">Administration</h1>
    <section className="sectionmaterial">
      <p>This section includes :</p>
      <ol>
        <li>
          <Link to="/user-guide/administration/amazon-connect-queue-records">
            Amazon Connect Queue Records
          </Link>
        </li>
        <li>
          <Link to="/user-guide/administration/configure-admin-console">
            Configure Admin Console
          </Link>
        </li>
        <li>
          <Link to="/user-guide/administration/disposition-codes">
            Disposition Codes
          </Link>
        </li>
      </ol>
    </section>
  </div>
);

const ACQRecordsContent = (
  <div>
    <h1 className="docsectiontitle">
      Create Amazon Connect Queue Records in ServiceNow
    </h1>
    <section className="sectionmaterial">
      <p>
        The Plug-in Administrator should add Connect Queues manually to enable
        RocketCX Enterprise Connector to fetch reporting metrics from Amazon
        Connect.
      </p>
      <p>
        To create a Connect Queue, click on the button <em>New</em> to add the
        name, ID, ARN details and save the record. To edit, open an existing
        record from the list and update.
      </p>
      <p>
        Users with Plug-In Administrator role can access Connect Queues using{' '}
        <em>
          <u>RocketCX > Connect Queues</u>
        </em>
      </p>
      <Screenshot src={image101a} />
      <h2 className="sectionsubtitle">
        Steps to create or edit Amazon Connect Queues :
      </h2>
      <ol>
        <li>
          Click on button <em>New</em> and select the checkbox for Active. Enter
          the ARN, Queue ID/Amazon ID and name of the queue. Click on Submit.
          <Screenshot src={image101b} />
        </li>
        <li>
          To edit an existing Connect Queue, click on the record from the list.
        </li>
      </ol>
    </section>
  </div>
);

const AdminConsoleContent = (
  <div>
    <h1 className="docsectiontitle">Configure Admin Console</h1>
    <section className="sectionmaterial">
      <p>
        Log in as a Plug-in Administrator or If you have logged in as ServiceNow
        Administrator, change the application scope to <strong>RocketCX</strong>
        , search for <strong>RocketCX</strong> in the left navigation bar and
        click on Admin Console under the Administration module.
      </p>
      <p>
        The Admin Console page will display three tabs Amazon Connect,
        ServiceNow and RocketCX.
      </p>
    </section>
  </div>
);

const AmazonConnectContent = (
  <div>
    <h1 className="docsectiontitle">Amazon Connect Tab</h1>
    <section className="sectionmaterial">
      <p>
        The Amazon Connect tab contains the following mandatory properties which
        are required to set up connectivity between ServiceNow and Amazon
        Connect.
      </p>
      <ol>
        <li>
          <strong>Amazon Connect CCP URL</strong>: URL to your Amazon Connect
          Instance CCP.
        </li>
        <li>
          <strong>S3 Bucket Name</strong>: Name of S3 Bucket where call
          recordings are getting saved.
        </li>
        <li>
          <strong>S3 Bucket Region</strong>: Region of S3 Bucket.
        </li>
        <li>
          <strong>Amazon Connect Instance Region</strong>: Region of your Amazon
          Connect Instance.
        </li>
        <li>
          <strong>Amazon Connect Instance ID</strong>: The Amazon Connect
          Instance ID can be found from Instance ARN.
        </li>
        <li>
          <strong>Amazon Connect Access Key ID</strong>: Access Key ID will be
          used to gain programmatic (API) access to AWS services.
        </li>
        <li>
          <strong>Amazon Connect Secret Access Key</strong>: Secret Access Key
          will be used to gain programmatic (API) access to AWS services.
        </li>
      </ol>
      <p>
        The Amazon Connect Access Key ID and Secret Access Key can be copied
        from the IAM User Account in AWS. Steps to set up IAM User are already
        mentioned under “AWS IAM User”
      </p>
      <Screenshot src={image1021a} />
      <p>
        Once you have entered all the properties on the above tab, click on
        “Test Connection” button. If you get an error message, please correct
        them with the help of an AWS Admin.
      </p>
    </section>
  </div>
);

const ServiceNowContent = (
  <div>
    <h1 className="docsectiontitle">ServiceNow</h1>
    <section className="sectionmaterial">
      <p>
        The ServiceNow tab holds related record and contact table properties.
      </p>
      <ol>
        <li>
          <strong>Related Record tables</strong>: RocketCX Enterprise Connector
          supports Incident Management, Customer Service and Human Resource Core
          modules. The Related Record Table control will display core tables
          from each module for selection. On CCP, an Agent can see the related
          records for the modules selected under header Related Record Tables on
          ServiceNow tab of Admin Console.
        </li>
        <li>
          <strong>Contact Tables</strong>: Contact tables are available for
          selection based on the above-mentioned active modules. Contact tables
          will be used to display a list of contacts on the CCP.
        </li>
        <li>
          <strong>Enable Reporting</strong>: This property enables Service
          Centre Dashboard to display Real-time and Historical Metrics.
        </li>
      </ol>
      <Screenshot src={image1022a} />
      <p>
        The administrator needs to select at least one related record table,
        which will allow CCP with access to different modules.
      </p>
      <p>
        For example, if you want to enable CCP for the Incident module, you
        should select the Incident table from
        <em>Related Record Tables</em> and move it to <em>Selected Tables</em>{' '}
        from <em>Available Tables</em>.
      </p>
      <p>
        On selecting the related record table, contact tables associated with it
        get automatically selected but this selection can be modified as per
        user’s requirement.
      </p>
      <p>Steps:</p>
      <ol>
        <li>
          Select the related record table on which you want to enable the CCP.
        </li>
        <li>
          The Service Centre Dashboard can be enabled/disabled using the On/Off
          switch available on ServiceNow tab of Admin console.
          <Screenshot src={image1022b} />
        </li>
        <li>
          Validations are applied to mandatory fields. If the user tries to save
          the properties without entering details in any of the mandatory
          fields, the following message will be displayed.
          <Screenshot src={image1022c} />
        </li>
        <li>
          Click on the Save button; this will save the properties from the
          Amazon Connect and ServiceNow tabs.
        </li>
      </ol>
    </section>
  </div>
);

const RocketCXContent = (
  <div>
    <h1 className="docsectiontitle">RocketCX</h1>
    <section className="sectionmaterial">
      <p>
        Usage of RocketCX CCP and Service Centre Dashboard is restricted until
        you have registered on the RocketCX and configured a new Instance for
        ServiceNow. You will then need to provide the following properties:
      </p>
      <ul>
        <li>
          <strong>RocketCX Username</strong>: This is the username used while
          creating a new instance on RocketCX.
        </li>
        <li>
          <strong>RocketCX Password</strong>: This is the password used while
          creating a new instance on RocketCX.
        </li>
        <li>
          <strong>RocketCX Instance ID</strong>: This is the Instance ID
          generated once a new instance has created on RocketCX.
        </li>
      </ul>
      <Screenshot src={image1023a} />
      <ol>
        <li>
          Click on the Test Connection button. If the connection is successful,
          you can save the properties.
        </li>
        <li>
          Next, reload the page to start using RocketCX CCP and the Service
          Centre Dashboard.
        </li>
      </ol>
    </section>
  </div>
);

const DispositionCodesContent = (
  <div>
    <h1 className="docsectiontitle">Disposition Codes</h1>
    <section className="sectionmaterial">
      <p>
        The Plug-In Administrator can add, modify or delete Disposition codes
        which will be used in CCP to store wrap up notes. The Plug-In
        Administrator is restricted from making any changes to ‘System Auto’
        Disposition Codes.
      </p>
      <p>
        Disposition Codes can be accessed using{' '}
        <em>
          <u>RocketCX > Disposition Codes</u>
        </em>
      </p>
      <Screenshot src={image103a} />
      <h2 className="sectionsubtitle">
        Steps to create or edit Disposition Codes:
      </h2>
      <ol>
        <li>
          Click on the New button and enter the name and description, and select
          the Active checkbox. Click on Submit.
          <Screenshot src={image103b} />
        </li>
        <li>
          To edit an existing disposition code, click on the record from the
          list.
        </li>
      </ol>
    </section>
  </div>
);

export const AdministrationContent = {
  content: AdminContent,
  acqRecords: ACQRecordsContent,
  adminConsole: {
    content: AdminConsoleContent,
    aConnectTab: AmazonConnectContent,
    sNowTab: ServiceNowContent,
    rocketCXTab: RocketCXContent,
  },
  dispositionCodes: DispositionCodesContent,
};
