import React, { RefObject } from 'react';
import { FieldError as HookFormFieldError } from 'react-hook-form';

import FieldError from 'components/atoms/FieldError';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';

import css from './Passwords.module.scss';

interface PasswordParams {
  name: string;
  ref:
    | ((instance: unknown) => void)
    | React.RefObject<unknown>
    | null
    | undefined;
  err?: HookFormFieldError | undefined;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface PasswordsProps {
  password: PasswordParams;
  rePassword: PasswordParams;
}

/**
 * A single component to handle usecase of styling and showing two password fields which are related to each other.
 * Useful for cases where both password and retype password fields are required.
 */
const Passwords: React.FC<PasswordsProps> = ({ password, rePassword }) => {
  return (
    <div className={css.passwordpanel}>
      <Label text={password.label ?? 'Password'}>
        <Input
          type="password"
          name={password.name}
          // @ts-ignore
          ref={password.ref}
          onChange={password.onChange}
        />
        <FieldError err={password.err} />
      </Label>
      <Label text={rePassword.label ?? 'Confirm password'}>
        <Input
          type="password"
          name={rePassword.name}
          // @ts-ignore
          ref={rePassword.ref}
          onChange={rePassword.onChange}
        />
        <FieldError err={rePassword.err} />
      </Label>
    </div>
  );
};
export default Passwords;
