import { useState, useEffect } from 'react';
import { HTTPError } from 'ky';

import { AsyncState } from './useAsync';

const useAsyncErrJson = (
  current: AsyncState,
  err: Error | null,
  signal?: AbortSignal
) => {
  const [errJson, _setErrJson] = useState<any>(null);

  useEffect(() => {
    const setErrJson = (json: any) => {
      // If success but later the signal is aborted
      if (signal?.aborted) return;
      _setErrJson(json);
    };

    switch (current) {
      case AsyncState.Error:
        if (err instanceof HTTPError) {
          const res = err.response.clone();
          res
            .json()
            .then(json => {
              setErrJson(json);
            })
            .catch(() => {
              setErrJson(null);
            });
        }
        break;
      default:
        setErrJson(null);
        break;
    }
  }, [current, err, signal]);

  return errJson;
};

export default useAsyncErrJson;
