import React from 'react';

export const ServiceNowCCP = (
  <section>
    <h3 className="sectiontopic">RocketCX CCP</h3>
    <ul>
      <li>Google Chrome is the only web browser supported.</li>
      <li>
        Issues due to session timeout or browser refresh.
        <ul>
          <li>
            RocketCX CCP stays active in the background even after the
            ServiceNow session has timed out
          </li>
          <li>
            The Agent is unable to accept any calls even though the status
            remains the same as it was prior to session timeout.
          </li>
          <li>
            The Agent needs to log in and refresh the webpage to start using the
            RocketCX CCP.
          </li>
        </ul>
      </li>
      <li>CCP should not be opened in more than one browsing window.</li>
      <li>
        RocketCX CCP User Interface is unstable when only one Agent is available
        and rejects calls continuously.
      </li>
      <li>
        RocketCX CCP Dial Pad supports numeric characters only. Malfunctioning
        of the RocketCX CCP is expected in case of non-numeric or special
        characters being entered.
      </li>
      <li>
        Call recording for a call-back would not be available in call log
        records.
      </li>
      <li>
        Instability of RocketCX CCP user interface is expected in case of
        call-back.
      </li>
    </ul>
  </section>
);
