import React from 'react';
import { useHover, ToggleLayer, Arrow } from 'react-laag';

interface TooltipProps {
  text: string;
}

/**
 * A tooltip to display helpful suggestion or info when hovered on content.
 *
 * @example
 * <div>
 *   <Tooltip text="For your information">
 *     <span className="highlight">FYI</span>
 *   </Tooltip>
 *   this can be done in another way.
 * </div>
 */
const Tooltip: React.FC<TooltipProps> = ({ children, text }) => {
  const [isOpen, hoverProps] = useHover();

  return (
    <ToggleLayer
      isOpen={isOpen}
      placement={{
        anchor: 'RIGHT_CENTER',
        autoAdjust: true,
        triggerOffset: 10,
      }}
      closeOnDisappear="full"
      renderLayer={({ isOpen, layerProps, arrowStyle, layerSide }) =>
        isOpen && (
          <div
            ref={layerProps.ref}
            style={{
              ...layerProps.style,
              borderRadius: '3px',
              backgroundColor: '#333',
              zIndex: 10,
            }}
          >
            <div
              className="tooltip_text"
              style={{
                padding: '10px 13px',
                color: 'white',
                fontSize: '0.9rem',
                width: '240px',
              }}
            >
              {text}
            </div>
            <Arrow
              style={arrowStyle}
              layerSide={layerSide}
              backgroundColor="#333"
              roundness={0.7}
            />
          </div>
        )
      }
    >
      {({ triggerRef }) => (
        <span ref={triggerRef} {...hoverProps}>
          {children}
        </span>
      )}
    </ToggleLayer>
  );
};

export default Tooltip;
