import { useRef } from 'react';

const useOnFirstRun = (fn: Function) => {
  const isFirstRunDone = useRef(false);

  if (!isFirstRunDone.current) {
    fn();
    isFirstRunDone.current = true;
  }
};

export default useOnFirstRun;
