import React, { useContext } from 'react';

import { UserRole } from 'schemas/User';
import AuthApi from 'api/AuthApi';
import { UserContext, history, UserStateReason } from 'App';
import ECSLogo from 'components/ECSLogo';
import Link from 'components/atoms/Link';
import NavBarDropdown from 'components/NavBarDropdown';

import css from './NavBar.module.scss';
import ReloadService from 'services/ReloadService';

const NavBar: React.FC = () => {
  const { user, setUser, role } = useContext(UserContext);

  const logout = async () => {
    try {
      await AuthApi.logout();
      setUser(null, UserStateReason.NullBecauseLoggedOut);
      // force push to login page here because irrespective of which user is, they may need to login with other credentials
      // also this action denotes user has been successfully logged out
      history.push('/login');
      ReloadService.triggerReload();
    } catch (error) {
      // TODO retry action?
    }
  };

  return (
    <nav className={css.navbarcontainer}>
      <ECSLogo className={css.navecslogo} />
      <div className={css.actionpanel}>
        <Link
          to={role === UserRole.Admin ? `/admin-instances` : `/instances`}
          className={css.navlink}
        >
          Instances
        </Link>
        {role === UserRole.Admin ? (
          <Link to="/users" className={css.navlink}>
            Users
          </Link>
        ) : null}
        <NavBarDropdown menuLabel="Documentation" labelID="docs">
          <Link to="/administration-guide">Administration Guide</Link>
          <Link to="/user-guide">User Guide</Link>
          <Link to="/docs/release-notes">Release Notes</Link>
        </NavBarDropdown>
        <NavBarDropdown
          menuLabel={`${user?.firstname}`}
          labelID="profile"
          profileMenu
        >
          <Link to="/profile">Edit profile</Link>
          <Link to="/profile/change-password">Change password</Link>
          <strong>
            <button name="logout" onClick={logout} className={css.logoutbtn}>
              Logout
            </button>
          </strong>
        </NavBarDropdown>
      </div>
    </nav>
  );
};

export default NavBar;
