import { IntroductionContent } from './sections/Introduction';
import { SetupSectionContent } from './sections/Setup';
import { AdministrationContent } from './sections/Administration';
import { UnsubscribeContent } from './sections/Unsubscribe';
import { AdditionalContent } from './sections/AdditionalResources';
import { GlossaryContent } from 'pages/UserGuide/sections/Glossary';

export const mappingTable: { [route: string]: JSX.Element } = {
  '/overview': IntroductionContent.content,
  '/setup': SetupSectionContent.content,
  '/setup/subscribe': SetupSectionContent.subscribe,
  '/setup/register': SetupSectionContent.register,
  '/setup/create-instance': SetupSectionContent.createInstance,
  '/setup/configure-on-snow': SetupSectionContent.configure,
  '/administration': AdministrationContent.content,
  '/administration/user-profile': AdministrationContent.userProfile,
  '/administration/change-user-password':
    AdministrationContent.changeUserPassword,
  '/administration/view-instances': AdministrationContent.viewInstances,
  '/administration/instance-details': AdministrationContent.viewEditInstance,
  '/administration/instance-usage': AdministrationContent.instanceUsage,
  '/administration/enable-disable-instance':
    AdministrationContent.enableDisableInstances,
  '/administration/change-instance-password':
    AdministrationContent.changeInstancePassword,
  '/administration/delete-instance': AdministrationContent.deleteInstance,
  '/unsubscribe': UnsubscribeContent.content,
  '/additional-resources': AdditionalContent.content,
  '/glossary': GlossaryContent.content,
};
