import React from 'react';
import { object as yupObject } from 'yup';

import Passwords from 'components/Passwords';
import Modal, { ModalHeading, ModalCloseReason } from 'components/Modal';
import Button from 'components/atoms/Button';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Alert from 'components/Alert';

import { history } from 'App';
import { userShape } from 'schemas/User';
import { useForm } from 'react-hook-form';
import FieldError from 'components/atoms/FieldError';
import useSignal from 'utils/hooks/useSignal';
import UserApi from 'api/UserApi';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import css from './NewUser.module.scss';
import { getErrorMessage } from 'utils/helpers';
import useAsyncErrJson from 'utils/hooks/useAsyncErrJson';

interface FormData {
  firstname: string;
  lastname: string;
  company: string;
  email: string;
  username: string;
  newPassword: string;
  rePassword: string;
}

const validationSchema = yupObject<FormData>({
  firstname: userShape.firstname.label('First name'),
  lastname: userShape.lastname.label('Last name'),
  company: userShape.company.label('Company'),
  email: userShape.email.label('Email'),
  username: userShape.username.label('Username').label('Username'),
  newPassword: userShape.password.label('Password'),
  rePassword: userShape.rePassword('newPassword').label('Confirm password'),
});

interface HandleCloseShape {
  (reason: ModalCloseReason): void;
}

interface NewUserProps {
  handleClose: HandleCloseShape;
}

const NewUser: React.FC<NewUserProps> = ({ handleClose }) => {
  const {
    register: fieldRef,
    handleSubmit,
    getValues,
    errors: formErr,
  } = useForm<FormData>({
    validationSchema,
  });

  const { signal } = useSignal();

  const formPromise = async () => {
    const { rePassword, ...values } = getValues();
    const result = await UserApi.add(values, { signal });
    return result;
  };

  const { run: sendRequest, current, err } = useAsync(formPromise, {
    onCurrentChange({ current, data, err }) {
      switch (current) {
        case AsyncState.Success:
          const user = data!;
          history.push(`/users/${user.id}`);
          break;
      }
    },
  });
  const isPending = current === AsyncState.Pending;

  const errJson = useAsyncErrJson(current, err, signal);
  const errMsg = getErrorMessage(err, { errJson });

  const onSubmit = handleSubmit(async () => {
    await sendRequest();
  });

  return (
    <Modal handleClose={handleClose} dismissModalCloseEvents>
      <ModalHeading>Create new user</ModalHeading>
      <Alert error={errMsg} />
      <form onSubmit={onSubmit} noValidate spellCheck={false}>
        <section>
          <div className={css.namerow}>
            <Label text="First name">
              <Input name="firstname" ref={fieldRef} />
              <FieldError err={formErr.firstname} />
            </Label>
            <Label text="Last name">
              <Input name="lastname" ref={fieldRef} />
              <FieldError err={formErr.lastname} />
            </Label>
          </div>
          <Label text="Company">
            <Input name="company" ref={fieldRef} />
            <FieldError err={formErr.company} />
          </Label>
          <Label text="Email">
            <Input name="email" type="email" ref={fieldRef} />
            <FieldError err={formErr.email} />
          </Label>
          <Label text="Username">
            <Input name="username" ref={fieldRef} />
            <FieldError err={formErr.username} />
          </Label>
          <Passwords
            password={{
              name: 'newPassword',
              ref: fieldRef,
              err: formErr.newPassword,
            }}
            rePassword={{
              name: 'rePassword',
              ref: fieldRef,
              err: formErr.rePassword,
            }}
          />
        </section>
        <section className="btnrow">
          <Button type="submit" kind="brand" loading={isPending}>
            Create User
          </Button>
          <Button
            onClick={() => handleClose(ModalCloseReason.ClickedCloseButton)}
          >
            Cancel
          </Button>
        </section>
      </form>
    </Modal>
  );
};

export default NewUser;
