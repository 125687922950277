import { inDevelopment } from 'utils/helpers';

class ReloadService {
  static tabId: string | null = null;
  static localKey: string | null = null;

  private static generateKey() {
    return (Date.now() * 10000 + Math.round(Math.random() * 10000)).toString();
  }

  static initialize() {
    this.tabId = this.generateKey();

    // fetch key and set it locally
    const currentKey = localStorage.getItem('reloadKey');
    if (currentKey === null) {
      const newKey = this.generateKey();
      localStorage.setItem('reloadKey', newKey);
      this.localKey = newKey;
    } else {
      this.localKey = currentKey;
    }

    window.addEventListener('storage', () => {
      const currentKey = localStorage.getItem('reloadKey');
      // something triggered storage listener, but that changed a value which isn't for reload
      if (currentKey === this.localKey) return;

      const storageTabId = localStorage.getItem('reloadOriginator');
      // reload all tabs except the one which has triggered it
      if (storageTabId === this.tabId) return;

      // a reload has been requested
      window.location.reload();
    });
  }

  // reload all tabs except the current one
  static triggerReload() {
    if (this.tabId === null) {
      if (inDevelopment()) {
        // TODO make color format with %c instead
        console.error(
          'You are trying to use `ReloadService.triggerReload` before initializing `ReloadService`. Please use `initializeReload` when you initialize the app.'
        );
      }
      return;
    }
    localStorage.setItem('reloadOriginator', this.tabId);
    localStorage.setItem('reloadKey', this.generateKey());
  }
}

export default ReloadService;
