import React from 'react';
import cn from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import User, { SubscriptionStatus } from 'schemas/User';
import { history } from 'App';
import AdminUsersPlaceholder from './AdminUsersPlaceholder';
import EmptyList from 'components/EmptyList';
import RenderDelay from 'components/RenderDelay';
import Button from 'components/atoms/Button';

import css from './AdminUsers.module.scss';
import { AsyncState } from 'utils/hooks/useAsync';

export enum RequestReason {
  LoadMore,
  Search,
  FilterInitialFetch,
}

// Internally used to remove `subscription-filter` from API query when `all` is selected
const __NULL = '__NULL';
interface SubscriptionFilter {
  id: SubscriptionStatus | '__NULL';
  label: string;
  className: string;
  icon?: IconProp;
}

const subscriptionFilters: SubscriptionFilter[] = [
  {
    id: __NULL,
    label: 'All',
    className: 'all',
  },
  {
    id: SubscriptionStatus.None,
    label: 'None',
    className: 'none',
    icon: 'unlink',
  },
  {
    id: SubscriptionStatus.SubscribeSuccess,
    label: 'Subscribe Success',
    className: 'success',
    icon: 'user-check',
  },
  {
    id: SubscriptionStatus.SubscribeFail,
    label: 'Subscribe Fail',
    className: 'fail',
    icon: 'user-slash',
  },
  {
    id: SubscriptionStatus.UnsubscribePending,
    label: 'Unsubscribe Pending',
    className: 'upending',
    icon: 'user-clock',
  },
  {
    id: SubscriptionStatus.UnsubscribeSuccess,
    label: 'Unsubscribe Success',
    className: 'usuccess',
    icon: 'user-times',
  },
];

interface SubscriptionStatusFilterProps {
  data: SubscriptionFilter;
  isActive?: boolean;
  setActiveSubscriptionStatus: (
    subscriptionStatus: SubscriptionStatus | null
  ) => void;
}

const StatusFilter: React.FC<SubscriptionStatusFilterProps> = ({
  data,
  isActive,
  setActiveSubscriptionStatus,
}) => {
  const classes = cn(
    [css.usertilestatus, css.filteroption, css[data.className], 'noselect'],
    {
      [`${css.filterselected}`]: isActive,
    }
  );

  return (
    <div
      className={classes}
      onClick={() =>
        setActiveSubscriptionStatus(data.id === __NULL ? null : data.id)
      }
    >
      {data.icon !== undefined && (
        <FontAwesomeIcon className={css.filtericon} icon={data.icon} />
      )}
      {data.label}
    </div>
  );
};

interface SubscriptionStatusFiltersProps {
  activeSubscriptionStatus: SubscriptionStatus | null;
  setActiveSubscriptionStatus: (
    subscriptionStatus: SubscriptionStatus | null
  ) => void;
}

export const SubscriptionStatusFilters: React.FC<SubscriptionStatusFiltersProps> = ({
  activeSubscriptionStatus,
  setActiveSubscriptionStatus,
}) => {
  return (
    <section className={css.usersactionpanel}>
      <section className={css.statusfilters}>
        {subscriptionFilters.map(filter => {
          return (
            <StatusFilter
              key={filter.id}
              data={filter}
              isActive={
                activeSubscriptionStatus ===
                (filter.id === __NULL ? null : filter.id)
              }
              setActiveSubscriptionStatus={setActiveSubscriptionStatus}
            />
          );
        })}
      </section>
    </section>
  );
};

interface UsersCardProps {
  users: User[];
  loadMore: Function;
  pageNo: number;
  totalPages: number;
  isLoadMoreDisabled?: boolean;
}

const UsersCard: React.FC<UsersCardProps> = ({
  users,
  loadMore,
  pageNo,
  totalPages,
  isLoadMoreDisabled,
}) => {
  /**
   * open User profile page with the provided user ID
   */
  function openUserPage(username: string) {
    history.push(`/users/${username}`);
  }

  /**
   * returns the class name of the status tag based on the subscription status name
   */
  function getSubscriptionStatusClass(status: SubscriptionStatus): string {
    switch (status) {
      case SubscriptionStatus.SubscribeFail:
        return css.faildot;
      case SubscriptionStatus.SubscribeSuccess:
        return css.successdot;
      case SubscriptionStatus.UnsubscribePending:
        return css.upendingdot;
      case SubscriptionStatus.UnsubscribeSuccess:
        return css.usuccessdot;
      case SubscriptionStatus.None:
        return css.nonedot;
      default:
        return '';
    }
  }

  return (
    <>
      <div className={css.usergrid}>
        {users.map(user => (
          <div
            key={user.id}
            className={`${css.usertile} noselect`}
            onClick={() => openUserPage(user.id)}
          >
            <div className={css.usertilename}>
              {user.firstname} {user.lastname}
            </div>
            <div className={css.usertileusername}>{user.username}</div>
            <div
              className={`${css.usertilestatusdot} ${getSubscriptionStatusClass(
                user.subscriptionStatusName
              )}`}
            ></div>
          </div>
        ))}
      </div>
      <div>
        {pageNo < totalPages - 1 && (
          <Button onClick={() => loadMore()} disabled={isLoadMoreDisabled}>
            <FontAwesomeIcon icon="arrow-circle-down" /> Load More
          </Button>
        )}
      </div>
    </>
  );
};

interface RenderContentProps {
  current: AsyncState;
  users: User[];
  loadMore: Function;
  pageNo: number;
  totalPages: number;
  requestReason: RequestReason;
}

export const RenderContent: React.FC<RenderContentProps> = ({
  current,
  users,
  loadMore,
  pageNo,
  totalPages,
  requestReason,
}) => {
  switch (current) {
    case AsyncState.Initial:
    case AsyncState.Pending:
      // Fetching when clicked on load more
      if (requestReason === RequestReason.LoadMore) {
        return (
          <UsersCard
            users={users}
            loadMore={loadMore}
            pageNo={pageNo}
            totalPages={totalPages}
            isLoadMoreDisabled
          />
        );
      }
      // Fetching on other cases
      return (
        <RenderDelay>
          <AdminUsersPlaceholder />
        </RenderDelay>
      );
    case AsyncState.Success:
      if (users.length === 0) {
        if (requestReason === RequestReason.FilterInitialFetch)
          return <EmptyList item="Users" suggestToCreateNew />;
        if (requestReason === RequestReason.Search) {
          return <EmptyList item="Users" />;
        }
      }
      return (
        <UsersCard
          users={users}
          loadMore={loadMore}
          pageNo={pageNo}
          totalPages={totalPages}
        />
      );
    case AsyncState.Error:
      // TODO handle error
      return null;
  }
};
