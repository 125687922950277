import { string as yupString, ref } from 'yup';

import {
  isValidPassword,
  yupInvalidPasswordMessage,
  yupInvalidUsernameMessage,
  invalidUsernameRegex,
  invalidNameRegex,
  yupInvalidNameMessage,
  invalidCompanyNameRegex,
  yupInvalidCompanyNameMessage,
  yupInvalidEmailMessage,
  yupInvalidMinMaxMessage,
  yupRequiredFieldMessage,
  doesEmailContainUnicodeCharacters,
} from '.';

export enum UserRole {
  Admin = 'ROLE_ADMIN',
  User = 'ROLE_USER',
}

export enum SubscriptionStatus {
  None = 'None',
  SubscribeFail = 'Subscribe Fail',
  SubscribeSuccess = 'Subscribe Success',
  UnsubscribePending = 'Unsubscribe Pending',
  UnsubscribeSuccess = 'Unsubscribe Success',
}

export interface Authority {
  authority: UserRole;
}

interface User {
  readonly id: string;
  authorities?: Authority[];
  company: string;
  awsCustomerId: string;
  enabled: boolean;
  firstname: string;
  lastname: string;
  username: string;
  email: string;
  subscriptionStatusId: string;
  readonly subscriptionStatusName: SubscriptionStatus;
  oldPassword?: string | null;
  newPassword?: string | null;
  readonly dateCreated: string;
  readonly dateUpdated: string | null;
  paid?: boolean;
  recaptchaToken?: string | null;
  readonly subscriptionDate: string | null;
  readonly unsubscriptionDate: string | null;
}

// Local override to turn optional properties to required ones
interface __UserOverride extends User {
  oldPassword: string;
  newPassword: string;
}

export type UserNewPassword = Pick<
  __UserOverride,
  'id' | 'oldPassword' | 'newPassword'
>;
export type UserCreate = Pick<
  __UserOverride,
  | 'company'
  | 'firstname'
  | 'lastname'
  | 'username'
  | 'email'
  | 'newPassword'
  | 'recaptchaToken'
>;
export type UserUpdate = Pick<
  User,
  | 'id'
  | 'company'
  | 'firstname'
  | 'lastname'
  | 'email'
  | 'oldPassword'
  | 'newPassword'
  | 'paid'
>;

export const userShape = {
  username: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .matches(invalidUsernameRegex, yupInvalidUsernameMessage)
    .min(3, yupInvalidMinMaxMessage(3, 50))
    .max(50, yupInvalidMinMaxMessage(3, 50)),
  email: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .test('validEmail', yupInvalidEmailMessage, function(this, v: string) {
      return !doesEmailContainUnicodeCharacters(v);
    })
    .email(yupInvalidEmailMessage)
    .min(5, yupInvalidMinMaxMessage(5, 254))
    .max(254, yupInvalidMinMaxMessage(5, 254)),
  firstname: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .matches(invalidNameRegex, yupInvalidNameMessage)
    .min(2, yupInvalidMinMaxMessage(2, 50))
    .max(50, yupInvalidMinMaxMessage(2, 50)),
  lastname: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .matches(invalidNameRegex, yupInvalidNameMessage)
    .min(2, yupInvalidMinMaxMessage(2, 50))
    .max(50, yupInvalidMinMaxMessage(2, 50)),
  company: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .matches(invalidCompanyNameRegex, yupInvalidCompanyNameMessage)
    .min(2, yupInvalidMinMaxMessage(2, 50))
    .max(50, yupInvalidMinMaxMessage(2, 50)),
  password: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .test('validPassword', yupInvalidPasswordMessage, function(
      this,
      v: string
    ) {
      return isValidPassword(v);
    })
    .min(12, yupInvalidMinMaxMessage(12, 30))
    .max(30, yupInvalidMinMaxMessage(12, 30)),
  rePassword(passwordPath: string) {
    return (
      yupString()
        .trim()
        .required(yupRequiredFieldMessage)
        // Use cutsom test (below) over `.oneOf([ref(passwordPath)], 'Passwords do not match.')`
        .test('passwordsDontMatch', 'Passwords do not match.', function(
          this,
          v: string
        ) {
          const password = this.resolve(ref(passwordPath));
          return password === v;
        })
    );
  },
};

export default User;
