import React from 'react';

const SContent = (
  <div>
    <h1 className="docsectiontitle">Support</h1>
    <section className="sectionmaterial">
      <table>
        <tr>
          <th>Aspect</th>
          <th>Details</th>
        </tr>
        <tr>
          <td>Support Hours Of Operation</td>
          <td>09:00 - 17:00 (GMT)</td>
        </tr>
        <tr>
          <td>Support Days of Operation</td>
          <td>Monday - Friday (excluding U.K Public Holidays)</td>
        </tr>
        <tr>
          <td>Promised Response Time</td>
          <td>Within 1 Business day of received support request</td>
        </tr>
        <tr>
          <td>Promised Resolution Time</td>
          <td>Within 15 Business Days of response</td>
        </tr>
        <tr>
          <td>Contact Method</td>
          <td>Email</td>
        </tr>
        <tr>
          <td>Contact Details</td>
          <td>
            <a href="mailto:RocketCX.support@ecs.co.uk">
              RocketCX.support@ecs.co.uk
            </a>
          </td>
        </tr>
      </table>
    </section>
  </div>
);

export const SupportContent = {
  content: SContent,
};
