import React from 'react';
import { sections } from '../sections';
import { HashLink as Link } from 'react-router-hash-link';

const RNContent = (
  <div>
    <ol>
      {sections.map(section => (
        <li key={section.id}>
          <Link to={`#${section.id}`}>{section.title}</Link>
        </li>
      ))}
    </ol>
    <section className="sectionmaterial">
      {sections.map(section => (
        <section key={section.id} className="releasenotesections">
          {section.content}
        </section>
      ))}
    </section>
  </div>
);

export const ReleaseNotesContent = {
  content: RNContent,
};
