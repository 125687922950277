// common form validators and messages ->

/* eslint-disable no-template-curly-in-string */

export function yupInvalidMinMaxMessage(min: number, max: number) {
  return '${path} should be between ' + min + ' to ' + max + ' characters.';
}

export const isValidPassword = (password: string): boolean => {
  const hasCharactersWithinConstraints = new RegExp(
    /^[a-z0-9!@#$%^&.\-*]*$/i
  ).test(password);
  const includesUpperCase = new RegExp(/[A-Z]/).test(password);
  const includesSpecialCharacter = new RegExp(/[!@#$%^&.\-*]/).test(password);
  return (
    hasCharactersWithinConstraints &&
    includesUpperCase &&
    includesSpecialCharacter
  );
};

/* eslint-disable no-control-regex */
export const doesEmailContainUnicodeCharacters = (email: string): boolean => {
  const hasUnicodeCharacters = new RegExp(/[^\u0000-\u007F]+/i).test(email);
  return hasUnicodeCharacters;
};
/* eslint-enable no-control-regex */

export const yupRequiredFieldMessage = '${path} is a mandatory field.';

export const yupInvalidPasswordMessage =
  '${path} can contain letters, numerals and special characters. Password must contain:\n   • One uppercase letter\n   • One special character from !@#$%^&.-*';

export const yupInvalidEmailMessage = 'Please enter a valid email address.';

export const invalidUsernameRegex = /^[a-z0-9]*$/i;
export const yupInvalidUsernameMessage =
  '${path} can contain letters and numerals only.';

export const invalidInstanceNameRegex = /^[a-z0-9.\s]*$/i;
export const yupInvalidInstanceNameMessage =
  '${path} can contain letters, numerals, period and spaces.';

export const invalidNameRegex = /^[a-z\-'\s.]*$/i;
export const yupInvalidNameMessage =
  "${path} can contain letters, spaces and special characters (- ' .).";

export const invalidCompanyNameRegex = /^[a-z0-9.\s\-,&]*$/i;
export const yupInvalidCompanyNameMessage =
  '${path} can contain letters, numerals, spaces and special characters (& , . - ).';

/* eslint-enable no-template-curly-in-string */
