import React from 'react';

import css from './OrderedList.module.scss';

export const LI: React.FC<{ index?: string }> = ({ index = '1', children }) => {
  return (
    <div className={css.listItem}>
      <span className={css.listItemIndex}>{index}</span>
      <div className={css.itemChildren}>{children}</div>
    </div>
  );
};

const OL: React.FC<{ sectionIndex?: number }> = ({
  children,
  sectionIndex = 1,
}) => {
  return (
    <div className={css.orderedList}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(
          // @ts-ignore
          child,
          { index: `${sectionIndex}.${index + 1}` }
        )
      )}
    </div>
  );
};

export default OL;
