import React, { useRef, useEffect, CSSProperties } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import cn from 'classnames';
import Portal from 'components/Portal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from './Modal.module.scss';

export enum ModalCloseReason {
  ClickedCloseButton = 'ClickedCloseButton',
  PressedKeyboardEscape = 'PressedKeyboardEscape',
  ClickedOutside = 'ClickedOutside',
  TouchedOutside = 'TouchedOutside',
  OtherEvent = 'OtherEvent',
}

interface HandleCloseShape {
  (reason: ModalCloseReason): void;
}

interface ModalProps {
  handleClose: HandleCloseShape;
  isDialog?: boolean;
  showClose?: boolean;
  inlineStyle?: CSSProperties;
  // TODO remove it as soon as user and instance creation are not handled inside modal
  dismissModalCloseEvents?: boolean;
}

/**
 * @example
 *
 * // basic usage ->
 * import React, { useState } from 'react'
 * import Modal, { ModalCloseReason } from './Modal'
 *
 * const Page = () => {
 *   const [open, setOpen] = useState(false)
 *
 *   function handleModalClose(reason: ModalCloseReason) {
 *     setOpen(false)
 *     console.log(`Modal closed because ${reason}`)
 *   }
 *
 *   return (
 *     <div>
 *       <button onClick={() => {setOpen(true)}}>Open modal</button>
 *
 *       <Modal handleClose={handleModalClose}>
 *         <div>Content inside modal</div>
 *       </Modal>
 *     </div>
 */
const Modal: React.FC<ModalProps> = ({
  children,
  handleClose,
  inlineStyle = {},
  isDialog = false,
  showClose = false,
  dismissModalCloseEvents = false,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Disable body scroll when modal is shown
  useEffect(() => {
    const container = containerRef.current!;
    disableBodyScroll(container);
    return () => {
      enableBodyScroll(container);
    };
  }, []);

  // Bring back focus to the originally focused element on unmount
  useEffect(() => {
    const focusedElement = document.activeElement as HTMLElement;
    return () => {
      focusedElement.focus();
    };
  }, []);

  // Focus to modal on mount
  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.focus();
  }, []);

  // Close when clicked outside ->
  const handleMouseUp = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  useEffect(() => {
    const handleOutsideClick = () => {
      if (dismissModalCloseEvents) return;
      handleClose(ModalCloseReason.ClickedOutside);
    };
    document.addEventListener('mouseup', handleOutsideClick);

    return () => {
      document.removeEventListener('mouseup', handleOutsideClick);
    };
  }, [dismissModalCloseEvents, handleClose]);

  // Close when touched outside ->
  const handleTouchEnd = (e: React.TouchEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  useEffect(() => {
    const handleOutsideTouch = () => {
      if (dismissModalCloseEvents) return;
      handleClose(ModalCloseReason.TouchedOutside);
    };
    document.addEventListener('touchend', handleOutsideTouch);

    return () => {
      document.addEventListener('touchend', handleOutsideTouch);
    };
  }, [dismissModalCloseEvents, handleClose]);

  // Close when `escape` button is pressed ->
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.stopPropagation();
        /* 
          Needed if e is a react synthetic event ->
          e.nativeEvent.stopImmediatePropagation() 
        */
        handleClose(ModalCloseReason.PressedKeyboardEscape);
      }
    };
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleClose]);

  let classes = cn(css.modal_main, { [css.modal_dialog]: isDialog });

  return (
    <Portal>
      <div className={css.mymodal}>
        <div
          className={classes}
          style={inlineStyle}
          ref={containerRef}
          tabIndex={0}
          onMouseUp={handleMouseUp}
          onTouchEnd={handleTouchEnd}
          data-testid="modal"
        >
          {children}
          {showClose && (
            <button
              className={css.modal_close_btn}
              onClick={() => handleClose(ModalCloseReason.ClickedCloseButton)}
            >
              <FontAwesomeIcon icon="times-circle" />
            </button>
          )}
        </div>
      </div>
    </Portal>
  );
};

export const ModalHeading: React.FC = ({ children }) => {
  return <h1 className={css.modal_heading}>{children}</h1>;
};

export const DialogHeading: React.FC = ({ children }) => {
  return <h1 className={css.dialog_heading}>{children}</h1>;
};

export default Modal;
