import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from './CopyToClipboard.module.scss';

export async function copyText(text: string) {
  return navigator.clipboard.writeText(text);
}

/**
 * Copies a text to clipboard when clicked
 */
const CopyToClipboard: React.FC<{ text: string }> = ({ text }) => {
  const [showAlert, setAlert] = useState(false);

  return (
    <span className={css.copypanel}>
      <FontAwesomeIcon
        icon="copy"
        className={css.copyicon}
        onClick={() => copyText(text).then(showDisappearingText)}
      />
      {showAlert && <span className={css.copyalert}>COPIED</span>}
    </span>
  );

  function showDisappearingText() {
    if (!showAlert) {
      setAlert(true);
      setTimeout(() => setAlert(false), 2000);
    }
  }
};

export default CopyToClipboard;
