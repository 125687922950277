import ky, { RetryOptions } from 'ky';

import { emitter, EmitterEvent } from 'services/EventService';
import { UserStateReason } from 'App';
import { ErrorRaisedReason } from 'components/ErrorBoundary/ErrorBoundary';

const apiUrl = process.env.REACT_APP_API_URL;

const api = ky.create({
  prefixUrl: apiUrl,
  credentials: 'include',
  hooks: {
    afterResponse: [
      (req, opt, res) => {
        /* 
          If an API request results in 401 (unauthorized), user needs to be logged out.
          This case can arise when a session gets expired or there is invalid/missing auth token.
        */
        if (res.status === 401) {
          emitter.emit(EmitterEvent.SetUser, {
            user: null,
            reason: UserStateReason.NullBecauseInvalidSession,
          });
        }
        if (res.status >= 500) {
          emitter.emit(EmitterEvent.ErrorRaised, {
            reason: ErrorRaisedReason.NetworkError,
          });
        }
      },
    ],
  },
});

export const apiWithoutHooks = ky.create({
  prefixUrl: apiUrl,
  credentials: 'include',
});

/**
 * Options passed to `ky` from api
 */
export interface RequestOptions {
  signal?: AbortSignal;
  retry?: RetryOptions | number;
  timeout?: number;
}

/**
 * Simple JSON response structure received from backend, like in 4xx status
 */
export interface BasicResponse {
  message: string;
}

export interface PaginatedResponse<T> {
  content: T[];
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    pageSize: number;
    pageNumber: number;
    offset: number;
    unpaged: boolean;
    paged: boolean;
  };
  totalElements: number;
  totalPages: number;
  last: boolean;
  first: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  size: number;
  number: number;
  empty: boolean;
}

export default api;
