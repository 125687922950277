import React from 'react';

export const ServiceCentreDashboard = (
  <section>
    <h3 className="sectiontopic">Service Centre Dashboard</h3>
    <p>
      The Service Centre Dashboard gives an overview of the live and overall
      performance of all agents and Contact Centre using Live and Historical
      Metrics provided by Amazon Connect. Since it provides a historical view of
      the Contact Centre, it becomes easy to strategize and improve the
      performance of Supervisors and Contact Centre Managers.
    </p>
  </section>
);
