import React from 'react';
import image51a from '../images/51a.png';
import image52a from '../images/52a.png';
import image53a from '../images/53a.png';
import image54a from '../images/54a.png';
import image55a from '../images/55a.png';
import image56a from '../images/56a.png';
import image57a from '../images/57a.png';
import image57b from '../images/57b.png';
import image57c from '../images/57c.png';
import image57d from '../images/57d.png';
import Screenshot from '../Screenshot';

const ATContent = (
  <div>
    <h1 className="docsectiontitle">
      Accessing the RocketCX Enterprise Connector
    </h1>
    <section className="sectionmaterial">
      <p>
        The following steps outline the process to access the CCP by an agent,
        in order to be able to make/receive calls and access contacts:
      </p>
      <ol>
        <li>
          Log in to Service Now:
          <Screenshot src={image51a} />
        </li>
        <li>
          Once logged in to ServiceNow, click on the phone icon which will open
          the CCP window.
          <Screenshot src={image52a} />
        </li>
        <li>
          In the CCP window click on Sign in to CCP which will open an Amazon
          Connect window.
          <Screenshot src={image53a} />
        </li>
        <li>
          Enter your Amazon Connect credentials and click on <em>Sign In</em>.
          <Screenshot src={image54a} />
        </li>
        <li>
          Once you have logged in it will close the window and ask for audio and
          microphone permission for the browser. Click on Allow.
          <Screenshot src={image55a} />
        </li>
        <li>
          RocketCX Enterprise Connector’s Contact Control Panel (hereafter
          referred as RocketCX CCP) will now display agent name and status.
          <Screenshot src={image56a} />
        </li>
      </ol>
      <p>Agents can open the CCP using three different methods:</p>
      <ol style={{ listStyleType: 'lower-roman' }}>
        <li>
          From the ServiceNow instance home page. Click on the phone icon as
          shown below.
          <Screenshot src={image57a} />
        </li>
        <li>
          <p>From a User Record</p>
          <p>
            Open a User record as shown in the following screenshot and click on
            the phone icon.
          </p>
          <Screenshot src={image57b} />
        </li>
        <li>
          <p>From the Incident/CSM and Case/HR Case forms.</p>
          <p>
            Open an Incident or Case as shown below, and click on the phone
            icon.
          </p>
          <Screenshot src={image57c} />
          <Screenshot src={image57d} />
        </li>
      </ol>
      <p className="sectionnote">
        Note: The user should have the required roles in order to access the
        Incident, Customer Service, HR Case modules.
      </p>
    </section>
  </div>
);

export const AccessingToolContent = {
  content: ATContent,
};
