import React, { useContext, useState } from 'react';
import { object as yupObject } from 'yup';

import { UserContext, UserStateReason } from 'App';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';

import DefaultLayout from 'layouts/DefaultLayout';
import { userShape } from 'schemas/User';
import { useForm } from 'react-hook-form';
import UserApi from 'api/UserApi';
import useSignal from 'utils/hooks/useSignal';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import FieldError from 'components/atoms/FieldError';
import Seo from 'components/Seo';
import UserProfilePlaceholder from './UserProfilePlaceholder';
import RenderDelay from 'components/RenderDelay';
import Alert from 'components/Alert';
import { goBack, getErrorMessage } from 'utils/helpers';
import useAsyncErrJson from 'utils/hooks/useAsyncErrJson';
import BackButton from 'components/BackButton';
import UnsavedChanges from 'components/UnsavedChanges/UnsavedChanges';
import moment from 'moment';

import css from './UserProfile.module.scss';

const Container: React.FC = ({ children }) => {
  return (
    <DefaultLayout>
      <Seo title="Profile" />
      <section className={css.userprofilepanel}>{children}</section>
    </DefaultLayout>
  );
};

interface FormData {
  firstname: string;
  lastname: string;
  company: string;
  email: string;
}

const validationSchema = yupObject<FormData>({
  firstname: userShape.firstname.label('First name'),
  lastname: userShape.lastname.label('Last name'),
  company: userShape.company.label('Company'),
  email: userShape.email.label('Email'),
});

const UserProfile: React.FC = () => {
  const { signal } = useSignal();
  const { user, setUser } = useContext(UserContext);
  const [showUnsavedChangesPopup, setUnsavedChangesPopup] = useState<boolean>(
    false
  );

  const {
    register: fieldRef,
    handleSubmit,
    getValues,
    errors: formErr,
    reset: resetForm,
    formState: { dirty },
  } = useForm<FormData>({
    validationSchema,
  });

  const initialFetchPromise = async () => {
    const userId = user!.id;
    const result = await UserApi.get(userId, { signal });
    return result;
  };

  const { current: initialFetchCurrent } = useAsync(initialFetchPromise, {
    immediate: [],
    onCurrentChange({ current, data, err }) {
      switch (current) {
        case AsyncState.Success:
          const user = data!;
          setUser(user, UserStateReason.ValueBecauseValidSession);
          resetForm(
            {
              firstname: user.firstname,
              lastname: user.lastname,
              company: user.company,
              email: user.email,
            },
            // @ts-ignore
            { dirty: false }
          );
          break;
      }
    },
  });
  const isInitialFetchPending = initialFetchCurrent === AsyncState.Pending;

  const formPromise = async () => {
    const userId = user!.id;
    const values = getValues();
    const userDetails = await UserApi.get(userId, { signal });
    const result = await UserApi.update(
      { ...userDetails, ...values },
      { signal }
    );
    return result;
  };

  const { run: sendRequest, current, err } = useAsync(formPromise, {
    onCurrentChange({ current, data, err }) {
      switch (current) {
        case AsyncState.Success:
          const user = data!;
          setUser(user, UserStateReason.ValueBecauseValidSession);
          resetForm(
            {
              firstname: user.firstname,
              lastname: user.lastname,
              company: user.company,
              email: user.email,
            },
            // @ts-ignore
            { dirty: false }
          );
          break;
      }
    },
  });
  const isPending = current === AsyncState.Pending;
  const isSuccess = current === AsyncState.Success;

  const errJson = useAsyncErrJson(current, err, signal);
  const errMsg = getErrorMessage(err, { errJson });
  const successMsg = isSuccess ? 'Details saved successfully' : null;

  const onSubmit = handleSubmit(async () => {
    await sendRequest();
  });

  if (isInitialFetchPending)
    return (
      <Container>
        <h2 className="userpageheading">Edit profile</h2>
        <RenderDelay>
          <UserProfilePlaceholder />
        </RenderDelay>
      </Container>
    );

  return (
    <Container>
      <h2 className="userpageheading">
        <BackButton
          onClick={() => {
            if (dirty) setUnsavedChangesPopup(true);
            else goBack();
          }}
        />
        Edit profile
      </h2>
      <Alert error={errMsg} success={successMsg} />
      <form onSubmit={onSubmit} noValidate spellCheck={false}>
        <section className="userform">
          <div className={css.namerow}>
            <Label text="First name">
              <Input name="firstname" ref={fieldRef} />
              <FieldError err={formErr.firstname} />
            </Label>
            <Label text="Last name">
              <Input name="lastname" ref={fieldRef} />
              <FieldError err={formErr.lastname} />
            </Label>
          </div>
          <Label text="Company">
            <Input name="company" ref={fieldRef} />
            <FieldError err={formErr.company} />
          </Label>
          <Label text="Username">
            <p>{user?.username}</p>
          </Label>
          <Label text="Email">
            <Input name="email" type="email" ref={fieldRef} />
            <FieldError err={formErr.email} />
          </Label>
          <Label
            text="AWS Marketplace Subscription Status"
            tip="Reflects the Subscription Status for the RocketCX Enterprise Connector as shown on the AWS Marketplace"
          >
            <p>{user?.subscriptionStatusName}</p>
          </Label>
          {user?.subscriptionDate && (
            <Label text="Subscribed on">
              <p>
                {moment(user?.subscriptionDate).format('DD-MM-YYYY HH:mm:ss')}
              </p>
            </Label>
          )}
          {user?.unsubscriptionDate && (
            <Label text="Unsubscribed on">
              <p>
                {moment(user?.unsubscriptionDate).format('DD-MM-YYYY HH:mm:ss')}
              </p>
            </Label>
          )}
          <Label text="Created on">
            <p>{moment(user?.dateCreated).format('DD-MM-YYYY HH:mm:ss')}</p>
          </Label>
          {user?.dateUpdated && (
            <Label text="Updated on">
              <p>{moment(user?.dateUpdated).format('DD-MM-YYYY HH:mm:ss')}</p>
            </Label>
          )}
        </section>
        <section className="btnrow">
          <Button type="submit" kind="brand" loading={isPending}>
            Save Changes
          </Button>
          <Button onClick={() => goBack()}>Cancel</Button>
        </section>
      </form>
      {showUnsavedChangesPopup && (
        <UnsavedChanges handleClose={() => setUnsavedChangesPopup(false)} />
      )}
    </Container>
  );
};

export default UserProfile;
