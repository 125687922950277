import React, { useState } from 'react';
import { noop } from 'utils/helpers';

// TODO Improve reset functionality

const useInput = (
  initialValue = '',
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void = noop,
  onReset: () => void = noop
) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e);
  };

  const resetValue = () => {
    setValue(initialValue);
    onReset();
  };

  return { value, onChange: handleChange, resetValue };
};

export default useInput;
