import React from 'react';

export const PluginAdministration = (
  <section>
    <h3 className="sectiontopic">Plugin Administration</h3>
    <ol>
      <li>
        <strong>Admin Console</strong>
        <p>
          The Admin Console allows us to save define the following RocketCX CCP
          configurations:
        </p>
        <p>
          Amazon Connect Properties required to set up integration between
          ServiceNow instance and Amazon Connect Instance.
        </p>
        <ul>
          <li>
            Related record tables are available for selection on RocketCX CCP.
          </li>
          <li>
            Contact tables which will be queried to display Customer contacts.
          </li>
          <li>Enable or disable reporting for Service Centre Dashboard.</li>
        </ul>
        <p>
          Here Plug-in Administrator can test the connectivity between Amazon
          Connect and ServiceNow, based on the connection results,
          configurations can be modified.
        </p>
      </li>
      <li>
        <strong>Disposition Codes</strong>
        <p>
          Plugin Administrator can define disposition codes which will be used
          in RocketCX CCP to update the interaction with customer using call log
          once a call is finished.
        </p>
      </li>
      <li>
        <strong>Create List (Related Record table)</strong>
        <p>
          Plugin Administrator can define or select modules which can be used to
          create a new record. RocketCX Enterprise Connector supports three
          modules viz. Incident, Customer Service and Human Resource.
        </p>
      </li>
      <li>
        <strong>Contact List</strong>
        <p>
          Plugin Administrator can define or control which tables will be
          searched to find contacts that match the phone number for caller or
          list of contacts which will be displayed on RocketCX CCP
        </p>
      </li>
    </ol>
  </section>
);
