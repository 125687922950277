import React from 'react';

export const Features = (
  <section>
    <h3 className="sectiontopic">Features</h3>
    <ol>
      <li>
        <strong>ServiceNow Integration</strong>
        <p>
          All standard call handling features available natively within the
          ServiceNow interface – Answer, Ignore, Place on hold, Consult,
          Transfer, Conference and Manage Agent Status.
        </p>
      </li>
      <li>
        <strong>Click to Dial</strong>
        <p>
          Click on any telephone number or contact field within ServiceNow to
          make an outbound call. RocketCX Enterprise Connector intelligently
          handles country and dialling codes providing a one-click dialling
          experience for outbound contact centres. Create favourites for even
          faster access.
        </p>
      </li>
      <li>
        <strong>Screen Pop</strong>
        <p>
          Display caller details along with any open incidents or cases before
          the call is answered, enabling the agent to provide a warm start to
          the call
        </p>
      </li>
      <li>
        <strong>Automated Call Logging & Recording</strong>
        <p>
          Call logs are created automatically capturing contact and agent
          details, call duration, summary, notes, disposition codes, customer
          satisfaction score and access to a full recording of the call.
        </p>
      </li>
      <li>
        <strong>Create Record</strong>
        <p>
          Create ServiceNow Incidents and Cases from within the RocketCX
          Enterprise Connector interface linking contacts, call logs and call
          recordings automatically.
        </p>
      </li>
      <li>
        <strong>Reporting & Dashboards</strong>
        <p>
          Real-time service centre dashboards and historical trend reports
          provide the visibility supervisors need to manage their teams all
          within ServiceNow.
        </p>
      </li>
      <li>
        <strong>Easy to Configure</strong>
        <p>
          Customisable disposition codes and related record lookups allow the
          agent experience to be fully customised.
        </p>
      </li>
    </ol>
  </section>
);
