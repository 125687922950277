import React from 'react';

import css from './Screenshot.module.scss';

const Screenshot: React.FC<{ src: string | undefined }> = ({ src }) => {
  return (
    <a
      className={css.screenshot}
      href={src}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={src} alt="" />
    </a>
  );
};

export default Screenshot;
