import React from 'react';

import NavBar from 'components/NavBar';
import Footer from 'components/Footer';

import css from './DefaultLayout.module.scss';

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <div className={css.defaultlayout}>
      <NavBar />
      <main className={css.maincontent}>{children}</main>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
