import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { goBack } from 'utils/helpers';

import css from './BackButton.module.scss';

const BackButton: React.FC<{ onClick?: Function }> = ({ onClick = goBack }) => {
  return (
    <button className={css.backbutton} onClick={() => onClick()}>
      <FontAwesomeIcon icon="arrow-left" />
    </button>
  );
};

export default BackButton;
