import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from './SearchField.module.scss';
import Input from 'components/atoms/Input';

interface AppUserSearchFieldProps {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  resetValue: () => void;
}

const AppUserSearchField: React.FC<AppUserSearchFieldProps> = ({
  placeholder,
  value,
  onChange,
  resetValue,
}) => {
  return (
    <div className={css.appusersearcharea}>
      <form
        onSubmit={e => {
          e.preventDefault();
        }}
        spellCheck={false}
      >
        {!value && <FontAwesomeIcon className={css.searchicon} icon="search" />}
        <Input
          type="search"
          autoFocus
          className={css.appusersearchfield}
          placeholder={`     ${placeholder}`}
          value={value}
          onChange={e => {
            if (e.target.value.includes('%')) {
              return;
            }
            onChange(e);
          }}
          maxLength={150}
        />
        {/* 
          Make type="button" and not `reset` as we are resetting manually. 
          TODO Do it in a better way in v1.2
        */}
        {value && (
          <button
            type="button"
            className={css.closebutton}
            onClick={resetValue}
          >
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </form>
    </div>
  );
};

export default AppUserSearchField;
