import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlus,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faTimesCircle,
  faTimes,
  faArrowCircleDown,
  faSearch,
  faInfoCircle,
  faSpinner,
  faUsers,
  faDesktop,
  faCopy,
  faExclamationCircle,
  faCheckCircle,
  faUserClock,
  faUserTimes,
  faUserSlash,
  faUserCheck,
  faUnlink,
  faArrowLeft,
  faHeartBroken,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faPlus,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faTimesCircle,
  faTimes,
  faArrowCircleDown,
  faSearch,
  faInfoCircle,
  faSpinner,
  faUsers,
  faDesktop,
  faCopy,
  faExclamationCircle,
  faCheckCircle,
  faUserClock,
  faUserTimes,
  faUserSlash,
  faUserCheck,
  faUnlink,
  faArrowLeft,
  faHeartBroken,
  faUser
);
