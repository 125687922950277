import React from 'react';

export const ControlPanel = (
  <section>
    <h3 className="sectiontopic">
      RocketCX's Contact Control Panel (RocketCX CCP)
    </h3>
    <p>
      Service Centre Agents use the RocketCX CCP to communicate with contacts
      (customers). RocketCX CCP is built upon the ServiceNow platform and
      integrated with Amazon Connect Service to provide telephone operations
      over voice. Features of RocketCX CCP are:
    </p>
    <ul>
      <li>
        Accessing the RocketCX CCP: Service Centre Agent can access RocketCX CCP
        within ServiceNow and can perform call functions and access contact
        information simultaneously.
      </li>
      <li>
        Logging on: Agent can log in to Amazon Connect using RocketCX CCP itself
        and don’t have to log in separately.
      </li>
      <li>
        Outbound Dialler: Agent can dial a telephone number using a numeric dial
        pad given.
      </li>
      <li>
        Contact List: Agent can view a filterable list of contacts in the
        RocketCX CCP with the contacts being the users, contacts or customers in
        ServiceNow system so that Agent can call them from RocketCX CCP.
      </li>
      <li>
        Agent List: Agent can view a filterable list of other agents in the
        RocketCX CCP so that Agent can call them from the RocketCX CCP.
      </li>
      <li>
        Favourites List: Agent can create a list of favourite contacts and/or
        agents using RocketCX CCP and can see them in the Favourites tab.
      </li>
      <li>
        Recent Call History: Agent can see call history of their recent outgoing
        or incoming calls.
      </li>
      <li>
        Outbound Call: Agent can make an outbound call using either by clicking
        on a contact or agent from a contact list or using the dialler.
      </li>
      <li>
        Incoming Call: Agent can accept an incoming call on CCP in Call tab.
      </li>
      <li>
        Call Info: As soon as Amazon Connect Contact Centre passes customer’s
        call to RocketCX CCP, it pulls Customer details from ServiceNow tables
        and displays to Agent for a quick start. Details include Customer Name
        and Caller ID (Phone Number). It also pulls the related record from
        ServiceNow if Customer provides record number via IVR or inputs number
        using the phone. This displays all the related records in a dropdown
        list on RocketCX CCP which makes it easy for Agent to select customer
        records rather than searching it in the list of all records.
      </li>
      <li>
        Create Related Record: Agent can create a new record from RocketCX CCP
        itself. It can be an Incident, Customer Service Case or Human Resource
        Case.
      </li>
      <li>
        Call Functions: Agent can put a call on hold or resume it, initiate a
        second call, switch between calls, transfer calls, Conference, Mute,
        Unmute, End Call.
      </li>
      <li>
        Agent Status: RocketCX CCP pulls different status values from Amazon
        Connect and makes it available for selection in RocketCX CCP also sets
        the same status which was set in Amazon Connect earlier.
      </li>
    </ul>
  </section>
);
