import React from 'react';

const IntroContent = (
  <div>
    <h1 className="docsectiontitle">Introduction</h1>
    <section className="sectionmaterial">
      <p>
        'RocketCX Enterprise Connector for ServiceNow (hereafter referred as
        RocketCX Enterprise Connector)’ integrates Amazon Connect with
        ServiceNow providing a rich computer telephony integration between one
        of the most innovative cloud contact centre technology and your favorite
        CRM. RocketCX Enterprise Connector makes contact centre agents more
        efficient, reduces call costs and improves customer experience.
      </p>
    </section>
  </div>
);

const FeaturesContent = (
  <div>
    <h1 className="docsectiontitle">Features</h1>
    <section className="sectionmaterial">
      <ul>
        <li>
          <strong>ServiceNow Integration</strong> – All standard call handling
          features available natively within the ServiceNow interface. This
          includes Answer, Ignore, Place on hold, Consult, Transfer, Conference
          and Manage Agent Status.
        </li>
        <li>
          <strong>Intelligent Dialling</strong> – Click on any telephone number
          or contact field within ServiceNow to make an outbound call. RocketCX
          Enterprise Connector intelligently handles country and dialling codes
          providing a one click dialling experience for outbound contact
          centres. Create favorites within RocketCX Enterprise Connector for
          even faster access.
        </li>
        <li>
          <strong>Screen Pop</strong> – Display caller details along with any
          open incidents or cases before the call is answered, enabling the
          agent to provide a warm start to the call.
        </li>
        <li>
          <strong>Automated Call Logging & Recording</strong> – Call logs are
          created automatically capturing contact and agent details, call
          duration, summary, notes, disposition codes, customer satisfaction
          score and access to a full recording of the call.
        </li>
        <li>
          <strong>Create Record</strong> – Create ServiceNow Incidents and Cases
          from within the RocketCX Enterprise Connector interface linking
          contacts, call logs and call recordings automatically.
        </li>
        <li>
          <strong>Reporting & Dashboards</strong> – Real-time service centre
          dashboards and historical trend reports provide the visibility
          supervisors need to manage their teams all within ServiceNow.
        </li>
      </ul>
    </section>
  </div>
);

const BenefitsContent = (
  <div>
    <h1 className="docsectiontitle">Benefits</h1>
    <section className="sectionmaterial">
      <ul>
        <li>
          Telephony system integration with ServiceNow eliminating frequent
          screen switching to make and receive calls.
        </li>
        <li>
          Outbound calling by clicking on the user in ServiceNow eliminating
          manual dialling effort.
        </li>
        <li>
          Reports, Metrics and Dashboard to assess Service Centre performance.
        </li>
        <li>
          Call records available on ServiceNow to review agent performance.
        </li>
      </ul>
      <p>
        RocketCX Enterprise Connector is available for ServiceNow IT Service
        Management, HR Service Management and Customer Service Management.
      </p>
    </section>
  </div>
);

export const IntroductionContent = {
  content: IntroContent,
  features: FeaturesContent,
  benefits: BenefitsContent,
};
