import React from 'react';
import image1a from '../images/image1a.png';
import CaptionedImage from '../CaptionedImage';

const IntroContent = (
  <div>
    <h1 className="docsectiontitle">Overview</h1>
    <section className="sectionmaterial">
      <p>
        RocketCX Enterprise Connector is a computer telephony integration (CTI)
        between Amazon Connect and ServiceNow. It provides service centre agents
        with access to a comprehensive set of telephony controls within the
        ServiceNow user interface, meaning they don’t need to switch screens to
        make and receive calls. RocketCX Enterprise Connector needs to be
        configured in two places: the RocketCX Enterprise Connector application
        in ServiceNow and the RocketCX{' '}
        <a
          href="https://admin.rocket-cx.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          website
        </a>
        . As shown below, this enables the ServiceNow{' '}
        <a
          href="https://codecreative.io/guides/getting-started-on-servicenow/what-is-a-servicenow-instance/"
          target="_blank"
          rel="noopener noreferrer"
        >
          instance
        </a>{' '}
        to send usage data to Amazon:
      </p>
      <CaptionedImage src={image1a} label="How Usage Data is Sent to Amazon" />
      <p>
        In this document, we discuss how to configure your account on RocketCX
        to enable updates from ServiceNow.
      </p>
    </section>
  </div>
);

export const IntroductionContent = {
  content: IntroContent,
};
