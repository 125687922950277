import React from 'react';
import image91a from '../images/91a.png';
import image92a from '../images/92a.png';
import image92b from '../images/92b.png';
import image92c from '../images/92c.png';
import image92d from '../images/92d.png';
import image93a from '../images/93a.png';
import image93b from '../images/93b.png';
import { Link } from 'react-router-dom';
import Screenshot from '../Screenshot';

const RContent = (
  <div>
    <h1 className="docsectiontitle">Reporting</h1>
    <section className="sectionmaterial">
      <p>
        RocketCX Enterprise Connector provides a Service Centre Dashboard which
        displays both real-time and historical metrics from Amazon Connect, as
        well as ServiceNow Standard reports.
      </p>
      <ol>
        <li>
          <Link to="/user-guide/reporting/service-centre-dashboard">
            Service Centre Dashboard
          </Link>
        </li>
        <li>
          <Link to="/user-guide/reporting/supervisor-dashboard">
            Supervisor Dashboard
          </Link>
        </li>
        <li>
          <Link to="/user-guide/reporting/my-dashboard">My Dashboard</Link>
        </li>
      </ol>
    </section>
  </div>
);

const SCDashboardContent = (
  <div>
    <h1 className="docsectiontitle">Service Centre Dashboard</h1>
    <section className="sectionmaterial">
      <p>
        The Real-time Dashboard can be accessed by the Contact Centre Supervisor
        using the{' '}
        <em>
          <u>RocketCX > Service Centre Dashboard</u>
        </em>{' '}
        application menu which loads a report as shown in the screenshot below.
      </p>
      <Screenshot src={image91a} />
    </section>
  </div>
);

const SDashboardContent = (
  <div>
    <h1 className="docsectiontitle">Supervisor Dashboard</h1>
    <section className="sectionmaterial">
      <p>
        The Supervisor dashboard can be accessed by the Contact Centre
        Supervisor using{' '}
        <em>
          <u>RocketCX > Supervisor Dashboard</u>
        </em>
        .
      </p>
      <h2 className="sectionsubtitle">Historic Reports</h2>
      <Screenshot src={image92a} />
      <h2 className="sectionsubtitle">Today's Trends</h2>
      <Screenshot src={image92b} />
      <h2 className="sectionsubtitle">Agent Report - Historical</h2>
      <Screenshot src={image92c} />
      <h2 className="sectionsubtitle">Agent Report - Today's Trend</h2>
      <Screenshot src={image92d} />
    </section>
  </div>
);

const MDashboardContent = (
  <div>
    <h1 className="docsectiontitle">My Dashboard</h1>
    <section className="sectionmaterial">
      <p>
        Users with Agent role can see their own metrics (Historical & Today’s)
        using{' '}
        <em>
          <u>RocketCX > My Dashboard</u>
        </em>
        .
      </p>
      <Screenshot src={image93a} />
      <Screenshot src={image93b} />
    </section>
  </div>
);

export const ReportingContent = {
  content: RContent,
  scDashboard: SCDashboardContent,
  mDashboard: MDashboardContent,
  sDashboard: {
    content: SDashboardContent,
  },
};
