import React from 'react';
import css from './clouds.module.scss';

interface CloudProps {
  clouds?: number;
}

const Clouds: React.FC<CloudProps> = ({ clouds }) => {
  const { cloud, one, two, three, four, five } = css;

  return <>{conditionOnClouds()}</>;

  function conditionOnClouds() {
    switch (clouds) {
      case 1:
        return <div className={`${cloud} ${one}`}></div>;
      case 2:
        return (
          <>
            <div className={`${cloud} ${one}`}></div>
            <div className={`${cloud} ${two}`}></div>
          </>
        );
      case 3:
        return (
          <>
            <div className={`${cloud} ${one}`}></div>
            <div className={`${cloud} ${two}`}></div>
            <div className={`${cloud} ${three}`}></div>
          </>
        );
      case 4:
        return (
          <>
            <div className={`${cloud} ${one}`}></div>
            <div className={`${cloud} ${two}`}></div>
            <div className={`${cloud} ${three}`}></div>
            <div className={`${cloud} ${four}`}></div>
          </>
        );
      default:
        return (
          <>
            <div className={`${cloud} ${one}`}></div>
            <div className={`${cloud} ${two}`}></div>
            <div className={`${cloud} ${three}`}></div>
            <div className={`${cloud} ${four}`}></div>
            <div className={`${cloud} ${five}`}></div>
          </>
        );
    }
  }
};

export default Clouds;
