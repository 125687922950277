import React from 'react';

import Link from '../atoms/Link';

import css from './Footer.module.scss';

const Footer: React.FC = () => {
  return (
    <footer className={css.appfooter}>
      <div className={css.companydetails}>
        <h1 className={css.footerheading}>
          RocketCX
          {/* <div className="ecsdot"></div> */}
        </h1>
        <a
          href="https://ecs.co.uk"
          target="_blank"
          rel="noopener noreferrer"
          className={css.companylink}
        >
          www.ecs.co.uk
        </a>
        <span>&#169; ECS 2020. All rights reserved.</span>
      </div>
      <div style={{ flex: '1' }}></div>
      <div className={css.productlinks}>
        <h1 className={css.footerheading}>Links</h1>
        <Link to="/docs/about">About</Link>
        <Link to="/docs/privacy">Privacy and Cookie Policy</Link>
        <Link to="/docs/eula">End User License Agreement</Link>
      </div>
      <div className={css.contactdetails}>
        <h1 className={css.footerheading}>Contact Us</h1>
        <a href="mailto:RocketCX.support@ecs.co.uk" className={css.companylink}>
          RocketCX.support@ecs.co.uk
        </a>
        <div>09:00 - 17:00 (GMT)</div>
        <div>Monday - Friday (excluding U.K Public Holidays)</div>
      </div>
    </footer>
  );
};

export default Footer;
