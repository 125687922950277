import React from 'react';

import Modal, { DialogHeading } from 'components/Modal';
import Button from 'components/atoms/Button';
import { goBack } from 'utils/helpers';

const UnsavedChanges: React.FC<{ handleClose: Function }> = ({
  handleClose,
}) => {
  return (
    <Modal isDialog handleClose={() => handleClose()}>
      <DialogHeading>Unsaved Changes</DialogHeading>
      <div className="dialogcontent">
        Your unsaved changes will be lost. Are you sure you want to go back ?
      </div>
      <div className="btnrow">
        <Button kind="brand" onClick={goBack}>
          Yes
        </Button>
        <Button onClick={() => handleClose()}>No</Button>
      </div>
    </Modal>
  );
};

export default UnsavedChanges;
