import React, { createContext, useState, useEffect } from 'react';

import { history } from './App';
import User, { Authority, UserRole } from 'schemas/User';
import StorageService from 'services/StorageService';
import { emitter, EmitterEvent } from 'services/EventService';
import { HTTPError } from 'ky';
import AuthApi from 'api/AuthApi';
import { apiWithoutHooks } from 'api';
import ReloadService from 'services/ReloadService';

const getRolesFromAuthorities = (authorities: Authority[]): UserRole[] =>
  authorities.map(authorityEntry => authorityEntry.authority);

export const isUser = (authorities: Authority[]) =>
  authorities.length === 1 &&
  getRolesFromAuthorities(authorities).includes(UserRole.User);

export const isAdmin = (authorities: Authority[]) =>
  getRolesFromAuthorities(authorities).includes(UserRole.Admin);

export enum UserStateReason {
  Initial = 'Initial',
  ValueBecauseValidSession = 'ValueBecauseValidSession',
  NullBecauseInvalidSession = 'NullBecauseInvalidSession',
  NullBecauseLoggedOut = 'NullBecauseLoggedOut',
}

interface SetUserShape {
  (user: User | null, reason: UserStateReason): void;
}

interface UserContextShape {
  user: User | null;
  setUser: SetUserShape;
  role: UserRole | null;
  stateReason: UserStateReason;
}

const UserContext = createContext<UserContextShape>({
  user: null,
  setUser: user => {},
  role: null,
  stateReason: UserStateReason.Initial,
});

export const UserContextGlobalProvider: React.FC = ({ children }) => {
  const [user, _setUser] = useState<User | null>(null);
  const [role, setRole] = useState<UserRole | null>(null);
  const [stateReason, setStateReason] = useState<UserStateReason>(
    UserStateReason.Initial
  );
  const userIdKey = 'browserUserId';
  const authoritiesKey = 'browserUserAuthorities';

  /**
   * `setUser` wrapper to add entries in storage
   */
  function setUser(user: User | null, reason: UserStateReason) {
    _setUser(user);
    if (user) {
      let authorities: Authority[] =
        user.authorities ?? StorageService.getJson(authoritiesKey);
      user.authorities = authorities;
      setRole(isAdmin(authorities) ? UserRole.Admin : UserRole.User);
      StorageService.set(userIdKey, user.id);
      StorageService.set(authoritiesKey, authorities);
    } else {
      setRole(null);
      StorageService.remove(userIdKey);
      StorageService.remove(authoritiesKey);
    }
    setStateReason(reason);
  }

  // Assign functionality of `setUser` to event emitter
  useEffect(() => {
    const setUserFromEvent = ({
      user,
      reason,
    }: {
      user: User;
      reason: UserStateReason;
    }) => {
      setUser(user, reason);
    };
    emitter.on(EmitterEvent.SetUser, setUserFromEvent);
    return () => emitter.off(EmitterEvent.SetUser, setUserFromEvent);
  }, []);

  // Make initial fetch when app loads
  useEffect(() => {
    async function getUser() {
      /**
       * If is authenticated but userId is not in backend 404 or
       * if userId is present but forbidden (used someone else's userId), then logout.
       */
      async function logoutAndRedirect(e: Error) {
        if (e instanceof HTTPError) {
          if (e.response.status === 404 || e.response.status === 403) {
            try {
              await AuthApi.logout();
              history.push({
                pathname: '/login',
                state: {
                  reason: 'You have been logged out. Please login to continue.',
                  next: window.location.href.endsWith('/login')
                    ? null
                    : history.location,
                },
              });
              setUser(null, UserStateReason.NullBecauseLoggedOut);
              ReloadService.triggerReload();
            } catch (error) {
              // TODO retry action?
            }
          } else if (e.response.status === 401) {
            setUser(null, UserStateReason.NullBecauseInvalidSession);
          }
        }
      }

      const userId = StorageService.get(userIdKey) ?? '__no-user-id';

      // This will handle 404 for '__no-user-id'->
      // User may go to sesion storage and change userId from there,
      // resulting in 404. This will be handled by sending that request instead.
      if (userId === '__no-user-id') {
        try {
          await AuthApi.logout();
          setUser(null, UserStateReason.NullBecauseLoggedOut);
          // Don't push to /login here as this also covers the scenario when
          // user is not logged in, so redirection should be performed by RouteGuard instead.
          return;
        } catch (e) {
          // TODO retry action?
        }
      } else {
        try {
          const user: User = await apiWithoutHooks.get(`user/${userId}`).json();
          setUser(user, UserStateReason.ValueBecauseValidSession);
        } catch (e) {
          await logoutAndRedirect(e);
        }
      }
    }

    getUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        role,
        stateReason,
      }}
      children={children}
    />
  );
};

export default UserContext;
