import React, { useState, useRef } from 'react';

import DefaultLayout from 'layouts/DefaultLayout';
import Seo from 'components/Seo';
import Instance from 'schemas/Instance';
import AppSearchField from 'components/SearchField';
import InstanceApi from 'api/InstanceApi';
import useSignal from 'utils/hooks/useSignal';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import useInput from 'utils/hooks/useInput';
import debounce from 'utils/debounce';
import { RenderContent, RequestReason } from './content';

import css from 'pages/Instances/Instances.module.scss';
import useQueryParams from 'utils/hooks/useQueryParams';
import { setUrlQuery } from 'utils/helpers';

interface InstancesPromiseParams {
  text: string;
  pageNo: number;
  clearInstances?: boolean;
  requestReason: RequestReason;
}

const AdminInstances: React.FC = () => {
  const pageSize = 8;

  const queryParams = useQueryParams();
  const initialSearchText = queryParams.get('text')?.trim() ?? '';

  const { signal } = useSignal();

  const debouncedCallback = useRef(
    debounce((text: string) => {
      const textToPass = text.trim();
      const isTextEmpty = textToPass.length === 0;
      const requestReason = isTextEmpty
        ? RequestReason.InitialFetch
        : RequestReason.Search;

      setUrlQuery(queryParams, {
        text: textToPass,
      });

      enabledInstancesSendRequest({
        pageNo: 0,
        text: textToPass,
        clearInstances: true,
        requestReason,
      });
      disabledInstancesSendRequest({
        pageNo: 0,
        text: textToPass,
        clearInstances: true,
        requestReason,
      });
    }, 200)
  );

  const search = useInput(
    initialSearchText,
    e => {
      debouncedCallback.current(e.target.value);
    },
    () => {
      debouncedCallback.current('');
    }
  );

  const [enabledInstances, setEnabledInstances] = useState<Instance[]>([]);
  const [enabledInstancesPageNo, setEnabledInstancesPageNo] = useState(0);
  const [enabledInstancesTotalPages, setEnabledInstancesTotalPages] = useState(
    0
  );

  // `clearInstances` is read in `onCurrentChange` of `useAsync` for this variable
  const enabledInstancesPromise = async ({
    text,
    pageNo,
    clearInstances = false,
  }: InstancesPromiseParams) => {
    const instances = await InstanceApi.adminList(
      { text, pageNo, pageSize, enabled: true },
      { signal }
    );
    return instances;
  };

  const enabledInstancesLastRequestReason = useRef<RequestReason>(
    RequestReason.InitialFetch
  );

  const {
    run: enabledInstancesSendRequest,
    current: enabledInstancesCurrent,
  } = useAsync(enabledInstancesPromise, {
    mode: 'last',
    immediate: [
      {
        pageNo: 0,
        text: initialSearchText,
        requestReason: RequestReason.InitialFetch,
      },
    ],
    onCurrentChange({ current, data, err }, params) {
      const { clearInstances, requestReason } = params[0];

      enabledInstancesLastRequestReason.current = requestReason;

      switch (current) {
        case AsyncState.Success:
          const result = data!;
          if (clearInstances === true) {
            setEnabledInstances(() => [...result.content]);
          } else {
            setEnabledInstances(instances => [...instances, ...result.content]);
          }
          setEnabledInstancesPageNo(result.number);
          setEnabledInstancesTotalPages(result.totalPages);
          break;
      }
    },
  });

  const [disabledInstances, setDisabledInstances] = useState<Instance[]>([]);
  const [disabledInstancesPageNo, setDisabledInstancesPageNo] = useState(0);
  const [
    disabledInstancesTotalPages,
    setDisabledInstancesTotalPages,
  ] = useState(0);

  const disabledInstancesPromise = async ({
    text,
    pageNo,
    clearInstances = false,
  }: InstancesPromiseParams) => {
    const instances = await InstanceApi.adminList(
      { text, pageNo, pageSize, enabled: false },
      { signal }
    );
    return instances;
  };

  const disabledInstancesLastRequestReason = useRef<RequestReason>(
    RequestReason.InitialFetch
  );

  const {
    run: disabledInstancesSendRequest,
    current: disabledInstancesCurrent,
  } = useAsync(disabledInstancesPromise, {
    immediate: [
      {
        pageNo: 0,
        text: initialSearchText,
        requestReason: RequestReason.InitialFetch,
      },
    ],
    onCurrentChange({ current, data, err }, params) {
      const { clearInstances, requestReason } = params[0];

      disabledInstancesLastRequestReason.current = requestReason;

      switch (current) {
        case AsyncState.Success:
          const result = data!;
          if (clearInstances === true) {
            setDisabledInstances(() => [...result.content]);
          } else {
            setDisabledInstances(instances => [
              ...instances,
              ...result.content,
            ]);
          }
          setDisabledInstancesPageNo(result.number);
          setDisabledInstancesTotalPages(result.totalPages);
          break;
      }
    },
  });

  return (
    <DefaultLayout>
      <Seo title="Instances" />
      <section className={css.instancegridpanel}>
        <section className={css.appheader}>
          <h1 className={`userpageheading ${css.userpageheading}`}>
            Instances
          </h1>
          <span style={{ flex: '1' }}></span>
          <AppSearchField placeholder="Search for instances" {...search} />
        </section>
        <RenderContent
          enabledInstances={{
            current: enabledInstancesCurrent,
            instances: enabledInstances,
            loadMore: () => {
              enabledInstancesSendRequest({
                text: search.value,
                pageNo: enabledInstancesPageNo + 1,
                requestReason: RequestReason.LoadMore,
              });
            },
            requestReason: enabledInstancesLastRequestReason.current,
            pageNo: enabledInstancesPageNo,
            totalPages: enabledInstancesTotalPages,
          }}
          disabledInstances={{
            current: disabledInstancesCurrent,
            instances: disabledInstances,
            loadMore: () => {
              disabledInstancesSendRequest({
                text: search.value,
                pageNo: disabledInstancesPageNo + 1,
                requestReason: RequestReason.LoadMore,
              });
            },
            requestReason: disabledInstancesLastRequestReason.current,
            pageNo: disabledInstancesPageNo,
            totalPages: disabledInstancesTotalPages,
          }}
        />
      </section>
    </DefaultLayout>
  );
};

export default AdminInstances;
