import React from 'react';
import { Reporting } from './IssuesAndProblems/Reporting';
import { ServiceNowCCP } from './IssuesAndProblems/ServiceNowCCP';

export const IssuesAndProblems = (
  <section id="issues-and-limitations">
    <h2 className="sectionsubtitle">4. Known Issues and Limitations</h2>
    <p>
      The following known issues have been identified in the RocketCX Enterprise
      Connector Application with respect to the Reporting Module and RocketCX's
      Contact Control Panel (RocketCX CCP).
    </p>
    {Reporting}
    {ServiceNowCCP}
  </section>
);
