import React from 'react';
import { useForm } from 'react-hook-form';
import { object as yupObject } from 'yup';

import SimpleLayout from 'layouts/SimpleLayout';
import Seo from 'components/Seo';
import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import Clouds from 'components/Clouds';
import { userShape } from 'schemas/User';
import useSignal from 'utils/hooks/useSignal';
import UserApi from 'api/UserApi';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import Alert from 'components/Alert';
import { getErrorMessage } from 'utils/helpers';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import FieldError from 'components/atoms/FieldError';

import css from './ResetPassword.module.scss';
import useAsyncErrJson from 'utils/hooks/useAsyncErrJson';
import useQueryParams from 'utils/hooks/useQueryParams';

interface FormData {
  password: string;
  rePassword: string;
}

const validationSchema = yupObject<FormData>({
  password: userShape.password.label('Password'),
  rePassword: userShape.rePassword('password').label('Confirm password'),
});

const ResetPassword: React.FC = () => {
  let query = useQueryParams();
  // Get `verificationToken` from URL's query params
  const verificationToken = query.get('verificationToken');
  const isVerificationTokenPresent = Boolean(verificationToken);

  const {
    register: fieldRef,
    handleSubmit,
    getValues,
    errors: formErr,
  } = useForm<FormData>({
    validationSchema,
  });

  const { signal } = useSignal();
  const formPromise = async () => {
    const { password } = getValues();
    const result = await UserApi.resetPassword(
      { password, verificationToken: verificationToken ?? '' },
      { signal }
    );
    return result;
  };

  const { run: sendRequest, current, err } = useAsync(formPromise);
  const isPending = current === AsyncState.Pending;
  const isSuccess = current === AsyncState.Success;

  const successMsg = isSuccess ? 'Password has been reset successfully' : null;
  const errJson = useAsyncErrJson(current, err, signal);
  const errString = !isVerificationTokenPresent
    ? 'No verification token found'
    : null;
  const errMsg = getErrorMessage(errString ?? err, { errJson });

  const onSubmit = handleSubmit(async () => {
    await sendRequest();
  });

  return (
    <SimpleLayout>
      <Seo title="Reset Password" />
      <div className={css.rpcontainer}>
        <section className={css.rppanel}>
          <h2 className="pageheading">Reset password</h2>
          <Alert success={successMsg} error={errMsg} />
          <section className={css.rpinputpanel}>
            <form onSubmit={onSubmit} spellCheck={false}>
              <Label text="New password">
                <Input type="password" name="password" ref={fieldRef} />
                <FieldError err={formErr.password} />
              </Label>
              <Label text="Confirm password">
                <Input type="password" name="rePassword" ref={fieldRef} />
                <FieldError err={formErr.rePassword} />
              </Label>
              <Button
                type="submit"
                kind="brand"
                loading={isPending}
                disabled={!isVerificationTokenPresent}
                className={css.rpsendlinkbtn}
              >
                Save Changes
              </Button>
            </form>
          </section>
          <Link to="/login" className={css.rploginpagelink}>
            Go to login page
          </Link>
        </section>
      </div>
      {/* Clouds bg */}
      <Clouds />
    </SimpleLayout>
  );
};

const ResetPasswordWrapper: React.FC = () => {
  let query = useQueryParams();
  const verificationToken = query.get('verificationToken') ?? '';

  return <ResetPassword key={verificationToken} />;
};

export default ResetPasswordWrapper;
