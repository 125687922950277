import { IntroductionContent } from './sections/Introduction';
import { UserRolesContent } from './sections/UserRoles';
import { SystemRequirements } from './sections/SystemRequirements';
import { AccessingToolContent } from './sections/AccessingTool';
import { CCPComponentsContent } from './sections/CCPComponents';
import { TelephonyOperationsContent } from './sections/TelephonyOperations';
import { CallLogsRecordingsContent } from './sections/CallLogsRecordings';
import { ReportingContent } from './sections/Reporting';
import { AdministrationContent } from './sections/Administration';
import { ApplicationLogsContent } from './sections/ApplicationLogs';
import { SupportContent } from './sections/Support';
import { GlossaryContent } from './sections/Glossary';

export const mappingTable: any = {
  '/introduction': IntroductionContent.content,
  '/introduction/features': IntroductionContent.features,
  '/introduction/benefits': IntroductionContent.benefits,
  '/user-roles': UserRolesContent.content,
  '/system-requirements': SystemRequirements.content,
  '/accessing-tool': AccessingToolContent.content,
  '/ccp-components': CCPComponentsContent.content,
  '/ccp-components/status': CCPComponentsContent.status,
  '/ccp-components/tabs/fav-contacts': CCPComponentsContent.tabs.favContacts,
  '/ccp-components/tabs/contacts': CCPComponentsContent.tabs.contacts,
  '/ccp-components/tabs/dial-pad': CCPComponentsContent.tabs.dialpad,
  '/ccp-components/tabs/call-history': CCPComponentsContent.tabs.callhistory,
  '/ccp-components/tabs/active-call': CCPComponentsContent.tabs.activecall,
  '/ccp-components/tabs': CCPComponentsContent.tabs.content,
  '/telephony-operations/incoming-call':
    TelephonyOperationsContent.incomingCall,
  '/telephony-operations/outgoing-call':
    TelephonyOperationsContent.outgoingCall.content,
  '/telephony-operations/outgoing-call/click-to-dial':
    TelephonyOperationsContent.outgoingCall.clickToDial,
  '/telephony-operations/related-record':
    TelephonyOperationsContent.relatedRecord,
  '/telephony-operations/related-user-record':
    TelephonyOperationsContent.relatedUserRecord,
  '/telephony-operations/disposition-code':
    TelephonyOperationsContent.dispositionCode,
  '/telephony-operations': TelephonyOperationsContent.content,
  '/call-logs-recordings/call-log': CallLogsRecordingsContent.callLogs,
  '/call-logs-recordings/call-recording-player':
    CallLogsRecordingsContent.callRecording,
  '/call-logs-recordings': CallLogsRecordingsContent.content,
  '/reporting/service-centre-dashboard': ReportingContent.scDashboard,
  '/reporting/supervisor-dashboard': ReportingContent.sDashboard.content,
  '/reporting/my-dashboard': ReportingContent.mDashboard,
  '/reporting': ReportingContent.content,
  '/administration/amazon-connect-queue-records':
    AdministrationContent.acqRecords,
  '/administration/configure-admin-console/amazon-connect':
    AdministrationContent.adminConsole.aConnectTab,
  '/administration/configure-admin-console/service-now':
    AdministrationContent.adminConsole.sNowTab,
  '/administration/configure-admin-console/rocket-cx':
    AdministrationContent.adminConsole.rocketCXTab,
  '/administration/configure-admin-console':
    AdministrationContent.adminConsole.content,
  '/administration/disposition-codes': AdministrationContent.dispositionCodes,
  '/administration': AdministrationContent.content,
  '/application-logs': ApplicationLogsContent.content,
  '/support': SupportContent.content,
  '/glossary': GlossaryContent.content,
};
