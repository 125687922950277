import React from 'react';
import image81a from '../images/81a.png';
import image81b from '../images/81b.png';
import image82a from '../images/82a.png';
import { Link } from 'react-router-dom';
import Screenshot from '../Screenshot';

const CLRContent = (
  <div>
    <h1 className="docsectiontitle">Call Logs and Recordings</h1>
    <section className="sectionmaterial">
      <ol>
        <li>
          <Link to="/user-guide/call-logs-recordings/call-log">Call Logs</Link>
        </li>
        <li>
          <Link to="/user-guide/call-logs-recordings/call-recording-player">
            Call Recording Player
          </Link>
        </li>
      </ol>
    </section>
  </div>
);

const CallLogsContent = (
  <div>
    <h1 className="docsectiontitle">Call Logs</h1>
    <section className="sectionmaterial">
      <p>
        When an Agent ends a call and saves a disposition code, a call log
        record gets created which will record information like Contact ID,
        Agent, Start time, Duration of Call, Disposition Code, Call Recording
        etc.
      </p>
      <p>
        Users with Contact Centre Supervisor role can access these call logs and
        call recordings.
      </p>
      <Screenshot src={image81a} />
      <p>
        Call logs can be accessed from related records like Incident, CSM Case
        or HR Case from their work notes.
      </p>
      <Screenshot src={image81b} />
    </section>
  </div>
);

const CallRecordingContent = (
  <div>
    <h1 className="docsectiontitle">Call Recording Player</h1>
    <section className="sectionmaterial">
      <p>
        The Contact Centre Supervisor can listen to the recordings using Call
        Recording Player embedded into ServiceNow.
      </p>
      <Screenshot src={image82a} />
    </section>
  </div>
);

export const CallLogsRecordingsContent = {
  content: CLRContent,
  callLogs: CallLogsContent,
  callRecording: CallRecordingContent,
};
