import React from 'react';

import ecsLogo from 'assets/img/logo.png';

import css from './LoadingBuffer.module.scss';

const LoaderBar: React.FC = () => (
  <svg width="256px" height="8px" viewBox="0 0 128 12">
    <g transform="translate(49.876)">
      <path fill="#24356d" d="M-128 2H0V10H-128z" />
      <path
        fill="none"
        d="M-122.926 2h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.962h-2.049zm10 0h2.049l-4.768 7.963h-2.049z"
      />
      <path fill="none" d="M-128 2H0v1.5h-128V2zm0 7H0v1h-128V9z" />
      <animateTransform
        attributeName="transform"
        type="translate"
        from="-128 0"
        to="384 0"
        repeatCount="indefinite"
        dur="1250ms"
      />
    </g>
  </svg>
);

/**
 * Displays a loading screen. Useful to show this until app hasn't been fully initialized.
 */
const LoadingBuffer: React.FC = () => {
  return (
    <div className={css.bufferview} key="loading-buff">
      <img src={ecsLogo} className={css.ecsimg} alt="ECS Logo" />
      <div className={css.bufferbar}>
        <LoaderBar />
      </div>
    </div>
  );
};

export default LoadingBuffer;
