import React from 'react';
import image111a from '../images/111a.png';
import Screenshot from '../Screenshot';

const ALContent = (
  <div>
    <h1 className="docsectiontitle">Application Logs</h1>
    <section className="sectionmaterial">
      <p>
        Application logs contain both system logs and call logs. Call logs are
        covered as part of section 8.1 of this document.
      </p>
      <h2 className="sectionsubtitle">System Logs</h2>
      <p>
        The ServiceNow Administrator can access the System Logs module. This
        module stores all the event logs related to RocketCX Enterprise
        Connector. The logs help the Administrator troubleshoot issues when
        something goes wrong in it.
      </p>
      <Screenshot src={image111a} />
    </section>
  </div>
);

export const ApplicationLogsContent = {
  content: ALContent,
};
