import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from './Alert.module.scss';

const AlertBox: React.FC<{ type: 'error' | 'success'; msg: string }> = ({
  type,
  msg,
}) => {
  return (
    <div
      data-testid="alert"
      className={type === 'error' ? css.errorwindow : css.successwindow}
    >
      <FontAwesomeIcon
        className={css.alerticon}
        icon={type === 'error' ? 'exclamation-circle' : 'check-circle'}
      />
      <div id="alertmsg" style={{ wordBreak: 'break-word' }}>
        {msg}
      </div>
    </div>
  );
};

/**
 * Simple alert component that can show success or error message
 */
const Alert: React.FC<{
  success?: string | null;
  error?: string | null;
}> = ({ success, error }) => {
  return (
    <>
      {success && <AlertBox type="success" msg={success} />}
      {error && <AlertBox type="error" msg={error} />}
    </>
  );
};

export default Alert;
