// From https://gist.github.com/avtopilot/7a57d6dc6b53a1185ec831fa937a95b2

type Procedure = (...args: any[]) => any;

interface Debounce {
  (...args: any[]): any;
  clear: () => void;
  flush: () => void;
}

/**
 * @example
 *
 * const callBackWithArgs = (i: number) => i * 2;
 * const fn = debounce(callBackWithArgs, 100, true)(2); // return 4
 *
 * @example
 *
 * const callBack = (a: string) => console.log(a);
 * const fn = debounce(callBack, 1500);
 *
 * fn('a');
 * fn('b');
 * fn('c');
 *
 * // the output is 'c'
 *
 */
const debounce = (
  func: Procedure,
  wait: number,
  immediate: boolean = false
) => {
  let timeout: number | null;
  let args: any;
  let context: any;
  let result: any;

  const later = () => {
    timeout = null;
    if (!immediate) {
      result = func.apply(context, args);
      context = args = null;
    }
  };

  const debouncedFunc: Procedure = function(this: any) {
    context = this;
    args = arguments;
    const callNow = immediate && !timeout;

    if (!timeout) {
      timeout = window.setTimeout(later, wait);
    }

    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };

  const clear = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  const flush = () => {
    if (timeout) {
      result = func.apply(context, args);
      context = args = null;

      clearTimeout(timeout);
      timeout = null;
    }
  };

  const debounced: Debounce = (() => {
    const f: any = debouncedFunc;
    f.clear = clear;
    f.flush = flush;
    return f;
  })();

  return debounced;
};

export default debounce;
