import React from 'react';
import { HTTPError } from 'ky';
import { history } from 'App';
import { BasicResponse } from 'api';

export const setUrlQuery = (
  queryParams: URLSearchParams,
  newParams: { [key: string]: string | null }
) => {
  const newQueryParams = new URLSearchParams(queryParams);
  Object.keys(newParams).forEach(k => {
    const v = newParams[k];
    if (v === null || v === '') {
      newQueryParams.delete(k);
    } else {
      newQueryParams.set(k, v);
    }
  });

  let queryParamsString = newQueryParams.toString();
  const searchString =
    queryParamsString.length !== 0 ? `?${queryParamsString}` : '';

  /*
  TODO actual format is to have query coming first and hash later
    const newUrl = history.createHref({
      ...history.location,
      search: searchString,
    });
    With browser history
    history.replace(newUrl);
  */

  // WONTFIX With hash history
  // const newUrl = history.createHref({
  //   ...history.location,
  //   hash: '',
  //   pathname: '',
  //   search: searchString,
  // });
  // history.replace(`${newUrl}`);
};

export function generateKey() {
  return (Date.now() * 10000 + Math.round(Math.random() * 10000)).toString();
}

/**
 * [NOT FOR PRODUCTION] Takes an object and dumps it into JSX
 * Good for quick debugging
 */
export const jsonDump = (obj: any) => <pre>{JSON.stringify(obj, null, 2)}</pre>;

/**
 * noop (no operation) - An empty function
 * Useful when an argument's default value needs to be an empty function
 */
export const noop = () => {};

/**
 * Helps to escape out from strict typings for object syntax
 */
export interface LooseObject {
  [key: string]: any;
}

/**
 * Sleep function for asynchronous code
 */
export const asyncSleep = (amount = 0) =>
  new Promise(resolve => setTimeout(resolve, amount));

export const removeIfNullOrEmpty = (obj: LooseObject) => {
  const filteredObject = { ...obj };
  for (const key of Object.keys(filteredObject)) {
    const value = filteredObject[key];
    if (
      value == null ||
      (typeof value === 'string' && value.length === 0) ||
      (typeof value === 'object' && (value.length === 0 || value.size === 0))
    ) {
      delete filteredObject[key];
    }
  }
  return filteredObject;
};

/**
 * @property `errJson` Parsed error response in JSON
 * @property `httpStatusMap` Map of status code to its message. If an entry is available, message mapped to that status is shown instead.
 */
interface ErrorResponseAttrs {
  errJson?: any;
  httpStatusMap?: { [key: number]: string };
}

/**
 * Parses different types of error.
 * Deals with ky HTTPError and results in a string or null.
 * If it does not find `err` as HTTPError, it will result in a generic error message.
 */
export function getErrorMessage(
  err: any,
  { errJson = null, httpStatusMap = {} }: ErrorResponseAttrs = {}
) {
  if (!err) {
    return null;
  }

  if (typeof err === 'string') {
    return err;
  }

  // Return error message if it is present in error mapping
  if (err instanceof HTTPError) {
    const { status } = err.response;
    if (Object.keys(httpStatusMap).includes(status.toString())) {
      return httpStatusMap[status];
    }
  }

  // Try to parse response error JSON
  if (
    err instanceof HTTPError &&
    errJson != null &&
    typeof errJson === 'object'
  ) {
    // Try to parse it as backend API error (or an obj that has message property)
    const { message } = errJson as BasicResponse;
    // Message could not be present, so check if its not undefined, i.e. has value present
    if (message) {
      return message;
    }
  }

  // Handle 5xx errors by default
  if (err instanceof HTTPError && err.response.status >= 500) {
    return 'Something went wrong';
  }

  // Could not parse `err`, but error is not null, so show a generic message
  return 'Something went wrong';
}

export function getSuccessMessage(success: any) {
  if (!success) {
    return null;
  }

  if (success && typeof success === 'string') {
    return success;
  }

  if (typeof success === 'object') {
    // Try to parse it as backend API success message (or an obj that has message property)
    const { message } = success as BasicResponse;
    // Message could not be present, so check if its not undefined, i.e. has value present
    if (message) {
      return message;
    }
  }

  // Could not parse `success`, but need a generic success message
  return 'Successful';
}

/**
 * Go back functionality for app
 */
export function goBack() {
  const { length, goBack, push } = history;
  if (length === 1) {
    // No other page in the stack
    push('/login');
  } else {
    // There are pages in the stack
    goBack();
  }
}

export const inDevelopment = () => process.env.NODE_ENV === 'development';
