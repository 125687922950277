import React from 'react';

export const Reporting = (
  <section>
    <h3 className="sectiontopic">Reporting</h3>
    <ul>
      <li>
        Data for the Daily Metrics on the Service Centre dashboard is polled
        from the Amazon Connect historic metrics API.
        <ul>
          <li>This API returns data for a minimum of 5 minutes.</li>
          <li>
            This implies that the maximum delay between actual call events and
            daily metrics data could be 5 minutes.
          </li>
        </ul>
      </li>
      <li>
        Issues due to session timeout.
        <ul>
          <li>
            Daily Metrics data on the Service Centre dashboard shows zero values
            once the user session is timed out.
          </li>
          <li>
            The user needs to log in and refresh the screen, this will allow for
            the metric values to be populated again.
          </li>
        </ul>
      </li>
      <li>
        RFC count on the reports is impacted in case of transferred calls.
        Incorrect values of RFC flag are set for both the call logs.
      </li>
      <li>
        <p>
          Any changes to the metrics definitions on Amazon Connect will have a
          direct impact on Service Centre Dashboard metrics. As of October 2019
          update, the metrics are defined below and reflect the same in Service
          Centre Dashboard.
        </p>
        <p>
          <a
            href="https://docs.aws.amazon.com/connect/latest/adminguide/real-time-metrics-definitions.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Real – Time Metrics Definitions
          </a>
        </p>
        <p>
          <a
            href="https://docs.aws.amazon.com/connect/latest/adminguide/historical-metrics-definitions.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Historical Metrics Definitions
          </a>
        </p>
      </li>
    </ul>
  </section>
);
