import React, { useState, useEffect } from 'react';

import { HashLink as Link } from 'react-router-hash-link';
import { navigateToSection } from 'utils/navigationLogic';
import Seo from 'components/Seo';
import OL, { LI } from 'components/atoms/OrderedList';

import css from './PrivacyPolicy.module.scss';

const PrivacyPolicy: React.FC = () => {
  useEffect(() => {
    navigateToSection();
  }, []);

  const [content] = useState([
    {
      title: 'Who we are?',
      id: 'who-we-are',
      points: [
        <span>
          RocketCX is a service provided by ECS Europe Limited, (Company
          registration number: SC357435) with its registered office at Parkview
          House, 6 Woodside Place, Glasgow, United Kingdom (“ECS” or “we”).
        </span>,
        <span>
          ECS is committed to protecting the privacy and security of your
          personal data. This privacy notice describes how we collect and use
          personal data about you, in accordance with the General Data
          Protection Regulation (“
          <b>GDPR</b>”). We are a “<b>data controller</b>”. This means that we
          are responsible for deciding how we hold and use personal data about
          you when you visit our website, contact us, or buy services from us.
        </span>,
        <span>
          <p>
            If you have any questions about this privacy policy, please contact
            us in the following ways:
          </p>
          <p>
            <u>Email address</u>:{' '}
            <a href="mailto:enquiries@ecs.co.uk">enquiries@ecs.co.uk</a>
          </p>
          <p>
            <u>Telephone number</u>:{' '}
            <a href="tel:+441415723040">+44 (0)141 572 3040</a>
          </p>
          <p>
            <u>Postal address</u>: Parkview House, 6 Woodside Place, Glasgow,
            United Kingdom G3 7QF.
          </p>
        </span>,
      ],
    },
    {
      title: 'Data Protection Principles',
      id: 'data-protection',
      points: [
        <span>
          We will comply with data protection law. This says that the personal
          data we hold about you must be:
          <ul>
            <li>Used lawfully, fairly and in a transparent way.</li>
            <li>
              Collected only for valid purposes that we have clearly explained
              to you and not used in any way that is incompatible with those
              purposes.
            </li>
            <li>
              Relevant to the purposes we have told you about and limited only
              to those purposes.
            </li>
            <li>Accurate and kept up to date.</li>
            <li>
              Kept only as long as necessary for the purposes we have told you
              about.
            </li>
            <li>Kept securely.</li>
          </ul>
        </span>,
      ],
    },
    {
      title: 'The kind of information we hold about you',
      id: 'info-about-you',
      points: [
        <span>
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data).
        </span>,
        <span>
          We may collect, store and use the following categories of personal
          information about you which we have grouped together as follows:
          <ul>
            <li>
              <b>Identity Data</b> includes first name, last name, title.
            </li>
            <li>
              <b>Contact Data</b> includes email address, telephone number and
              company details.
            </li>
            <li>
              <b>Technical Data</b> includes access keys, secrets, session
              tokens and session timestamps.
            </li>
            <li>
              <b>Transaction Data</b> includes details about payments to and
              from you and other details services you have purchased from us.
            </li>
            <li>
              <b>Usage Data</b> includes information about how you use our
              website, products and services.
            </li>
          </ul>
        </span>,
        <span>
          We do not collect any <b>Special Categories of Personal Data</b> about
          you (this includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health, and
          genetic and biometric data).
        </span>,
      ],
    },
    {
      title: 'How is your personal data collected?',
      id: 'data-collection',
      points: [
        <span>
          We use different methods to collect data from you and about you
          including through:
          <ul>
            <li>
              <b>Direct Interactions.</b>You may give us your Identity and
              Contact Data in circumstances including when you:
              <ul>
                <li>make an order for our services;</li>
                <li>give us feedback, or contact us; or</li>
                <li>use our support services.</li>
              </ul>
            </li>
            <li>
              <b>Third parties or publicly available sources.</b>We will receive
              personal data about you from various third parties and public
              sources as set out below:
              <ul>
                <li>Technical Data from Amazon Web Services;</li>
                <li>
                  Contact, Identity and Transaction Data from providers of
                  technical and payment services such as Amazon Web Services;
                </li>
                <li>
                  Identity and Contact Data from publicly available sources
                  (such as Companies House).
                </li>
              </ul>
            </li>
          </ul>
        </span>,
      ],
    },
    {
      title: 'How we will use information about you',
      id: 'info-usage',
      points: [
        <span>
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:
          <ul>
            <li>
              Where we need to perform the contract we are about to enter into
              or have entered into with you.
            </li>
            <li>
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests.
            </li>
            <li>Where we need to comply with a legal obligation.</li>
          </ul>
        </span>,
        <span>
          Generally, we do not rely on consent as a legal basis for processing
          your personal data.
        </span>,
      ],
    },
    {
      title: 'Cookies',
      id: 'cookies',
      points: [
        <span>
          Our services use cookies. A cookie is a small file of letters and
          numbers that is sent by a web server to a web browser and then stored
          by the browser, when you access certain websites.
        </span>,
        <span>
          Cookies do not typically contain any information that personally
          identifies a user, but personal data that we store about you may be
          linked to the information stored in and obtained from cookies.
        </span>,
        <span>
          Cookies may be either “persistent” cookies or “session” cookies: a
          persistent cookie will be stored by a web browser and will remain
          valid until its set expiry date, unless deleted by the user before the
          expiry date; a session cookie, on the other hand, will expire at the
          end of the user session. We do not use persistent cookies in
          delivering our services.
        </span>,
        <span>
          <p>
            The names of the cookies that we use, and the purposes for which
            they are used, are set out below.
          </p>
          <p>
            RocketCX uses “cookies” to make requests to the S3/Amazon Connect
            APIs for accessing call recordings and Amazon Connect data. The
            cookies are called aws_accessKeyId, aws_expiration,
            aws_secretAccessKey, aws_sessionToken, aws_supervisor.
          </p>
        </span>,
      ],
    },
    {
      title: 'Change of purpose',
      id: 'change-of-purpose',
      points: [
        <span>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If we need to use your personal data for an unrelated
          purpose, we will notify you and we will explain the legal basis which
          allows us to do so.
        </span>,
        <span>
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
        </span>,
      ],
    },
    {
      title: 'Data sharing',
      id: 'data-sharing',
      points: [
        <span>
          We may have to share your data with third parties, including
          third-party service providers. We require third parties to respect the
          security of your data and to treat it in accordance with the law. We
          do not envisage transferring your personal data outside the EU,
          however, if we do, you can expect a similar degree of protection in
          respect of your personal data.
        </span>,
        <span>
          <p>
            <u>
              <em>Why might you share my personal data with third parties?</em>
            </u>
          </p>
          <p>
            We share information with Amazon Web Services to allow the
            processing of payments for the services you use.
          </p>
        </span>,
        <span>
          <p>
            <u>
              <em>
                Which third party service providers process my personal data?
              </em>
            </u>
          </p>
          <p>Which third party service providers process my personal data?</p>
        </span>,
      ],
    },
    {
      title: 'Retaining Personal Data',
      id: 'retaining-data',
      points: [
        <span>
          This section 9 sets out our data retention policies and procedure,
          which are designed to help ensure that we comply with our legal
          obligations in relation to the retention and deletion of personal
          data.
        </span>,
        <span>
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes we collected it for, including for
          the purposes of satisfying any legal, regulatory, tax, accounting or
          reporting requirements. We may retain your personal data for a longer
          period in the event of a compliant or if we reasonably believe there
          is a prospect of litigation in respect to our relationship with you.
        </span>,
        <span>
          To determine the appropriate retention period for personal data, we
          consider the amount, nature and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal, regulatory, tax, accounting or other requirements.
        </span>,
      ],
    },
    {
      title: 'Data Security',
      id: 'data-security',
      points: [
        <span>
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality.
        </span>,
        <span>
          We have put in place procedures to deal with any suspected personal
          data breach and will notify you and any applicable regulator of a
          breach where we are legally required to do so
        </span>,
      ],
    },
    {
      title: 'Rights of access, correction, erasure and restriction',
      id: 'rights-of-access',
      points: [
        <span>
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes
          during your working relationship with us.
        </span>,
        <span>
          Under certain circumstances, by law you have the right to:
          <ul>
            <li>
              <b>Right to be informed</b> about the collection and use of your
              personal data.
            </li>
            <li>
              <b>Request Access</b> to your personal data (commonly known as a
              “data subject access request”). This enables you to receive a copy
              of the personal data we hold about you and to check that we are
              lawfully processing it.
            </li>
            <li>
              <b>Request correction</b> of the personal data that we hold about
              you. This enables you to have any incomplete or inaccurate
              information we hold about you corrected.
            </li>
            <li>
              <b>Request erasure</b> of your personal data. This enables you to
              ask us to delete or remove personal data where there is no good
              reason for us continuing to process it. You also have the right to
              ask us to delete or remove your personal data where you have
              exercised your right to object to processing (see below).
            </li>
            <li>
              <b>Object to processing</b> of your personal data where we are
              relying on a legitimate interest (or those of a third party) and
              there is something about your particular situation which makes you
              want to object to processing on this ground. You also have the
              right to object where we are processing your personal data for
              direct marketing purposes.
            </li>
            <li>
              <b>
                Request the restriction of processing of your personal data.
              </b>{' '}
              This enables you to ask us to suspend the processing of personal
              data about you, for example if you want us to establish its
              accuracy or the reason for processing it.
            </li>
            <li>
              <b>
                Request the transfer of your personal data to another party.
              </b>
            </li>
          </ul>
        </span>,
        <span>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we could refuse to comply with your request in these
          circumstances.
        </span>,
        <span>
          If you want to review, verify, correct or request erasure of your
          personal data, object to the processing of your personal data, or
          request that we transfer a copy of your personal data to another
          party, please contact us.
        </span>,
      ],
    },
    {
      title: 'Changes to this privacy notice',
      id: 'changes-to-notice',
      points: [
        <span>
          We reserve the right to update this privacy notice at any time, and we
          will provide you with a new privacy notice when we make any
          substantial updates. We may also notify you in other ways from time to
          time about the processing of your personal information.
        </span>,
        <span>
          If you have any questions about this privacy notice or how we handle
          your personal information, please contact us on the contact details in
          section 1.3. You have the right to make a complaint at any time to the
          Information Commissioner's Office (ICO), the UK supervisory authority
          for data protection issues. We would, however, appreciate the chance
          to deal with your concerns before you approach the ICO so please
          contact us in the first instance.
        </span>,
      ],
    },
  ]);
  return (
    <div className="policycontainer">
      <Seo title="Privacy and Cookie Policy" />
      <h1 className="docspagetitle">RocketCX Privacy and Cookie Policy</h1>
      <ol>
        {content.map(section => {
          return (
            <li key={section.title}>
              <Link to={`#${section.id}`}>{section.title}</Link>
            </li>
          );
        })}
      </ol>
      {content.map((section, index) => {
        return (
          <section key={index} className={css.section} id={section.id}>
            <h2 className="subheading">
              {index + 1}. {section.title}
            </h2>
            <OL sectionIndex={index + 1}>
              {section.points.map((point, i) => (
                <LI key={i}>{point}</LI>
              ))}
            </OL>
          </section>
        );
      })}
    </div>
  );
};

export default PrivacyPolicy;
