import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from './EmptyList.module.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * Empty state when no instance (or user) is found when records for them are fetched
 */
const EmptyList: React.FC<{
  item: 'Instances' | 'Users' | 'User';
  suggestToCreateNew?: boolean;
}> = ({ item, suggestToCreateNew = false }) => {
  const iconMappingTable: { [k: string]: IconProp } = {
    Instances: 'desktop',
    Users: 'users',
    User: 'user',
  };

  let content = 'something';
  switch (item) {
    case 'Instances':
      content = 'an instance';
      break;
    case 'Users':
      content = 'a user';
      break;
  }

  return (
    <section className={css.emptylistcontainer}>
      <div className={css.panel}>
        <div className={css.emptyicon}>
          <FontAwesomeIcon icon={iconMappingTable[item]} />
        </div>
        <div className={css.line1}>No {item.toLowerCase()} found</div>
        {suggestToCreateNew && (
          <div className={css.line2}>Try creating {content} first</div>
        )}
      </div>
    </section>
  );
};

export default EmptyList;
