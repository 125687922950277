import React from 'react';

const URContent = (
  <div>
    <h1 className="docsectiontitle">User Roles</h1>
    <section className="sectionmaterial">
      <p>There are 3 roles for RocketCX Enterprise Connector.</p>
      <table>
        <tr>
          <th>Role Label</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>Plug-In Administrator</td>
          <td>
            Users in this role group will have access to all functions on the
            Admin Console and Administration menu. This is accessed through the
            ServiceNow back end.
          </td>
        </tr>
        <tr>
          <td>Contact Centre Supervisor</td>
          <td>
            Users in this role group have access to the queue and agent
            reporting functions (for all agents), and the Service Centre
            Dashboard.
          </td>
        </tr>
        <tr>
          <td>Contact Centre Agent</td>
          <td>
            Users in this role group will have access to the RocketCX Enterprise
            Connector’s CCP (Contact Control Panel), and will also be able to
            access the agent reporting function for their own user ID only.
          </td>
        </tr>
      </table>
      <p className="sectionnote">
        Note: RocketCX Enterprise Connector is a CTI application which runs on
        ServiceNow with OpenFrame plugin dependency. ServiceNow Administrator
        must assign sn_openframe_user role to any ServiceNow user with Contact
        Centre Agent role. Users with Contact Centre Agent role must be assigned
        roles or membership of ServiceNow groups which provide access to the
        ServiceNow modules (Incident, Customer Service and HR Core) supported by
        RocketCX Enterprise Connector.
      </p>
    </section>
  </div>
);

export const UserRolesContent = {
  content: URContent,
};
