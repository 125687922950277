import React from 'react';
import image31a from '../images/image31a.png';
import image32a from '../images/image32a.png';
import image32b from '../images/image32b.png';
import image33a from '../images/image33a.png';
import image34a from '../images/image34a.png';
import image35a from '../images/image35a.png';
import image35b from '../images/image35b.png';
import image36a from '../images/image36a.png';
import image37a from '../images/image37a.png';
import image37b from '../images/image37b.png';
import image38a from '../images/image38a.png';
import CaptionedImage from '../CaptionedImage';
import Link from 'components/atoms/Link';

const AdminContent = (
  <div>
    <h1 className="docsectiontitle">Administration</h1>
    <section className="sectionmaterial">
      <ol>
        <li>
          <Link to="/administration-guide/administration/user-profile">
            User Profile
          </Link>
        </li>
        <li>
          <Link to="/administration-guide/administration/change-user-password">
            Change User Password
          </Link>
        </li>
        <li>
          <Link to="/administration-guide/administration/view-instances">
            View Instances
          </Link>
        </li>
        <li>
          <Link to="/administration-guide/administration/instance-details">
            View/Edit Instance Details
          </Link>
        </li>
        <li>
          <Link to="/administration-guide/administration/instance-usage">
            View Instance Usage
          </Link>
        </li>
        <li>
          <Link to="/administration-guide/administration/enable-disable-instance">
            Enable/Disable Instance
          </Link>
        </li>
        <li>
          <Link to="/administration-guide/administration/change-instance-password">
            Change Instance Password
          </Link>
        </li>
        <li>
          <Link to="/administration-guide/administration/delete-instance">
            Delete Instance
          </Link>
        </li>
      </ol>
    </section>
  </div>
);

const UserProfileContent = (
  <div>
    <h1 className="docsectiontitle">User Profile</h1>
    <section className="sectionmaterial">
      <p>
        You can change your user details via the User Profile screen. As shown
        below, this includes your personal details, company information and
        password:
      </p>
      <CaptionedImage src={image31a} label="User Profile" />
    </section>
  </div>
);

const ChangeUserPasswordContent = (
  <div>
    <h1 className="docsectiontitle">Change User Password</h1>
    <section className="sectionmaterial">
      <p>
        To change a User’s password, please click “Change Password” button from
        the menu on the top right corner as shown in the image below. You’ll
        then be redirected to Change Password page. Please enter your old and
        new password and click “Save Changes” button. Click Cancel button to
        return to previous screen.
      </p>
      <CaptionedImage src={image32a} label="User Profile" />
      <CaptionedImage src={image32b} label="Change Password" />
    </section>
  </div>
);

const ViewInstancesContent = (
  <div>
    <h1 className="docsectiontitle">View Instances</h1>
    <section className="sectionmaterial">
      <p>
        Please click “Instances” link on the top navigation bar to view the list
        of all Instances configured for your account.
      </p>
      <CaptionedImage src={image33a} label="View all Instances" />
    </section>
  </div>
);

const ViewEditInstanceContent = (
  <div>
    <h1 className="docsectiontitle">View/Edit Instance details</h1>
    <section className="sectionmaterial">
      <p>
        To view or edit an Instance, please select the respective Instance from
        the list, the instance tile is expanded as shown below to let you edit
        Instance details.
      </p>
      <CaptionedImage src={image34a} label="Instance Tile" />
    </section>
  </div>
);

const InstanceUsageContent = (
  <div>
    <h1 className="docsectiontitle">View Instance Usage</h1>
    <section className="sectionmaterial">
      <p>
        Once an Instance has been configured successfully with ServiceNow, you
        can view it’s usage information by clicking “Usage” tab in the Instance
        tile as shown below. On clicking on the Usage tab you will see two
        fields for date selection, selecting a time period will show you the
        total number of units and estimated spends for that period. To see
        Actual Spendings please check your consolidated AWS monthly bill.
      </p>
      <CaptionedImage src={image35a} label="Instance Usage Cumulative" />
      <p>
        You can also click the “Show Details” button to see the usage details by
        the hour, as shown in the image below.
      </p>
      <CaptionedImage src={image35b} label="Instance Usage Details" />
      <p>
        In the example shown above, the usage data includes the No of calls and
        Fetch time (when data was received from the Instance).
      </p>
    </section>
  </div>
);

const EnableDisableInstancesContent = (
  <div>
    <h1 className="docsectiontitle">Enable/Disable Instances</h1>
    <section className="sectionmaterial">
      <p>
        You can enable or disable an Instance by checking or un-checking the
        Enable Field, under Config tab, followed by clicking Save Changes (as
        shown in the image below)
      </p>
      <p>
        Please note in case an Instance is disabled the RocketCX Enterprise
        Connector would start showing warnings within the first 2 days of
        disabling the Instance, and would eventually stop working after 30 days.
      </p>
      <CaptionedImage src={image36a} label="View Instance Page" />
    </section>
  </div>
);

const ChangePasswordContent = (
  <div>
    <h1 className="docsectiontitle">Change Instance Password</h1>
    <section className="sectionmaterial">
      <p>
        To change an Instance’s password, please click “Change Password” tab on
        the Instance tile. Please enter your new password and click Change
        Password button.
      </p>
      <CaptionedImage src={image37a} label="Instance Page" />
      <CaptionedImage src={image37b} label="Change Instance Password" />
      <p>
        Please note after you have successfully changed the password, it will
        need to be changed in RocketCX Enterprise Connector’s Admin Console in
        ServiceNow as described in{' '}
        <a
          href="https://rocketcx.s3.amazonaws.com/EnterpriseConnectorforServiceNow/v1/Documents/Public/RocketCX+Installation+and+Configuration+Guide+1.0.0.docx"
          target="_blank"
          rel="noopener noreferrer"
        >
          RocketCX Installation and Configuration Guide 1.0.0
        </a>{' '}
        – Section 6.4.7.2.3 RocketCX Configuration.
      </p>
    </section>
  </div>
);

const DeleteInstanceContent = (
  <div>
    <h1 className="docsectiontitle">Delete Instance</h1>
    <section className="sectionmaterial">
      <p>
        You may choose to delete the Instance by clicking “Delete Instance”
        button in Instance tile as shown in the image below.
      </p>
      <CaptionedImage src={image38a} label="Instance Tile - Delete Instance" />
      <p>
        Please note the “Delete Instance” button may not be visible in case
        there is usage data against the respective Instance.
      </p>
    </section>
  </div>
);

export const AdministrationContent = {
  content: AdminContent,
  userProfile: UserProfileContent,
  changeUserPassword: ChangeUserPasswordContent,
  viewInstances: ViewInstancesContent,
  viewEditInstance: ViewEditInstanceContent,
  instanceUsage: InstanceUsageContent,
  enableDisableInstances: EnableDisableInstancesContent,
  changeInstancePassword: ChangePasswordContent,
  deleteInstance: DeleteInstanceContent,
};
