import React from 'react';
import CaptionedImage from 'pages/AdminGuide/CaptionedImage';
import image21a from '../images/image21a.png';
import image21b from '../images/image21b.png';
import image21c from '../images/image21c.png';
import image22a from '../images/image22a.png';
import image22b from '../images/image22b.png';
import image23a from '../images/image23a.png';
import image23b from '../images/image23b.png';
import image23c from '../images/image23c.png';
import image23d from '../images/image23d.png';
import Link from 'components/atoms/Link';

const SetupContent = (
  <div>
    <h1 className="docsectiontitle">Setup</h1>
    <section className="sectionmaterial">
      <ol>
        <li>
          <Link to="/administration-guide/setup/subscribe">Subscribe</Link>
        </li>
        <li>
          <Link to="/administration-guide/setup/register">Register</Link>
        </li>
        <li>
          <Link to="/administration-guide/setup/create-instance">
            Create New Instance
          </Link>
        </li>
        <li>
          <Link to="/administration-guide/setup/configure-on-snow">
            Configure the Instance on ServiceNow
          </Link>
        </li>
      </ol>
    </section>
  </div>
);

const SubscribeContent = (
  <div>
    <h1 className="docsectiontitle">Subscribe</h1>
    <section className="sectionmaterial">
      <p>
        If you have not already subscribed to RocketCX Enterprise Connector,
        please log into the AWS console at{' '}
        <a
          href="https://console.aws.amazon.com/console/home"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://console.aws.amazon.com/console/home
        </a>{' '}
        with an account which has administrative permissions on Amazon Connect,
        AWS Marketplace and related services such as S3, IAM, Lambda,
        CloudFormation.
      </p>
      <p>
        Next, go to AWS marketplace{' '}
        <a
          href="https://aws.amazon.com/marketplace"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://aws.amazon.com/marketplace
        </a>
        , search for “RocketCX Enterprise Connector”, and select the result.
      </p>
      <CaptionedImage
        src={image21a}
        label="The RocketCX Home Page on the marketplace"
      />
      <p>Click on the “Continue to Subscribe” button.</p>
      <CaptionedImage src={image21b} label="Subscribing to RocketCX" />
      <p>Next, click on the Subscribe button.</p>
      <CaptionedImage src={image21c} label="Confirmation" />
      <p>
        When you click on the Set up Your Account button, you will be redirected
        to the RocketCX, to configure your account.
      </p>
    </section>
  </div>
);

const RegisterContent = (
  <div>
    <h1 className="docsectiontitle">Register</h1>
    <section className="sectionmaterial">
      <p>
        Provide your details on the registration form, and click on Sign Up:
      </p>
      <CaptionedImage src={image22a} label="Register as a new User" />
      <p>After successful Sign Up, you will be redirected to the Login page:</p>
      <CaptionedImage src={image22b} label="Logging in" />
      <p>Login using the details provided during registration.</p>
    </section>
  </div>
);

const CreateInstanceContent = (
  <div>
    <h1 className="docsectiontitle">Create New Instance</h1>
    <section className="sectionmaterial">
      <p>
        An instance is a reference of ServiceNow running in the cloud. Depending
        on the size and structure or your organization, you might have multiple
        instances, each representing a different division or department.
        RocketCX allows you to add an instance for each copy of ServiceNow.
      </p>
      <CaptionedImage src={image23a} label="Instances Page" />
      <CaptionedImage src={image23b} label="Creating New Instance" />
      <p>
        Enter the following information:
        <ul>
          <li>
            Name: An arbitrary name for the Instance (e.g. "Production
            Instance")
          </li>
          <li>
            Instance Endpoint URL: The ServiceNow instance URL (e.g.
            https://prod-instance.service.service-now.com).
          </li>
          <li>Instance Type: ServiceNow</li>
          <li>
            Username: A username for the instance. This user will be used to
            maintain a connection between ServiceNow and RocketCX. The username
            must be unique for all users of RocketCX, so you may wish to include
            your organization name (e.g. “productioninstance”).
          </li>
          <li>Password: A password for the instance user</li>
          <li>Confirm password: Re-enter the password entered previously</li>
        </ul>
        Click on the "Create Instance" button to create a new Instance.
        <CaptionedImage src={image23c} label="New Instance created" />
        <CaptionedImage src={image23d} label="New Instance created" />
      </p>
    </section>
  </div>
);

const ConfigureOnServiceNowContent = (
  <div>
    <h1 className="docsectiontitle">Configure the Instance on ServiceNow</h1>
    <section className="sectionmaterial">
      When the Instance is created, it is assigned a randomly generated ID, as
      shown in the previous screenshot. You can find the reference of this ID in
      the{' '}
      <a
        href="https://rocketcx.s3.amazonaws.com/EnterpriseConnectorforServiceNow/v1/Documents/Public/RocketCX+Installation+and+Configuration+Guide+1.0.0.docx"
        target="_blank"
        rel="noopener noreferrer"
      >
        RocketCX Installation and Configuration Guide 1.0.0
      </a>{' '}
      – Section 6.4.7.2.3 RocketCX Configuration. Please note that you can click
      on the icon next to the ID to copy it to the clipboard.
    </section>
  </div>
);

export const SetupSectionContent = {
  content: SetupContent,
  subscribe: SubscribeContent,
  register: RegisterContent,
  createInstance: CreateInstanceContent,
  configure: ConfigureOnServiceNowContent,
};
