import React, { useState, useEffect } from 'react';

import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from './NavBarDropdown.module.scss';

interface NavBarDropdownProps {
  menuLabel: string;
  labelID: string;
  profileMenu?: boolean;
}

const NavBarDropdown: React.FC<NavBarDropdownProps> = props => {
  const [show, setVisibility] = useState(false);

  // Close dropdown if user clicks outside of it
  useEffect(() => {
    let outsideClickListener = (e: any) => {
      if (!e.target.matches(`#${props.labelID}`)) {
        setVisibility(false);
      }
    };
    window.addEventListener('click', outsideClickListener);
    return () => {
      window.removeEventListener('click', outsideClickListener);
    };
  }, [props.labelID]);

  const labelClass = cn(css.navlink, { [css.usertext]: props.profileMenu });

  return (
    <div className={css.dropdown}>
      <div
        className={labelClass}
        onClick={show ? () => setVisibility(false) : () => setVisibility(true)}
        id={props.labelID}
      >
        {props.menuLabel}
        <FontAwesomeIcon
          icon={!show ? 'chevron-down' : 'chevron-up'}
          className={css.dropicon}
        />
      </div>
      <div
        className={
          show
            ? `${css.dropdown_content} ${css.show}`
            : `${css.dropdown_content}`
        }
        id="myDropdown"
        onClick={() => setVisibility(false)}
      >
        {props.children}
      </div>
    </div>
  );
};

export default NavBarDropdown;
