import React from 'react';
import { FieldError as HookFormFieldError } from 'react-hook-form';

import css from './field-error.module.scss';

interface FieldError {
  err: HookFormFieldError | undefined | null;
}

/**
 * Error message that appears underneath of an input field for any failed validation or when an error occurs.
 */
const FieldError: React.FC<FieldError> = ({ err }) => {
  return (
    <div className={css.error}>
      {err?.message?.split('\n').map((line, i) => (
        <div key={i}>{line}</div>
      ))}
    </div>
  );
};

export default FieldError;
