import { ReleaseSummary } from './sections/ReleaseSummary';
import { ReleaseFeatures } from './sections/ReleaseFeatures';
import { Enhancements } from './sections/Enhancements';
import { IssuesAndProblems } from './sections/IssuesAndProblems';

export const sections = [
  {
    id: 'release-summary',
    content: ReleaseSummary,
    title: 'Release Summary',
  },
  {
    id: 'release-features',
    content: ReleaseFeatures,
    title: 'Release Features',
  },
  {
    id: 'enhancements',
    content: Enhancements,
    title: 'Enhancements',
  },
  {
    id: 'issues-and-limitations',
    content: IssuesAndProblems,
    title: 'Known Issues and Limitations',
  },
];
