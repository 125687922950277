import React, { forwardRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from 'components/atoms/Tooltip';

import css from './Label.module.scss';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  text: string;
  tip?: string;
}

/**
 * Label that wraps around an input field (and its error message)
 */
const Label = forwardRef<HTMLLabelElement, LabelProps>(
  ({ text, tip, children, className, ...props }, ref) => {
    return (
      <label className={css.field} {...props} ref={ref}>
        <div className={css.label}>
          {text}
          {tip && (
            <Tooltip text={tip}>
              <FontAwesomeIcon icon="info-circle" className={css.tooltipicon} />
            </Tooltip>
          )}
        </div>
        <div id="labelChildren">{children}</div>
      </label>
    );
  }
);

export default Label;
