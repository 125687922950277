import React from 'react';

import { useRouteMatch } from 'react-router-dom';
import Link from 'components/atoms/Link';
import { history } from 'App';

import css from './DocsBar.module.scss';

const DocsBar: React.FC = () => {
  const links = [
    {
      id: 'service',
      title: 'Product Overview',
      url: 'about',
    },
    {
      id: 'terms',
      title: 'EULA',
      url: 'eula',
    },
    {
      id: 'privacy',
      title: 'Privacy and Cookie Policy',
      url: 'privacy',
    },
    {
      id: 'release',
      title: 'Release Notes',
      url: 'release-notes',
    },
  ];

  let { url } = useRouteMatch();

  const { pathname } = history.location;

  return (
    <div className={css.tabsbarcontainer}>
      {links.map(link => {
        return (
          <Link
            to={`${url}/${link.url}`}
            key={link.id}
            id={link.id}
            className={`${css.link} ${
              pathname ? (pathname.includes(link.url) ? css.chosen : '') : ''
            }`}
          >
            {link.title}
          </Link>
        );
      })}
    </div>
  );
};

export default DocsBar;
