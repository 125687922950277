import React from 'react';

import { LabelPlaceholder } from 'components/Placeholders';

import css from './AdminEditUserProfile.module.scss';

const UserProfilePlaceholder: React.FC = () => {
  return (
    <section className="userform">
      <div className={css.namerow}>
        <LabelPlaceholder />
        <LabelPlaceholder />
      </div>
      <LabelPlaceholder />
      <LabelPlaceholder />
      <LabelPlaceholder />
      <LabelPlaceholder />
      <LabelPlaceholder />
      <LabelPlaceholder />
    </section>
  );
};

export default UserProfilePlaceholder;
