import React from 'react';

import css from './CaptionedImage.module.scss';

const CaptionedImage: React.FC<{ src: string | undefined; label: string }> = ({
  src,
  label,
}) => {
  return (
    <a
      className={css.captionedimage}
      href={src}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={src} alt="" style={{ marginBottom: 0 }} />
      <em className={css.imagelabel}>{label}</em>
    </a>
  );
};

export default CaptionedImage;
