import React from 'react';

export const LegacyDashboards = (
  <section>
    <h3 className="sectiontopic">ServiceNow Legacy Dashboards</h3>
    <ol>
      <li>
        <strong>Supervisor Dashboard</strong>
        <p>
          Supervisor Dashboard is a responsive dashboard which displays
          different legacy metrics and trend reports which Supervisor can use to
          determine and measure the Contact Centre and Agent performance.
        </p>
      </li>
      <li>
        <strong>My Dashboard</strong>
        <p>
          My Dashboard is accessible to Agents where they can see the summary of
          calls received over a specific time period based on an interactive
          filter. It displays the details in terms of different legacy reports
          and metrics.
        </p>
      </li>
    </ol>
  </section>
);
