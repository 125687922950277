import React from 'react';

export const ReleaseSummary = (
  <section id="release-summary">
    <h2 className="sectionsubtitle">
      1. RocketCX Enterprise Connector Release Summary
    </h2>
    <p>
      This release includes modules described below as well as improvements
      identified during testing.
    </p>
  </section>
);
