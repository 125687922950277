import React from 'react';
import image6a from '../images/6a.png';
import image61a from '../images/61a.png';
import image621a from '../images/621a.png';
import image622a from '../images/622a.png';
import image623a from '../images/623a.png';
import image624a from '../images/624a.png';
import image625a from '../images/625a.png';
import { Link } from 'react-router-dom';
import Screenshot from '../Screenshot';

const CCPContent = (
  <div>
    <h1 className="docsectiontitle">Contact Control Panel Components</h1>
    <section className="sectionmaterial">
      <p>RocketCX CCP displays four components :</p>
      <ol>
        <li>Name of Agent logged in</li>
        <li>Status</li>
        <li>Tabs</li>
        <li>Tabs content</li>
      </ol>
      <p>
        RocketCX CCP window can be minimized/maximized using the buttons
        displayed in the top corner of CCP.
      </p>
      <Screenshot src={image6a} />
    </section>
  </div>
);

const StatusContent = (
  <div>
    <h1 className="docsectiontitle">Status</h1>
    <section className="sectionmaterial">
      <p>
        The agent can change the availability status using the status dropdown.
        The possible status values are:
      </p>
      <ol>
        <li>Available</li>
        <li>Busy</li>
        <li>Do not disturb</li>
        <li>Offline</li>
      </ol>
      <Screenshot src={image61a} />
      <p className="sectionnote">
        Note: You can also logout from Amazon Connect using the logout link
        given on the status dropdown. Calls will only be received when the
        status is set to Available.
      </p>
    </section>
  </div>
);

const TabsContent = (
  <div>
    <h1 className="docsectiontitle">Tabs</h1>
    <section className="sectionmaterial">
      There are 5 tabs available to the user :
      <ol>
        <li>
          <Link to="/user-guide/ccp-components/tabs/fav-contacts">
            Favorite Contacts
          </Link>
        </li>
        <li>
          <Link to="/user-guide/ccp-components/tabs/contacts">Contacts</Link>
        </li>
        <li>
          <Link to="/user-guide/ccp-components/tabs/dial-pad">Dial Pad</Link>
        </li>
        <li>
          <Link to="/user-guide/ccp-components/tabs/call-history">
            Call History
          </Link>
        </li>
        <li>
          <Link to="/user-guide/ccp-components/tabs/active-call">
            Active Call
          </Link>
        </li>
      </ol>
    </section>
  </div>
);

const FavContactsContent = (
  <div>
    <h1 className="docsectiontitle">Favorite Contacts</h1>
    <section className="sectionmaterial">
      <p>
        The agent can click on the Star Icon, to add the contact to the
        Favorites tab.
      </p>
      <Screenshot src={image621a} />
    </section>
  </div>
);

const ContactsContent = (
  <div>
    <h1 className="docsectiontitle">Contacts</h1>
    <section className="sectionmaterial">
      <p>
        The Contacts tab displays a list of contacts assembled from the contact
        tables defined in the Admin Console like Users, Contacts or Consumers.
      </p>
      <p>
        Outgoing calls can be placed by clicking on a contact. If two numbers
        are associated with a contact, CCP will then display a popup to choose a
        number (as shown below):
      </p>
      <Screenshot src={image622a} />
    </section>
  </div>
);

const DialPadContent = (
  <div>
    <h1 className="docsectiontitle">Dial Pad</h1>
    <section className="sectionmaterial">
      <p>
        The Dial Pad can be used to place an outgoing call to a number which is
        not listed in ServiceNow:
      </p>
      <Screenshot src={image623a} />
    </section>
  </div>
);

const CallHistoryContent = (
  <div>
    <h1 className="docsectiontitle">Call History</h1>
    <section className="sectionmaterial">
      <p>
        Call History shows the last 50 entries for calls placed or received
        using the CCP. Each call entry contains date, time, caller and incoming
        or outgoing details:
      </p>
      <Screenshot src={image624a} />
    </section>
  </div>
);

const ActiveCallContent = (
  <div>
    <h1 className="docsectiontitle">Active Call</h1>
    <section className="sectionmaterial">
      <p>
        The Active Call tab is displayed automatically when an agent receives an
        incoming call or makes an outgoing call on CCP. The Agent cannot select
        this tab manually. As shown below, the tab displays caller details like
        name and any related records (in the Record dropdown). Note that the
        agent can also add call log details on wrap-up.
      </p>
      <Screenshot src={image625a} />
    </section>
  </div>
);

export const CCPComponentsContent = {
  content: CCPContent,
  status: StatusContent,
  tabs: {
    content: TabsContent,
    favContacts: FavContactsContent,
    contacts: ContactsContent,
    dialpad: DialPadContent,
    callhistory: CallHistoryContent,
    activecall: ActiveCallContent,
  },
};
