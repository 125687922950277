import React, { useState, useContext, useCallback, useRef } from 'react';

import Modal from 'components/Modal';
import Usage from 'schemas/InstanceUsage';
import Label from 'components/atoms/Label';
import Instance from 'schemas/Instance';
import { UserContext } from 'App';
import { UserRole } from 'schemas/User';
import UsageRecordPlaceholder from './UsageRecordPlaceholder';
import Table, {
  Header,
  Body,
  Row,
  Cell,
  Footer,
  TablePagination,
  Heading,
} from 'components/atoms/Table/Table';
import Button from 'components/atoms/Button';
import InstanceApi from 'api/InstanceApi';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import debounce from 'utils/debounce';
import { goBack } from 'utils/helpers';
import moment from 'moment';
import DatePicker from './DatePicker';

import css from './Usage.module.scss';
import usePreviousValue from 'utils/hooks/usePreviousValue';

const TimeFormat: React.FC<{ date: string | null }> = ({ date }) => {
  const dateString = date ? moment(new Date(date)).format('DD-MM-YYYY') : '--';
  const timeString = date ? moment(new Date(date)).format('HH:mm:ss') : '--';

  return (
    <div>
      {dateString} {timeString}
    </div>
  );
};

const InstanceUsage: React.FC<{ instance: Instance }> = ({ instance }) => {
  const instanceId = instance.id;
  const [selectedFilter, selectFilter] = useState<string | null>(null);
  const [validPageInput, setValidPageInput] = useState<boolean>(true);

  const QuickFilter: React.FC<{ code: string; updateItems?: Items[] }> = ({
    children,
    code,
    updateItems = ['PageData', 'UsageCount'],
  }) => {
    return (
      <div
        className={`${css.quickfilteraction} ${
          selectedFilter && selectedFilter === code
            ? css.quickfilterselected
            : ''
        }`}
        onClick={() =>
          selectedFilter !== code
            ? twitchDataRange(code, updateItems)
            : clearFilters()
        }
      >
        {children}
      </div>
    );
  };

  const QuickFilters: React.FC<{
    center?: boolean;
    updateItems?: Items[];
  }> = ({ updateItems = ['PageData', 'UsageCount'], ...props }) => {
    return (
      <div
        className={`${css.quickfilters} ${props.center ? css.centered : null}`}
      >
        Quick filters:
        <QuickFilter updateItems={updateItems} code="a">
          Today
        </QuickFilter>
        <QuickFilter updateItems={updateItems} code="b">
          This week
        </QuickFilter>
        <QuickFilter updateItems={updateItems} code="c">
          Last week
        </QuickFilter>
        <QuickFilter updateItems={updateItems} code="d">
          This month
        </QuickFilter>
        <QuickFilter updateItems={updateItems} code="e">
          Last month
        </QuickFilter>
        <QuickFilter updateItems={updateItems} code="f">
          6 months
        </QuickFilter>
        <QuickFilter updateItems={updateItems} code="g">
          This year
        </QuickFilter>
        <QuickFilter updateItems={updateItems} code="h">
          All
        </QuickFilter>
      </div>
    );
  };

  const [totalPages, setTotalPages] = useState(0);
  const [startFetchTime, setStartFetchTime] = useState<Date | null>(null);
  const [endFetchTime, setEndFetchTime] = useState<Date | null>(null);

  const [count, setCount] = useState<number | null>(null);
  const [pricing, setPricing] = useState<number | null>(null);
  const { role } = useContext(UserContext);

  const [instanceUsageData, setInstanceUsageData] = useState<Usage[]>([]);

  // TODO Remove `useCallback`, fix types in arguments
  const usageCountPromise = useCallback(
    async ({ endFetchTime, startFetchTime }) => {
      const instances = await InstanceApi.usageCount({
        instanceId,
        startFetchTime: moment(startFetchTime).format('YYYY-MM-DD HH:mm:ss'),
        endFetchTime: moment(endFetchTime).format('YYYY-MM-DD HH:mm:ss'),
      });
      return instances;
    },
    [instanceId]
  );
  const { run: sendUsageCountRequest } = useAsync(usageCountPromise, {
    onCurrentChange({ current, data, err }) {
      switch (current) {
        case AsyncState.Success:
          const result = data!;
          setCount(result.count);
          setPricing(result.pricing);
          break;
      }
    },
  });

  // TODO Remove `useCallback`, fix types in arguments
  const instanceUsageListPromise = async ({
    pageNo,
    endFetchTime,
    startFetchTime,
  }: {
    pageNo: number;
    endFetchTime: any;
    startFetchTime: any;
  }) => {
    const instances = await InstanceApi.usageList({
      instanceId,
      pageNo,
      startFetchTime: moment(startFetchTime).format('YYYY-MM-DD HH:mm:ss'),
      endFetchTime: moment(endFetchTime).format('YYYY-MM-DD HH:mm:ss'),
      pageSize: 8,
    });
    return instances;
  };

  const { run: sendRequest, current, data } = useAsync(
    instanceUsageListPromise,
    {
      mode: 'last',
      onCurrentChange({ current, data, err }) {
        switch (current) {
          case AsyncState.Success:
            const result = data!;
            setTotalPages(result.totalPages);
            setInstanceUsageData(result.content);
            break;
        }
      },
    }
  );

  const debouncedSendRequest = useCallback(
    debounce((n: number) => {
      sendRequest({ pageNo: n, startFetchTime, endFetchTime });
    }, 200),
    [startFetchTime, endFetchTime]
  );

  type Items = 'PageData' | 'UsageCount';
  type ItemsMap = { [key in Items]: () => void };

  const handleStartTimeChange = (
    date: Date | null,
    updateItems: Items[] = ['PageData', 'UsageCount']
  ) => {
    setShowInvalidTimeRangeMessage(false);

    selectFilter(null);
    setStartFetchTime(date);

    if (date === null) {
      setEndFetchTime(null);
      setTotalPages(0);
      setCount(null);
      setPricing(null);
      setInstanceUsageData([]);
      return;
    }

    const itemsMap: ItemsMap = {
      PageData: () => {
        sendRequest({ pageNo: 0, startFetchTime: date, endFetchTime });
      },
      UsageCount: () => {
        sendUsageCountRequest({ startFetchTime: date, endFetchTime });
      },
    };

    if (date && endFetchTime) {
      for (const item of updateItems) {
        itemsMap[item]();
      }
    }
  };

  const handleEndTimeChange = (
    date: any,
    updateItems: Items[] = ['PageData', 'UsageCount']
  ) => {
    setShowInvalidTimeRangeMessage(false);

    selectFilter(null);
    setEndFetchTime(date);

    const itemsMap: ItemsMap = {
      PageData: () => {
        sendRequest({ pageNo: 0, startFetchTime, endFetchTime: date });
      },
      UsageCount: () => {
        sendUsageCountRequest({ startFetchTime, endFetchTime: date });
      },
    };

    if (startFetchTime && date) {
      for (const item of updateItems) {
        itemsMap[item]();
      }
    }
  };

  const [
    showInvalidTimeRangeMessage,
    setShowInvalidTimeRangeMessage,
  ] = useState(false);
  let isTimeRangeNegative = false;

  const isTimeRangeIncorrect = (() => {
    if (startFetchTime === null || endFetchTime === null) return true;
    if (startFetchTime > endFetchTime) {
      isTimeRangeNegative = true;
      return true;
    } else {
      return false;
    }
  })();

  const getMinDate = () => {
    let date = new Date(instance.dateCreated);
    date.setDate(date.getDate() - 2);
    return date;
  };

  const getMaxDate = () => {
    let date = new Date();
    date.setDate(date.getDate() + 2);
    return date;
  };

  const handleBlur = () => {
    setShowInvalidTimeRangeMessage(true);
  };

  // Ensures right page number is shown when page number is changed
  // and modal is closed and reopened.
  const pageStringValue = useRef('1');
  const tablePaginationKey = `${startFetchTime} ${endFetchTime}`;
  usePreviousValue(tablePaginationKey, () => {
    // If `tablePaginationKey` is changed, which means start or end date is changed,
    // reset page number back to `'1'`
    pageStringValue.current = '1';
  });

  const [showReport, _setShowReport] = useState(false);
  const setShowReport = (v: boolean) => {
    _setShowReport(v);
    if (v) {
      const pageNo = parseInt(pageStringValue.current) - 1;
      // Can't check for `&& pageNo < totalPages` as totalPages is initially zero
      if (!isNaN(pageNo) && pageNo >= 0)
        sendRequest({ pageNo, startFetchTime, endFetchTime });
    } else {
      sendUsageCountRequest({ startFetchTime, endFetchTime });
    }
  };

  return (
    <section>
      <section className="userform">
        <div className={css.rangesection}>
          <DatePicker
            id="startFetchTime1"
            date={startFetchTime ? moment(startFetchTime) : null}
            onDateChange={date => {
              handleStartTimeChange(date ? date?.toDate() : null, [
                'UsageCount',
              ]);
            }}
            isOutsideRange={day =>
              day.isBefore(moment(getMinDate())) ||
              day.isAfter(moment(getMaxDate()))
            }
            handleBlur={handleBlur}
          />
          to
          <DatePicker
            id="startEndTime1"
            date={endFetchTime ? moment(endFetchTime) : null}
            onDateChange={date =>
              handleEndTimeChange(date ? date?.toDate() : null, ['UsageCount'])
            }
            isOutsideRange={day =>
              day.isBefore(moment(getMinDate())) ||
              day.isAfter(moment(getMaxDate()))
            }
            disabled={!startFetchTime}
            handleBlur={handleBlur}
          />
        </div>
        {isTimeRangeNegative && showInvalidTimeRangeMessage && (
          <p className={css['incorrect-time-range']}>
            Start date and time should not be greater than end date and time
          </p>
        )}
        <QuickFilters updateItems={['UsageCount']} />
        <Label text="Total Calls">
          <p>{count === null ? '--' : count}</p>
        </Label>
        <Label
          text="Total Estimated Spends"
          tip="For Actual Spends please refer your AWS monthly bill"
        >
          <p>
            {count == null || pricing == null
              ? '--'
              : `$${(pricing * count).toFixed(2)}`}
          </p>
        </Label>
      </section>
      {showReport && (
        <Modal
          handleClose={() => {
            setShowReport(false);
          }}
          showClose
          inlineStyle={{ width: '90%' }}
          dismissModalCloseEvents
        >
          <h2 className={css.usagedetailsheader}>
            Usage details{' '}
            <DatePicker
              id="startFetchTime2"
              date={startFetchTime ? moment(startFetchTime) : null}
              onDateChange={date =>
                handleStartTimeChange(date ? date?.toDate() : null, [
                  'PageData',
                ])
              }
              isOutsideRange={day =>
                day.isBefore(moment(getMinDate())) ||
                day.isAfter(moment(getMaxDate()))
              }
              handleBlur={handleBlur}
            />
            to
            <DatePicker
              id="startEndTime2"
              date={endFetchTime ? moment(endFetchTime) : null}
              onDateChange={date =>
                handleEndTimeChange(date ? date?.toDate() : null, ['PageData'])
              }
              isOutsideRange={day =>
                day.isBefore(moment(getMinDate())) ||
                day.isAfter(moment(getMaxDate()))
              }
              disabled={!startFetchTime}
              handleBlur={handleBlur}
            />
          </h2>
          {isTimeRangeNegative && showInvalidTimeRangeMessage && (
            <p className={css['modal--incorrect-time-range']}>
              Start date and time should not be greater than end date and time
            </p>
          )}
          <QuickFilters center updateItems={['PageData']} />
          <Table>
            <Header>
              <Heading>No. of calls</Heading>
              {role === UserRole.Admin ? <Heading>Send time</Heading> : null}
              <Heading>Fetch time</Heading>
              {role === UserRole.Admin ? <Heading>Created at</Heading> : null}
              {role === UserRole.Admin ? <Heading>Updated at</Heading> : null}
              {role === UserRole.Admin ? <Heading>Test data</Heading> : null}
            </Header>
            {current === AsyncState.Pending ? (
              <UsageRecordPlaceholder />
            ) : (
              <Body>
                {instanceUsageData.length &&
                startFetchTime &&
                endFetchTime &&
                validPageInput ? (
                  <>
                    {instanceUsageData.map(instanceUsage => (
                      <Row key={instanceUsage.id}>
                        <Cell>{instanceUsage.amount}</Cell>
                        {role === UserRole.Admin ? (
                          <Cell>
                            <TimeFormat date={instanceUsage.sendTime} />
                          </Cell>
                        ) : null}
                        <Cell>
                          <TimeFormat date={instanceUsage.fetchTime} />
                        </Cell>
                        {role === UserRole.Admin ? (
                          <Cell>
                            <TimeFormat date={instanceUsage.dateCreated} />
                          </Cell>
                        ) : null}
                        {role === UserRole.Admin ? (
                          <Cell>
                            <TimeFormat date={instanceUsage.dateUpdated} />
                          </Cell>
                        ) : null}
                        {role === UserRole.Admin ? (
                          <Cell>{instanceUsage.testData ? 'Yes' : 'No'}</Cell>
                        ) : null}
                      </Row>
                    ))}
                  </>
                ) : (
                  <div className={css.emptyrecords}>
                    {!validPageInput ? (
                      <div>Entered page number is invalid</div>
                    ) : (
                      <>
                        {startFetchTime && endFetchTime ? (
                          <div>No usage records found</div>
                        ) : (
                          <div>Please select a time range first</div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </Body>
            )}
            {totalPages === 0 || !startFetchTime || !endFetchTime ? (
              <div style={{ height: '65px' }} />
            ) : (
              <Footer>
                <TablePagination
                  key={tablePaginationKey}
                  loading={current === AsyncState.Pending}
                  pages={totalPages}
                  onPageChange={n => {
                    sendRequest({ pageNo: n, startFetchTime, endFetchTime });
                  }}
                  onInputChange={debouncedSendRequest}
                  initialValue={pageStringValue.current}
                  onPageStringChange={(stringValue, isValid) => {
                    pageStringValue.current = stringValue;
                    setValidPageInput(isValid);
                  }}
                />
              </Footer>
            )}
          </Table>
        </Modal>
      )}
      <div className="btnrow">
        <Button
          kind="default"
          onClick={() => setShowReport(true)}
          disabled={isTimeRangeIncorrect}
        >
          Show Details
        </Button>
        <Button onClick={goBack}>Cancel</Button>
      </div>
    </section>
  );

  function twitchDataRange(
    option: string,
    updateItems: Items[] = ['PageData', 'UsageCount']
  ) {
    const endFetchTime = new Date();
    const startFetchTime = getTimeStamp(option);
    selectFilter(option);
    setStartFetchTime(startFetchTime);
    setEndFetchTime(endFetchTime);

    const itemsMap: ItemsMap = {
      PageData: () => {
        sendRequest({ pageNo: 0, startFetchTime, endFetchTime });
      },
      UsageCount: () => {
        sendUsageCountRequest({ startFetchTime, endFetchTime });
      },
    };

    for (const item of updateItems) {
      itemsMap[item]();
    }
  }

  function getTimeStamp(option: string) {
    let startFetchTime: Date | null = new Date();
    switch (option) {
      case 'a':
        // today
        startFetchTime.setHours(0, 0);
        break;
      case 'b':
        // This week
        startFetchTime.setDate(
          startFetchTime.getDate() - startFetchTime.getDay() + 1
        );
        startFetchTime.setHours(0, 0);
        break;
      case 'c':
        // Last week
        startFetchTime.setDate(startFetchTime.getDate() - 7);
        break;
      case 'd':
        // This month
        startFetchTime.setDate(1);
        break;
      case 'e':
        // Last month
        startFetchTime.setMonth(startFetchTime.getMonth() - 1);
        break;
      case 'f':
        // 6 months
        startFetchTime.setMonth(startFetchTime.getMonth() - 6);
        break;
      case 'g':
        // This year
        startFetchTime.setDate(1);
        startFetchTime.setMonth(0);
        startFetchTime.setHours(0, 0);
        break;
      case 'h':
        // All time
        startFetchTime = new Date(instance.dateCreated.replace(/-/g, '/'));
    }
    return startFetchTime;
  }

  function clearFilters() {
    setStartFetchTime(null);
    setEndFetchTime(null);
    selectFilter(null);

    setTotalPages(0);
    setCount(null);
    setPricing(null);
    setInstanceUsageData([]);
  }
};

export default InstanceUsage;
