import React, { useState, useContext, useEffect } from 'react';
import { object as yupObject, string as yupString } from 'yup';
import { useForm } from 'react-hook-form';

import Instance, { instanceShape } from 'schemas/Instance';
import InstanceTypesContext from 'App/InstanceTypesContext';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Checkbox from 'components/atoms/Checkbox';
import Select from 'components/atoms/Select';
import Option from 'components/atoms/Option';
import Button from 'components/atoms/Button';
import useSignal from 'utils/hooks/useSignal';
import InstanceApi from 'api/InstanceApi';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import FieldError from 'components/atoms/FieldError';
import Modal, { DialogHeading } from 'components/Modal';
import CopyToClipboard from 'components/CopyToClipboard';
import { UserContext } from 'App';
import { UserRole } from 'schemas/User';
import Link from 'components/atoms/Link';
import Alert from 'components/Alert';
import { goBack } from 'utils/helpers';
import moment from 'moment';

interface FormData {
  name: string;
  enabled: boolean;
  instanceTypeId: string;
  instanceEndPoint: string;
}

type ValidationFields = Omit<FormData, 'enabled'>;

const validationSchema = yupObject<ValidationFields>({
  name: instanceShape.instanceName.label('Name'),
  instanceTypeId: yupString()
    .required()
    .label('Instance type'),
  instanceEndPoint: instanceShape.url.label('Endpoint URL'),
});

const Info: React.FC<{
  instance: Instance;
  onFormBeingModified: (k: boolean) => void;
}> = ({ instance, onFormBeingModified }) => {
  const { instanceTypes } = useContext(InstanceTypesContext);

  const { signal } = useSignal();

  const { role } = useContext(UserContext);

  const {
    register: fieldRef,
    handleSubmit,
    getValues,
    errors: formErr,
    formState,
    reset,
  } = useForm<FormData>({
    validationSchema,
    defaultValues: {
      name: instance.name,
      enabled: instance.enabled,
      instanceTypeId: instance.instanceTypeId,
      instanceEndPoint: instance.instanceEndPoint,
    },
  });

  /* Form state values */
  const { dirty } = formState;

  useEffect(() => {
    onFormBeingModified(dirty);
  }, [dirty, onFormBeingModified]);

  const formPromise = async () => {
    const values = getValues();
    const result = await InstanceApi.update(
      { ...instance, ...values },
      { signal }
    );
    return result;
  };

  const { current, run: sendRequest } = useAsync(formPromise);
  const isPending = current === AsyncState.Pending;
  const isSuccess = current === AsyncState.Success;

  const onSubmit = handleSubmit(async () => {
    await sendRequest();
    reset(
      getValues(),
      // @ts-ignore
      { dirty: false }
    );
  });

  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

  async function deleteInstance() {
    try {
      await InstanceApi.delete(instance.id);
      goBack();
    } catch (e) {
      console.log('failed');
    }
  }

  return (
    <section>
      <Alert success={isSuccess ? 'Details saved successfully' : null} />
      <form onSubmit={onSubmit} spellCheck={false}>
        <section className="userform">
          <Label text="Name" tip="Name of the instance">
            <Input name="name" ref={fieldRef} />
            <FieldError err={formErr.name} />
          </Label>
          <Label
            text="ID"
            tip="An arbitary and unique identifier for the instance generated by the system to uniquely identify this instance"
          >
            <p>
              {instance.id}
              <CopyToClipboard text={instance.id} />
            </p>
          </Label>
          <Label text="Company">
            {role === UserRole.User ? (
              <p>{instance.userCompany}</p>
            ) : (
              <Link to={`/users/${instance.userId}`}>
                {instance.userCompany}
              </Link>
            )}
          </Label>
          <Label text="Endpoint URL" tip="Endpoint URL for platform instance">
            <Input name="instanceEndPoint" ref={fieldRef} />
            <FieldError err={formErr.instanceEndPoint} />
          </Label>
          <Label text="Enabled">
            <Checkbox name="enabled" ref={fieldRef} />
          </Label>
          <Label text="Instance type">
            <Select name="instanceTypeId" ref={fieldRef}>
              {instanceTypes.map(({ id, name }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
            <FieldError err={formErr.instanceTypeId} />
          </Label>
          <Label text="Username" tip="Username for the instance">
            <p>
              {instance.username}
              <CopyToClipboard text={instance.username} />
            </p>
          </Label>
          <Label text="Created on">
            <p>{moment(instance.dateCreated).format('DD-MM-YYYY HH:mm:ss')}</p>
          </Label>
          {instance.dateUpdated && (
            <Label text="Updated on">
              <p>
                {moment(instance.dateUpdated).format('DD-MM-YYYY HH:mm:ss')}
              </p>
            </Label>
          )}
        </section>

        <div className="btnrow">
          <Button type="submit" kind="brand" loading={isPending}>
            Save Changes
          </Button>
          {!instance.locked && role === UserRole.User && (
            <Button kind="red" onClick={() => setShowDeletePopup(true)}>
              Delete Instance
            </Button>
          )}
          <Button onClick={goBack}>Cancel</Button>
        </div>
      </form>
      {showDeletePopup && (
        <Modal isDialog handleClose={() => setShowDeletePopup(false)}>
          <DialogHeading>Delete Instance</DialogHeading>
          <div className="dialogcontent">
            Are you sure you want to delete {instance.name}?
          </div>
          <div className="btnrow">
            <Button onClick={deleteInstance} kind="red">
              Yes
            </Button>
            <Button kind="default" onClick={() => setShowDeletePopup(false)}>
              No
            </Button>
          </div>
        </Modal>
      )}
    </section>
  );
};

export default Info;
