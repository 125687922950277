import React, { useState, useRef } from 'react';

import User, { SubscriptionStatus } from 'schemas/User';
import UserSearchField from 'components/SearchField';
import NewUser from './NewUser';
import Button from 'components/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultLayout from 'layouts/DefaultLayout';
import Seo from 'components/Seo';
import useSignal from 'utils/hooks/useSignal';
import UserApi from 'api/UserApi';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import useInput from 'utils/hooks/useInput';
import debounce from 'utils/debounce';
import {
  RenderContent,
  SubscriptionStatusFilters,
  RequestReason,
} from './content';

import css from './AdminUsers.module.scss';

interface UserPromiseParams {
  text: string;
  pageNo: number;
  subscriptionStatus: SubscriptionStatus | null;
  clearUsers?: boolean;
  requestReason: RequestReason;
}

const AdminUsers: React.FC = () => {
  const pageSize = 12;

  const { signal } = useSignal();

  const textFilter = useInput(
    '',
    e => {
      debouncedCallback.current(e.target.value, activeSubscriptionStatus);
    },
    () => {
      debouncedCallback.current('', activeSubscriptionStatus);
    }
  );
  const [users, setUsers] = useState<User[]>([]);
  const [pageNo, setPageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [
    activeSubscriptionStatus,
    setActiveSubscriptionStatus,
  ] = useState<SubscriptionStatus | null>(null);

  const usersPromise = async ({
    text,
    pageNo,
    subscriptionStatus,
    clearUsers = false,
  }: UserPromiseParams) => {
    const instances = await UserApi.list(
      { pageSize, text, pageNo, subscriptionStatus },
      { signal }
    );
    return instances;
  };

  const lastRequestReason = useRef<RequestReason>(
    RequestReason.FilterInitialFetch
  );

  const { run: sendRequest, current } = useAsync(usersPromise, {
    mode: 'last',
    immediate: [
      {
        text: '',
        pageNo: 0,
        subscriptionStatus: null,
        requestReason: RequestReason.FilterInitialFetch,
      },
    ],
    onCurrentChange({ current, data, err }, params) {
      const { clearUsers, requestReason } = params[0];

      lastRequestReason.current = requestReason;

      switch (current) {
        case AsyncState.Success:
          const result = data!;
          setPageNo(result.number);
          setTotalPages(result.totalPages);
          if (clearUsers) {
            setUsers(() => [...result.content]);
          } else {
            setUsers(arr => [...arr, ...result.content]);
          }
          break;
      }
    },
  });

  const debouncedCallback = useRef(
    debounce((text: string, subscriptionStatus: SubscriptionStatus | null) => {
      const textToPass = text.trim();
      const isTextEmpty = textToPass.length === 0;
      const requestReason = isTextEmpty
        ? RequestReason.FilterInitialFetch
        : RequestReason.Search;

      sendRequest({
        text: textToPass,
        pageNo: 0,
        subscriptionStatus,
        clearUsers: true,
        requestReason,
      });
    }, 200)
  );

  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);

  return (
    <DefaultLayout>
      <Seo title="Users" />
      <section className={css.usergridpanel}>
        <section className={css.userpageheader}>
          <h1 className={`userpageheading ${css.userpageheadertitle}`}>
            Users
          </h1>
          <span style={{ flex: '1' }}></span>
          <UserSearchField placeholder="Search for users" {...textFilter} />
          <Button
            kind="default"
            onClick={() => setIsNewUserModalOpen(true)}
            disabled={isNewUserModalOpen}
          >
            <FontAwesomeIcon icon="plus" /> Create User
          </Button>
        </section>
        <SubscriptionStatusFilters
          activeSubscriptionStatus={activeSubscriptionStatus}
          setActiveSubscriptionStatus={status => {
            sendRequest({
              text: textFilter.value,
              pageNo: 0,
              subscriptionStatus: status,
              clearUsers: true,
              requestReason: RequestReason.FilterInitialFetch,
            });
            setActiveSubscriptionStatus(status);
          }}
        />
        <RenderContent
          current={current}
          users={users}
          loadMore={() => {
            sendRequest({
              text: textFilter.value,
              pageNo: pageNo + 1,
              subscriptionStatus: activeSubscriptionStatus,
              requestReason: RequestReason.LoadMore,
            });
          }}
          requestReason={lastRequestReason.current}
          pageNo={pageNo}
          totalPages={totalPages}
        />
      </section>
      {isNewUserModalOpen && (
        <NewUser handleClose={() => setIsNewUserModalOpen(false)} />
      )}
    </DefaultLayout>
  );
};

export default AdminUsers;
