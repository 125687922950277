import api, { apiWithoutHooks, RequestOptions } from '.';

import User from 'schemas/User';

interface Credentials {
  username: string;
  password: string;
}

interface LoginResponse {
  userDetails: User;
  expiration: number;
}

class AuthApi {
  static login(
    creds: Credentials,
    options: RequestOptions = {}
  ): Promise<LoginResponse> {
    return apiWithoutHooks.post('auth', { ...options, json: creds }).json();
  }

  static logout(options: RequestOptions = {}) {
    return api.post('logout', { ...options });
  }
}

export default AuthApi;
