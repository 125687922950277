import React, { useContext } from 'react';

import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import { UserContext } from 'App';
import ReleaseNotes from './ReleaseNotes';
import DocsBar from 'components/DocsBar/DocsBar';
import DefaultLayout from 'layouts/DefaultLayout';
import EULA from './EULA';
import PrivacyPolicy from './PrivacyPolicy';
import About from './About';
import SimpleLayout from 'layouts/SimpleLayout';
import RouteGuard from 'components/RouteGuard';

import './Docs.scss';
import Seo from 'components/Seo';

const DocsContent: React.FC = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <div className="docs-container">
        <Switch>
          <Route exact path={path}>
            <Redirect to={`${path}/eula`} />
          </Route>
          <Route path={`${path}/eula`}>
            <EULA />
          </Route>
          <Route path={`${path}/privacy`}>
            <PrivacyPolicy />
          </Route>
          <Route path={`${path}/about`}>
            <About />
          </Route>
          <RouteGuard path={`${path}/release-notes`}>
            <ReleaseNotes />
          </RouteGuard>
          <Route path={`${path}**`}>
            <Redirect to={`${path}/eula`} />
          </Route>
        </Switch>
      </div>
    </>
  );
};

const Docs: React.FC = () => {
  const { user } = useContext(UserContext);
  return (
    <>
      <Seo title="Docs" />
      {user ? (
        <DefaultLayout>
          <DocsBar />
          <DocsContent />
        </DefaultLayout>
      ) : (
        <SimpleLayout>
          <DocsContent />
        </SimpleLayout>
      )}
    </>
  );
};

export default Docs;
