import React, { forwardRef } from 'react';
import cn from 'classnames';

import css from './checkbox.module.scss';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ checked = undefined, type, style, className, ...props }, ref) => {
    let classes = cn(css.checkbox);

    return (
      <input
        type="checkbox"
        checked={checked}
        className={classes}
        {...props}
        ref={ref}
      />
    );
  }
);

export default Checkbox;
