import { CSSProperties } from 'react';

export interface Node {
  id: string;
  title: string;
  path: string;
  layerCSS: CSSProperties;
  expanded: boolean;
  nodes: Node[];
}

const FirstLayer: CSSProperties = {
  textDecoration: 'none',
};

const SecondLayer: CSSProperties = {
  marginLeft: 15,
  textDecoration: 'none',
};

export const table: Node[] = [
  {
    id: '6',
    title: 'Glossary',
    path: '/glossary',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [],
  },
  {
    id: '1',
    title: 'Overview',
    path: '/overview',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [],
  },
  {
    id: '2',
    title: 'Setup',
    path: '/setup',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [
      {
        id: '2.1',
        title: 'Subscribe',
        path: '/setup/subscribe',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '2.2',
        title: 'Register',
        path: '/setup/register',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '2.3',
        title: 'Create New Instance',
        path: '/setup/create-instance',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '2.4',
        title: 'Configure the Instance on ServiceNow',
        path: '/setup/configure-on-snow',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
    ],
  },
  {
    id: '3',
    title: 'Administration',
    path: '/administration',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [
      {
        id: '3.1',
        title: 'User Profile',
        path: '/administration/user-profile',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '3.2',
        title: 'Change User Password',
        path: '/administration/change-user-password',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '3.3',
        title: 'View Instances',
        path: '/administration/view-instances',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '3.4',
        title: 'View/Edit Instance Details',
        path: '/administration/instance-details',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '3.5',
        title: 'View Instance Usage',
        path: '/administration/instance-usage',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '3.6',
        title: 'Enable/Disable Instance',
        path: '/administration/enable-disable-instance',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '3.7',
        title: 'Change Instance Password',
        path: '/administration/change-instance-password',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '3.8',
        title: 'Delete Instance',
        path: '/administration/delete-instance',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
    ],
  },
  {
    id: '4',
    title: 'Unsubscribe',
    path: '/unsubscribe',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [],
  },
  {
    id: '5',
    title: 'Additional Resources',
    path: '/additional-resources',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [],
  },
];
