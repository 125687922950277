import React, { useState, useRef, useEffect } from 'react';
import moment, { Moment } from 'moment';
import {
  SingleDatePicker as LibDatePicker,
  SingleDatePickerShape,
} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import './datePicker.scss';

/* A component for DateTimePicker */

interface DatePickerProps
  extends Pick<
    SingleDatePickerShape,
    'date' | 'onDateChange' | 'isOutsideRange' | 'disabled' | 'id'
  > {
  handleBlur: Function;
}
const DatePicker: React.FC<DatePickerProps> = ({
  id,
  date,
  onDateChange,
  isOutsideRange,
  disabled,
  handleBlur,
}) => {
  const [focused, setFocused] = useState<boolean | null>(false);
  const [showDropdown, setDropdownVisibility] = useState<boolean | null>(false);
  const [timeInput, setTimeInput] = useState<string>(
    date?.format('HH:mm') ?? ''
  );
  const [invalidDateInput, setDateInputValidity] = useState<boolean | null>();
  const timePickerRef = useRef<HTMLInputElement | null>(null);

  const timeOptions = getTimeOptions();

  useEffect(() => {
    setTimeInput(date?.format('HH:mm') ?? '');
    setDateInputValidity(false);
  }, [date, focused]);

  /* handles date input change */
  function handleDateInputChange(evt: React.FormEvent<HTMLDivElement>) {
    // @ts-ignore
    if (evt.target.tagName !== 'INPUT' || evt.type !== 'change') return;

    // works like a wrapper, gets bubbled up event of datepicker

    const inputEvent = evt as React.ChangeEvent<HTMLInputElement>;

    // all allowed formats
    const allowedFormats: string[] = [
      'DD-MM-YYYY',
      'DD-M-YYYY',
      'D-M-YYYY',
      'D-MM-YYYY',
    ];

    // if no input is there
    if (!inputEvent.target.value) {
      setDateInputValidity(false);
      onDateChange(null);
      return;
    }

    // if date that has been written till now is valid according to allowedFormats, change the date
    let dateObj = moment(inputEvent.target.value, allowedFormats, true);
    if (dateObj.isValid()) {
      modifyDateInput(dateObj);
    } else {
      setDateInputValidity(true);
    }
  }

  return (
    <div
      className={`CustomDatePicker ${invalidDateInput && 'InvalidDateInput'}`}
    >
      <div onChange={handleDateInputChange}>
        <LibDatePicker
          focused={focused}
          onFocusChange={({ focused }) => {
            setFocused(focused);
            handleBlur();
          }}
          id={id}
          date={date}
          onDateChange={date => {
            if (date) {
              modifyDateInput(date);
            }
          }}
          placeholder="dd-mm-yyyy"
          isOutsideRange={isOutsideRange}
          disabled={disabled}
          displayFormat="DD-MM-YYYY"
        />
      </div>
      <div className="TimePicker_container">
        <input
          placeholder="HH:MM"
          className="TimePicker"
          disabled={disabled}
          ref={timePickerRef}
          value={timeInput}
          onChange={evt => {
            if (moment(evt.target.value, 'HH:mm', true).isValid()) {
              modifyTimeInput(evt.target.value);
              setDropdownVisibility(false);
            }
            setTimeInput(evt.target.value);
          }}
          onClick={() => setDropdownVisibility(!showDropdown)}
          onFocus={() => setDropdownVisibility(true)}
          onBlur={() => {
            if (!moment(timeInput, 'HH:mm', true).isValid()) clearTimeInput();
          }}
        />
        {showDropdown && (
          <>
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100vh',
                width: '100vw',
                zIndex: 15,
              }}
              onClick={() => setDropdownVisibility(false)}
            ></div>
            <div className="TimePicker_dropdown">
              {timeOptions.map((option, index) => (
                <div
                  className="TimePicker_dropdownItem"
                  key={index}
                  onClick={() => {
                    setTimeInput(option);
                    modifyTimeInput(option);
                    setDropdownVisibility(false);
                  }}
                  tabIndex={0}
                >
                  {option}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );

  function modifyTimeInput(timeString: string) {
    let newDate: Moment | null = date;
    let hours = moment(timeString, 'HH:mm', true).hours();
    let minutes = moment(timeString, 'HH:mm', true).minutes();
    newDate?.minutes(minutes).hours(hours);
    onDateChange(newDate);
    handleBlur();
  }

  function modifyDateInput(dateObj: Moment | null) {
    // Date Input is valid
    setDateInputValidity(false);

    let newDate: Moment | null = dateObj;
    if (timeInput) {
      let hours = moment(timeInput, 'HH:mm', true).hours();
      let minutes = moment(timeInput, 'HH:mm', true).minutes();
      newDate?.hours(hours).minutes(minutes);
    }
    onDateChange(newDate);
    handleBlur();
    // timePickerRef.current?.focus();
  }

  function clearTimeInput() {
    setTimeInput('');
    onDateChange(date?.hours(0).minutes(0)!);
    handleBlur();
  }

  /* A function to return an array of all 48 time combos */
  function getTimeOptions() {
    let arr = [];
    let hours = '00';
    for (let i = 0; i < 48; i++) {
      let minutes = '00';
      if (i % 2 !== 0) {
        // if its odd
        minutes = '30';
      } else {
        // if its even
        hours = `${i / 2}`.padStart(2, '0');
      }
      arr.push(`${hours}:${minutes}`);
    }
    return arr;
  }
};

export default DatePicker;
