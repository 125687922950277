import React, { CSSProperties, useContext } from 'react';

import { UserContext, UserStateReason } from 'App';
import SimpleLayout from 'layouts/SimpleLayout';
import DefaultLayout from 'layouts/DefaultLayout';
import Seo from 'components/Seo';
import Link from 'components/atoms/Link';

import css from './NotFound.module.scss';
import { UserRole } from 'schemas/User';
import LoadingBuffer from 'components/LoadingBuffer';

const NotFoundContent: React.FC<{ isLoggedIn?: boolean }> = props => {
  const { isLoggedIn } = props;

  const { role } = useContext(UserContext);

  let containerStyle: CSSProperties = {
    height: isLoggedIn ? 'calc(100vh - 80px)' : '',
  };

  let content = (
    <span>
      The page you're looking for cannot be found, you can try to{' '}
      <Link to="/login">login here</Link>.
    </span>
  );

  if (isLoggedIn) {
    content = (
      <span>
        The page you're looking for cannot be found.
        <br />
        Try going back to the{' '}
        <Link to={role === UserRole.Admin ? '/admin-instances' : '/instances'}>
          Instances
        </Link>{' '}
        page or see our{' '}
        <Link to="/administration-guide">Administration Guide</Link> for more
        information.
      </span>
    );
  }

  return (
    <section className={css.pnfcontainer} style={containerStyle}>
      <div className={css.pnfpanel}>
        <span className={css.notfoundnumber}>404</span>
        <span className={css.notfoundtext}>Page not found</span>
        <div style={{ textAlign: 'center' }}>{content}</div>
      </div>
    </section>
  );
};

const NotFound: React.FC = () => {
  const { user, stateReason } = useContext(UserContext);

  if (stateReason === UserStateReason.Initial) {
    return <LoadingBuffer />;
  }

  return (
    <>
      <Seo title="Page not found" />
      {user ? (
        <DefaultLayout>
          <NotFoundContent isLoggedIn />
        </DefaultLayout>
      ) : (
        <SimpleLayout>
          <NotFoundContent />
        </SimpleLayout>
      )}
    </>
  );
};

export default NotFound;
