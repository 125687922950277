import React, { useContext } from 'react';

import cn from 'classnames';
import ecsLogo from 'assets/img/logo.png';
import Link from 'components/atoms/Link';
import { UserContext } from 'App';
import { UserRole } from 'schemas/User';

import css from './ECSLogo.module.scss';

interface ECSLogoProps {
  marker?: boolean;
  className?: string;
}

const ECSLogo: React.FC<ECSLogoProps> = props => {
  const { user, role } = useContext(UserContext);

  let logoClasses = cn(css.ecsLogo, { [css.marker]: props.marker });
  let imgClasses = cn(css.ecsimg, props.className ? props.className : '');

  return (
    <Link className={logoClasses} to={getAppropriateLink()}>
      <img src={ecsLogo} className={imgClasses} alt="ECS Logo" />
    </Link>
  );

  function getAppropriateLink() {
    if (user) {
      if (role === UserRole.Admin) return '/admin-instances';
      if (role === UserRole.User) return '/instances';
    }
    return '/login';
  }
};

export default ECSLogo;
