import React, { forwardRef } from 'react';
import css from './Link.module.scss';
import { Link as RLink, LinkProps } from 'react-router-dom';

/**
 * Wrapper around `react-router-dom` Link component
 */
const Link = forwardRef<RLink, LinkProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <RLink className={`${className} ${css.link}`} {...props} ref={ref}>
        {children}
      </RLink>
    );
  }
);

export default Link;
