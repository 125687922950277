import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';

import Button from 'components/atoms/Button';

import css from './Table.module.scss';
import { noop } from 'utils/helpers';
import useOnMount from 'utils/hooks/useOnMount';

const {
  table_grid,
  table_head,
  table_heading,
  table_body,
  table_row,
  table_cell,
  table_footer,
  table_pagecounter,
} = css;

const Table: React.FC = ({ children }) => {
  return <section className={table_grid}>{children}</section>;
};

export const Header: React.FC = ({ children }) => {
  return <div className={table_head}>{children}</div>;
};

export const Heading: React.FC = ({ children }) => {
  return <div className={table_heading}>{children}</div>;
};

export const Body: React.FC = ({ children }) => {
  return <div className={table_body}>{children}</div>;
};

export const Row: React.FC = ({ children }) => {
  return <div className={table_row}>{children}</div>;
};

export const Cell: React.FC = ({ children }) => {
  return <div className={table_cell}>{children}</div>;
};

export const Footer: React.FC = ({ children }) => {
  return <div className={table_footer}>{children}</div>;
};

interface TablePaginationProps {
  initialValue?: string;
  pages: number;
  onPageChange: (pageNo: number) => void;
  onInputChange?: (pageNo: number) => void;
  debouncedSetPageNumber?: (pageNo: number) => void;
  loading: boolean;
  onPageStringChange?: (text: string, isValid: boolean) => void;
}

// if `debouncedSetPageNumber` is passed, it'll be called on change in vlaue of page number input instead of `setPageNumber`
export const TablePagination: React.FC<TablePaginationProps> = ({
  pages,
  onPageChange,
  onInputChange,
  loading,
  onPageStringChange = noop,
  initialValue = '1',
}) => {
  const changePage = (pageNo: number) => {
    if (onInputChange) {
      onInputChange(pageNo);
    } else {
      onPageChange(pageNo);
    }
  };

  const [isPrevLoading, setIsPrevLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);

  const [pageNumberString, _setPageNumberString] = useState(initialValue);

  const setPageNumberString = (v: string, cb = (isValidNum: boolean) => {}) => {
    _setPageNumberString(v);
    const parsedPageNumber = parseInt(v) - 1;
    let isValidNum = !isNaN(parsedPageNumber);
    if (isValidNum && (parsedPageNumber < 0 || parsedPageNumber > pages - 1)) {
      isValidNum = false;
    }
    onPageStringChange(v, isValidNum);
    setIsIncorrectPage(!isValidNum);
    cb(isValidNum);
  };

  const pageNumber = parseInt(pageNumberString) - 1;

  const [isIncorrectPage, setIsIncorrectPage] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsIncorrectPage(false);
    const stringValue = e.target.value;
    const value = parseInt(stringValue) - 1;
    setPageNumberString(stringValue, isValidNum => {
      if (isValidNum) {
        changePage(value);
      }
    });
  };

  // As initially page number is `initialValue`, manually call `onPageStringChange`
  useOnMount(() => {
    setPageNumberString(initialValue);
  });

  useEffect(() => {
    if (!loading) {
      setIsPrevLoading(false);
      setIsNextLoading(false);
    }
  }, [loading]);

  const isLoading = isPrevLoading || isNextLoading;

  const inputClasses = cn(css.pagenumber, {
    [css['pagenumber--text_error']]: isIncorrectPage,
  });

  return (
    <>
      <Button
        onClick={() => {
          if (isIncorrectPage) return;
          setIsPrevLoading(true);
          setPageNumberString(pageNumber.toString());
          changePage(pageNumber - 1);
        }}
        disabled={pageNumber <= 0 || isLoading || isIncorrectPage || loading}
        loading={isPrevLoading}
      >
        Previous
      </Button>
      <div className={table_pagecounter}>
        <form onSubmit={e => e.preventDefault()}>
          Page{' '}
          <input
            autoFocus
            disabled={isLoading}
            className={inputClasses}
            value={pageNumberString}
            onChange={handleInputChange}
          />{' '}
          of {`${pages}`}
        </form>
      </div>
      <Button
        onClick={() => {
          if (isIncorrectPage) return;
          setIsNextLoading(true);
          setPageNumberString((pageNumber + 2).toString());
          changePage(pageNumber + 1);
        }}
        disabled={
          pageNumber >= pages - 1 || isLoading || isIncorrectPage || loading
        }
        loading={isNextLoading}
      >
        Next
      </Button>
    </>
  );
};

export default Table;
