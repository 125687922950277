import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import serviceNowLogo from 'pages/Instances/images/servicenow.jpg';
import salesforceLogo from 'pages/Instances/images/salesforce.jpg';

import { history } from 'App';
import Instance from 'schemas/Instance';
import { AsyncState } from 'utils/hooks/useAsync';

import RenderDelay from 'components/RenderDelay';
import Button from 'components/atoms/Button';
import EmptyList from 'components/EmptyList';

import InstancesPlaceholder from 'pages/Instances/InstancesPlaceholder';
import User from 'schemas/User';

import css from 'pages/Instances/Instances.module.scss';

export enum RequestReason {
  LoadMore,
  Search,
  InitialFetch,
}

const appLogoMapping: { [k: string]: any } = {
  Salesforce: salesforceLogo,
  ServiceNow: serviceNowLogo,
};

const InstanceTile: React.FC<{
  instance: Instance;
}> = ({ instance }) => {
  return (
    <div
      tabIndex={0}
      key={instance.id}
      className={`${css.instancetile} ${css.enabled}`}
    >
      <section onClick={() => openInstance(instance.id)}>
        <img
          src={appLogoMapping[instance.instanceTypeName]}
          alt="apptile"
          className={css.applogo}
        />
        <div className={css.namecontainer}>
          <span className={css.appname}>{instance.name}</span>
        </div>
      </section>
    </div>
  );

  function openInstance(instanceID: string) {
    history.push(`/instance/${instanceID}`);
  }
};

interface InstanceTypeProps {
  current: AsyncState;
  instances: Instance[];
  pageNo: number;
  totalPages: number;
  loadMore: Function;
  requestReason: RequestReason;
}

const InstancesCard: React.FC<{
  heading: string;
  data: Omit<InstanceTypeProps, 'current' | 'requestReason'>;
  disableLoadMore?: boolean;
}> = ({
  heading,
  data: { instances, pageNo, totalPages, loadMore },
  disableLoadMore,
}) => {
  return (
    <div>
      {instances.length !== 0 && (
        <section className={css.appsection}>
          <h2 className={css.appsectionheader}>{heading}</h2>
          <div className={css.instancegrid}>
            {instances.map(instance => (
              <InstanceTile key={instance.id} instance={instance} />
            ))}
          </div>
          <div>
            {pageNo < totalPages - 1 && (
              <Button onClick={() => loadMore()} disabled={disableLoadMore}>
                <FontAwesomeIcon icon="arrow-circle-down" /> Load More
              </Button>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

const RenderInstanceContent: React.FC<{
  heading: string;
  data: InstanceTypeProps;
}> = ({
  heading,
  data: { current, instances, loadMore, pageNo, totalPages, requestReason },
}) => {
  switch (current) {
    case AsyncState.Initial:
    case AsyncState.Pending:
      if (requestReason === RequestReason.LoadMore) {
        return (
          <InstancesCard
            heading={heading}
            data={{
              instances,
              loadMore,
              pageNo,
              totalPages,
            }}
            disableLoadMore
          />
        );
      }
      return (
        <RenderDelay>
          <InstancesPlaceholder />
        </RenderDelay>
      );
    case AsyncState.Success:
      if (instances.length === 0) {
        return null;
      }
      return (
        <InstancesCard
          heading={heading}
          data={{
            instances,
            loadMore,
            pageNo,
            totalPages,
          }}
        />
      );
    case AsyncState.Error:
      // TODO handle err
      return null;
  }
};

interface RenderContentProps {
  user: User | 'NOT_EXISTS' | null;
  enabledInstances: InstanceTypeProps;
  disabledInstances: InstanceTypeProps;
}

export const RenderContent: React.FC<RenderContentProps> = ({
  user,
  disabledInstances,
  enabledInstances,
}) => {
  if (
    enabledInstances.current === AsyncState.Success &&
    disabledInstances.current === AsyncState.Success &&
    enabledInstances.instances.length === 0 &&
    disabledInstances.instances.length === 0
  ) {
    // no need as admin cannot create new instances
    // if (
    //   enabledInstances.requestReason === RequestReason.InitialFetch &&
    //   disabledInstances.requestReason === RequestReason.InitialFetch
    // )
    //   return <EmptyList item="Instances" suggestToCreateNew />;
    return <EmptyList item="Instances" />;
  }

  return (
    <>
      <RenderInstanceContent
        key="enabled"
        heading="Enabled"
        data={enabledInstances}
      />
      <RenderInstanceContent
        key="disabled"
        heading="Disabled"
        data={disabledInstances}
      />
    </>
  );
};
