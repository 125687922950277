import React, { useContext, useState, useEffect } from 'react';
import { object as yupObject } from 'yup';
import { useForm } from 'react-hook-form';

import { history, UserContext, UserStateReason } from 'App';
import { userShape } from 'schemas/User';
import UserApi from 'api/UserApi';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import useSignal from 'utils/hooks/useSignal';
import DefaultLayout from 'layouts/DefaultLayout';
import Seo from 'components/Seo';
import Passwords from 'components/Passwords';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import FieldError from 'components/atoms/FieldError';
import Alert from 'components/Alert';
import { goBack, getErrorMessage } from 'utils/helpers';
import useAsyncErrJson from 'utils/hooks/useAsyncErrJson';
import BackButton from 'components/BackButton';
import UnsavedChanges from 'components/UnsavedChanges';
import ReloadService from 'services/ReloadService';

import css from './ChangeUserPassword.module.scss';

interface FormData {
  oldPassword: string;
  newPassword: string;
  rePassword: string;
}

const passwordNotSame = {
  type: 'passwordNotSame',
  msg: 'New password must be different from old password.',
};

const validationSchema = yupObject<FormData>({
  oldPassword: userShape.password.label('Old password'),
  newPassword: userShape.password
    .test(passwordNotSame.type, passwordNotSame.msg, function(this, v: string) {
      return this.parent['oldPassword'] !== v;
    })
    .label('New password'),
  rePassword: userShape.rePassword('newPassword').label('Confirm password'),
});

const ChangeUserPassword: React.FC = () => {
  const { user, setUser } = useContext(UserContext);

  const { signal } = useSignal();

  const {
    register: fieldRef,
    handleSubmit,
    getValues,
    errors: formErr,
    watch,
    setError: setFormErr,
    clearError: clearFormErr,
    formState: { dirty },
  } = useForm<FormData>({
    validationSchema,
  });

  const [isAlertShown, setIsAlertShown] = useState(false);
  const [showUnsavedChangesPopup, setUnsavedChangesPopup] = useState<boolean>(
    false
  );

  const oldPasswordValue = watch('oldPassword');
  const newPasswordValue = watch('newPassword');
  const rePasswordValue = watch('rePassword');

  // We are already validating for when new pwd is same as old pwd, but we aren't handling it when old pwd is changed
  const handleOldPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value !== newPasswordValue &&
      formErr.newPassword?.type === passwordNotSame.type
    ) {
      clearFormErr('newPassword');
    } else if (
      e.target.value === newPasswordValue &&
      newPasswordValue &&
      formErr.newPassword?.type !== passwordNotSame.type
    ) {
      setFormErr('newPassword', passwordNotSame.type, passwordNotSame.msg);
    }
  };

  // Using useEffect is safe here as all depedencies are string
  useEffect(() => {
    setIsAlertShown(false);
  }, [oldPasswordValue, newPasswordValue, rePasswordValue]);

  const formPromise = async () => {
    const { oldPassword, newPassword } = getValues();
    const userId = user!.id;

    const userDetails = await UserApi.get(userId, { signal });

    const result = await UserApi.updatePassword(
      {
        ...userDetails,
        oldPassword,
        newPassword,
      },
      { signal }
    );

    return result;
  };

  const { current, run: sendRequest, err } = useAsync(formPromise, {
    onCurrentChange({ current, data, err }) {
      async function logoutAndRedirectOnSuccess() {
        setUser(null, UserStateReason.NullBecauseLoggedOut);
        history.push({
          pathname: '/login',
          state: {
            reason: 'You need to log back in.',
          },
        });
        ReloadService.triggerReload();
      }

      setIsAlertShown(true);

      switch (current) {
        case AsyncState.Success:
          logoutAndRedirectOnSuccess();
          break;
      }
    },
  });
  const isPending = current === AsyncState.Pending;

  const errJson = useAsyncErrJson(current, err, signal);
  const errMsg = isPending ? null : getErrorMessage(err, { errJson });

  const onSubmit = handleSubmit(async () => {
    await sendRequest();
  });

  return (
    <DefaultLayout>
      <Seo title="Change Password" />
      <section className={css.cuppanel}>
        <h2 className="userpageheading">
          <BackButton
            onClick={() => {
              if (dirty) setUnsavedChangesPopup(true);
              else goBack();
            }}
          />
          Change password
        </h2>
        {isAlertShown && <Alert error={errMsg} />}
        <form onSubmit={onSubmit} spellCheck={false}>
          <section className="userform">
            <Label text="Old password">
              <Input
                name="oldPassword"
                type="password"
                ref={fieldRef}
                onChange={handleOldPasswordChange}
              />
              <FieldError err={formErr.oldPassword} />
            </Label>
            <Passwords
              password={{
                name: 'newPassword',
                ref: fieldRef,
                err: formErr.newPassword,
                label: 'New password',
              }}
              rePassword={{
                name: 'rePassword',
                ref: fieldRef,
                err: formErr.rePassword,
              }}
            />
          </section>
          <div className={css.cupbtnpanel}>
            <Button type="submit" kind="brand" loading={isPending}>
              Save Changes
            </Button>
            <Button onClick={() => goBack()}>Cancel</Button>
          </div>
          {showUnsavedChangesPopup && (
            <UnsavedChanges handleClose={() => setUnsavedChangesPopup(false)} />
          )}
        </form>
      </section>
    </DefaultLayout>
  );
};

export default ChangeUserPassword;
