import React, { useState, useEffect } from 'react';

import { HashLink as Link } from 'react-router-hash-link';
import { navigateToSection } from 'utils/navigationLogic';
import Seo from 'components/Seo';
import OL, { LI } from 'components/atoms/OrderedList';

import css from './EULA.module.scss';

const EULA: React.FC = () => {
  useEffect(() => {
    navigateToSection();
  }, []);

  const [agreedTerms] = useState([
    {
      title: 'Interpretation',
      id: 'interpretation',
      points: [
        <span className={css.bunchofparas}>
          <span>
            The definitions and rules of interpretation in this clause apply in
            this agreement.
          </span>
          <span>
            <strong>Authorised Users</strong>: those employees, agents and
            independent contractors of the Customer who are authorised by the
            Customer to use the Services and the Documentation.
          </span>
          <span>
            <strong>Business Day</strong>: a day other than a Saturday, Sunday
            or public holiday in England when banks in London are open for
            business.
          </span>
          <span>
            <strong>Call Handling charges</strong>: the fees payable by the
            Customer to the Supplier for calls handled via the Software
            (including both inbound and outbound), as set out in paragraph
            Schedule 1 and as amended in accordance with this agreement.
          </span>
          <span>
            <strong>Confidential Information</strong>: information that is
            proprietary or confidential and is either clearly labelled as such
            or identified as Confidential Information in{' '}
            <Link to="#clause115">clause 11.5</Link> or{' '}
            <Link to="#clause116">clause 11.6</Link>.
          </span>
          <span>
            <strong>
              {' '}
              Controller, processor, data subject, personal data, personal data
              breach, processing
            </strong>{' '}
            and{' '}
            <strong>appropriate technical and organisational measures</strong>:
            as defined in the Data Protection Legislation.
          </span>
          <span>
            <strong>Customer Data</strong>: the data inputted by the Customer,
            Authorised Users, or the Supplier on the Customer's behalf for the
            purpose of using the Services or facilitating the Customer's use of
            the Services.
          </span>
          <span>
            <strong>Data Protection Legislation</strong>: all applicable data
            protection and privacy legislation in force from time to time in the
            UK including the General Data Protection Regulation ((EU) 2016/679);
            the Data Protection Act 2018; the Privacy and Electronic
            Communications Directive 2002/58/EC (as updated by Directive
            2009/136/EC) and the Privacy and Electronic Communications
            Regulations 2003 (SI 2003/2426) as amended.
          </span>
          <span>
            <strong>Documentation</strong>: the document made available to the
            Customer by the Supplier online via{' '}
            <a href="https://admin.rocket-cx.com/#/">RocketCX</a> or such other
            web address notified by the Supplier to the Customer from time to
            time which sets out a description of the Services and the user
            instructions for the Services.
          </span>
          <span>
            <strong>Effective Date</strong>: the date this agreement is accepted
            by the Customer.
          </span>
          <span>
            <strong>Normal Business Hours</strong>: 9.00 am to 5.00 pm local UK
            time, each Business Day.
          </span>
          <span>
            <strong>Services</strong>: the subscription services provided by the
            Supplier to the Customer under this agreement and as described in
            the Documentation.
          </span>
          <span>
            <strong>Software</strong>: the online software applications provided
            by the Supplier as part of the Services.
          </span>
          <span>
            <strong>Subscription Term</strong>: has the meaning given in{' '}
            <Link to="#clause141">clause 14.1</Link>.
          </span>
          <span>
            <strong>Virus</strong>: any thing or device (including any software,
            code, file or programme) which may: prevent, impair or otherwise
            adversely affect the operation of any computer software, hardware or
            network, any telecommunications service, equipment or network or any
            other service or device; prevent, impair or otherwise adversely
            affect access to or the operation of any programme or data,
            including the reliability of any programme or data (whether by
            re-arranging, altering or erasing the programme or data in whole or
            part or otherwise); or adversely affect the user experience,
            including worms, trojan horses, viruses and other similar things or
            devices.
          </span>
          <span>
            <strong>Vulnerability</strong>: a weakness in the computational
            logic (for example, code) found in software and hardware components
            that when exploited, results in a negative impact to the
            confidentiality, integrity, or availability, and the term{' '}
            <strong>Vulnerabilities</strong> shall be construed accordingly.
          </span>
        </span>,
        <span>
          Clause, schedule and paragraph headings shall not affect the
          interpretation of this agreement.
        </span>,
        <span>
          A person includes an individual, corporate or unincorporated body
          (whether or not having separate legal personality) and that person's
          legal and personal representatives, successors or permitted assigns.
        </span>,
        <span>
          A reference to a company shall include any company, corporation or
          other body corporate, wherever and however incorporated or
          established.
        </span>,
        <span>
          Unless the context otherwise requires, words in the singular shall
          include the plural and in the plural shall include the singular.
        </span>,
        <span>
          Unless the context otherwise requires, a reference to one gender shall
          include a reference to the other genders.
        </span>,
        <span>
          A reference to a statute or statutory provision is a reference to it
          as it is in force as at the date of this agreement.
        </span>,
        <span>
          A reference to a statute or statutory provision shall include all
          subordinate legislation made as at the date of this agreement under
          that statute or statutory provision.
        </span>,
        <span>
          References to clauses and schedules are to the clauses and schedules
          of this agreement; references to paragraphs are to paragraphs of the
          relevant schedule to this agreement.
        </span>,
      ],
    },
    {
      title: 'Use Of Services',
      id: 'use-of-services',
      points: [
        <span>
          Subject to the restrictions set out in this{' '}
          <Link to="#use-of-services">clause 2</Link> and the other terms and
          conditions of this agreement, the Supplier hereby grants to the
          Customer a non-exclusive, non-transferable right, without the right to
          grant sub-licences, to permit the Authorised Users to use the Services
          and the Documentation during the Subscription Term solely for the
          Customer's internal business operations.
        </span>,
        <span>
          The Customer shall not access, store, distribute or transmit any
          Viruses, or any material during the course of its use of the Services
          that:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              is unlawful, harmful, threatening, defamatory, obscene,
              infringing, harassing or racially or ethnically offensive;
            </li>
            <li>facilitates illegal activity;</li>
            <li>depicts sexually explicit images;</li>
            <li>promotes unlawful violence;</li>
            <li>
              is discriminatory based on race, gender, colour, religious belief,
              sexual orientation, disability; or
            </li>
            <li>
              is otherwise illegal or causes damage or injury to any person or
              property;
            </li>
          </ol>
          and the Supplier reserves the right, without liability or prejudice to
          its other rights to the Customer, to disable the Customer's access to
          the Services in the event of a breach of the provisions of this
          clause.
        </span>,
        <span>
          The Customer shall not:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              except as may be allowed by any applicable law which is incapable
              of exclusion by agreement between the parties and except to the
              extent expressly permitted under this agreement:
              <ol style={{ listStyleType: 'lower-roman' }}>
                <li>
                  attempt to copy, modify, duplicate, create derivative works
                  from, frame, mirror, republish, download, display, transmit,
                  or distribute all or any portion of the Software and/or
                  Documentation (as applicable) in any form or media or by any
                  means; or
                </li>
                <li>
                  attempt to de-compile, reverse compile, disassemble, reverse
                  engineer or otherwise reduce to human-perceivable form all or
                  any part of the Software; or
                </li>
              </ol>
            </li>
            <li>
              access all or any part of the Services and Documentation in order
              to build a product or service which competes with the Services
              and/or the Documentation; or
            </li>
            <li>
              use the Services and/or Documentation to provide services to third
              parties; or
            </li>
            <li>
              subject to <Link to="#clause221">clause 22.1</Link>, license,
              sell, rent, lease, transfer, assign, distribute, display,
              disclose, or otherwise commercially exploit, or otherwise make the
              Services and/or Documentation available to any third party except
              the Authorised Users, or
            </li>
            <li>
              attempt to obtain, or assist third parties in obtaining, access to
              the Services and/or Documentation, other than as provided under
              this <Link to="#use-of-services">clause 2</Link>; or
            </li>
            <li>
              introduce or permit the introduction of, any Virus or
              Vulnerability into the Supplier's network and information systems.
            </li>
          </ol>
        </span>,
        <span>
          The Customer shall use all reasonable endeavours to prevent any
          unauthorised access to, or use of, the Services and/or the
          Documentation and, in the event of any such unauthorised access or
          use, promptly notify the Supplier.
        </span>,
        <span>
          The rights provided under this{' '}
          <Link to="#use-of-services">clause 2</Link> are granted to the
          Customer only, and shall not be considered granted to any subsidiary
          or holding company of the Customer.
        </span>,
      ],
    },
    {
      title: 'Policies',
      id: 'policies',
      points: [
        <span>
          The Customer’s used of the Services shall at all times be subject to
          the Suppliers then current policies available at{' '}
          <a href="https://admin.rocket-cx.com/#/docs/eula">EULA</a> and{' '}
          <a href="https://admin.rocket-cx.com/#/docs/privacy">
            Privacy and Cookie Policy
          </a>
          .
        </span>,
        <span>
          The Customer shall ensure that all Authorised Users have seen the
          Policies prior to their use of the Services.
        </span>,
      ],
    },
    {
      title: 'Services',
      id: 'services',
      points: [
        <span>
          The Supplier shall, during the Subscription Term, provide the Services
          and make available the Documentation to the Customer on and subject to
          the terms of this agreement.
        </span>,
        <span>
          The Supplier shall use commercially reasonable endeavours to make the
          Services available 24 hours a day, seven days a week, except for
          maintenance which the Supplier will use reasonable endeavours to
          undertake in a manner which causes minimal disruption to the
          Customer’s use of the Services.
        </span>,
        <span>
          The Supplier will, as part of the Services, provide the Customer with
          the Supplier's standard customer support services during Normal
          Business Hours.
        </span>,
      ],
    },
    {
      title: 'Customer Data',
      id: 'customer-data',
      points: [
        <span>
          The Customer shall own all right, title and interest in and to all of
          the Customer Data that is not personal data and shall have sole
          responsibility for the legality, reliability, integrity, accuracy and
          quality of all such Customer Data.
        </span>,
        <span>
          Both parties will comply with all applicable requirements of the Data
          Protection Legislation. This <Link to="#customer-data">clause 5</Link>{' '}
          is in addition to, and does not relieve, remove or replace, a party's
          obligations or rights under the Data Protection Legislation.
        </span>,
        <span>
          The parties acknowledge that:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              if the Supplier processes any personal data on the Customer's
              behalf when performing its obligations under this agreement, the
              Customer is the controller and the Supplier is the processor for
              the purposes of the Data Protection Legislation.
            </li>
            <li>
              Schedule 2 sets out the scope, nature and purpose of processing by
              the Supplier, the duration of the processing and the types of
              personal data and categories of data subject.
            </li>
            <li>
              the personal data may be transferred or stored outside the EEA or
              the country where the Customer and the Authorised Users are
              located in order to carry out the Services and the Supplier's
              other obligations under this agreement.
            </li>
          </ol>
        </span>,
        <span id="clause54">
          Without prejudice to the generality of{' '}
          <Link to="#clause54">clause 5.4</Link>, the Customer will ensure that
          it has all necessary appropriate consents and notices in place to
          enable lawful transfer of the personal data to the Supplier for the
          duration and purposes of this agreement so that the Supplier may
          lawfully use, process and transfer the personal data in accordance
          with this agreement on the Customer's behalf.
        </span>,
        <span>
          Without prejudice to the generality of{' '}
          <Link to="#clause54">clause 5.4</Link>, the Supplier shall, in
          relation to any personal data processed in connection with the
          performance by the Supplier of its obligations under this agreement:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              process that personal data only on the documented written
              instructions of the Customer unless the Supplier is required by
              the laws of any member of the European Union or by the laws of the
              European Union applicable to the Supplier and/or Domestic UK Law
              (where <strong>Domestic UK Law</strong> means the Data Protection
              Legislation and any other law that applies in the UK) to process
              personal data (<strong>Applicable Laws</strong>). Where the
              Supplier is relying on Applicable Laws as the basis for processing
              personal data, the Supplier shall promptly notify the Customer of
              this before performing the processing required by the Applicable
              Laws unless those Applicable Laws prohibit the Supplier from so
              notifying the Customer;
            </li>
            <li>
              not transfer any personal data outside of the European Economic
              Area and the United Kingdom unless the following conditions are
              fulfilled:
              <ol style={{ listStyleType: 'lower-roman' }}>
                <li>
                  the Customer or the Supplier has provided appropriate
                  safeguards in relation to the transfer;
                </li>
                <li>
                  the data subject has enforceable rights and effective legal
                  remedies;
                </li>
                <li>
                  the Supplier complies with its obligations under the Data
                  Protection Legislation by providing an adequate level of
                  protection to any personal data that is transferred; and
                </li>
                <li>
                  the Supplier complies with reasonable instructions notified to
                  it in advance by the Customer with respect to the processing
                  of the personal data;
                </li>
              </ol>
            </li>
            <li>
              assist the Customer, at the Customer's cost, in responding to any
              request from a data subject and in ensuring compliance with its
              obligations under the Data Protection Legislation with respect to
              security, breach notifications, impact assessments and
              consultations with supervisory authorities or regulators;
            </li>
            <li>
              notify the Customer without undue delay on becoming aware of a
              personal data breach;
            </li>
            <li>
              at the written direction of the Customer, delete or return
              personal data and copies thereof to the Customer on termination of
              the agreement unless required by Applicable Law to store the
              personal data (and for these purposes the term "delete" shall mean
              to put such data beyond use); and
            </li>
            <li>
              maintain complete and accurate records and information to
              demonstrate its compliance with this{' '}
              <Link to="#customer-data">clause 5</Link> and immediately inform
              the Company if, in the opinion of the Supplier, an instruction
              infringes the Data Protection Legislation.
            </li>
          </ol>
        </span>,
        <span>
          Each party shall ensure that it has in place appropriate technical and
          organisational measures, reviewed and approved by the other party, to
          protect against unAuthorised or unlawful processing of personal data
          and against accidental loss or destruction of, or damage to, personal
          data, appropriate to the harm that might result from the unAuthorised
          or unlawful processing or accidental loss, destruction or damage and
          the nature of the data to be protected, having regard to the state of
          technological development and the cost of implementing any measures
          (those measures may include, where appropriate, pseudonymising and
          encrypting personal data, ensuring confidentiality, integrity,
          availability and resilience of its systems and services, ensuring that
          availability of and access to personal data can be restored in a
          timely manner after an incident, and regularly assessing and
          evaluating the effectiveness of the technical and organisational
          measures adopted by it).
        </span>,
        <span id="clause57">
          The Customer consents to the Supplier appointing Amazon Web Services
          as a third-party processor of personal data under this agreement. The
          Supplier confirms that it has entered or (as the case may be) will
          enter with the third-party processor into a written agreement
          substantially on that third party's standard terms of business and in
          either case which the Supplier undertakes will reflect and will
          continue to reflect the requirements of the Data Protection
          Legislation. As between the Customer and the Supplier, the Supplier
          shall remain fully liable for all acts or omissions of any third-party
          processor appointed by it pursuant to this{' '}
          <Link to="#customer-data">clause 5</Link>.
        </span>,
        <span>
          Either party may, at any time on not less than 30 days' notice, revise
          this clause 5 by replacing it with any applicable controller to
          processor standard clauses or similar terms forming part of an
          applicable certification scheme (which shall apply when replaced by
          attachment to this agreement).
        </span>,
      ],
    },
    {
      title: 'Third party providers',
      id: 'third-party-providers',
      points: [
        <span>
          The Customer acknowledges that the Services may enable or assist it to
          access the website content of, correspond with, and purchase products
          and services from, third parties via third-party websites and that it
          does so solely at its own risk. The Supplier makes no representation,
          warranty or commitment and shall have no liability or obligation
          whatsoever in relation to the content or use of, or correspondence
          with, any such third-party website, or any transactions completed, and
          any contract entered into by the Customer, with any such third party.
          Any contract entered into and any transaction completed via any
          third-party website is between the Customer and the relevant third
          party, and not the Supplier. The Supplier recommends that the Customer
          refers to the third party's website terms and conditions and privacy
          policy prior to using the relevant third-party website. The Supplier
          does not endorse or approve any third-party website nor the content of
          any of the third-party website made available via the Services.
        </span>,
      ],
    },
    {
      title: "Supplier's Obligation",
      id: 'supplier-obligation',
      points: [
        <span id="clause71">
          The Supplier undertakes that the Services will be performed
          substantially in accordance with the Documentation and with reasonable
          skill and care.
        </span>,
        <span>
          The undertaking at <Link to="#clause71">clause 7.1</Link> shall not
          apply to the extent of any non-conformance which is caused by use of
          the Services contrary to the Supplier's instructions, or modification
          or alteration of the Services by any party other than the Supplier or
          the Supplier's duly Authorised contractors or agents. If the Services
          do not conform with the foregoing undertaking, Supplier will, at its
          expense, use all reasonable commercial endeavours to correct any such
          non-conformance promptly, or provide the Customer with an alternative
          means of accomplishing the desired performance. Such correction or
          substitution constitutes the Customer's sole and exclusive remedy for
          any breach of the undertaking set out in{' '}
          <Link to="#clause71">clause 7.1</Link>.
        </span>,
        <span>
          The Supplier:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              does not warrant that:
              <ol style={{ listStyleType: 'lower-roman' }}>
                <li>
                  the Customer's use of the Services will be uninterrupted or
                  error-free;
                </li>
                <li>
                  that the Services, Documentation and/or the information
                  obtained by the Customer through the Services will meet the
                  Customer's requirements; or
                </li>
                <li>
                  the Software or the Services will be free from
                  Vulnerabilities.
                </li>
              </ol>
            </li>
            <li>
              is not responsible for any delays, delivery failures, or any other
              loss or damage resulting from the transfer of data over
              communications networks and facilities, including the internet,
              and the Customer acknowledges that the Services and Documentation
              may be subject to limitations, delays and other problems inherent
              in the use of such communications facilities.
            </li>
          </ol>
        </span>,
        <span>
          This agreement shall not prevent the Supplier from entering into
          similar agreements with third parties, or from independently
          developing, using, selling or licensing documentation, products and/or
          services which are similar to those provided under this agreement.
        </span>,
      ],
    },
    {
      title: "Customer's Obligation",
      id: 'customer-obligation',
      points: [
        <span>
          The customer shall:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              provide the supplier with:
              <ol style={{ listStyleType: 'lower-roman' }}>
                <li>
                  all necessary co-operation in relation to this agreement; and
                </li>
                <li>
                  all necessary access to such information as may be required by
                  the Supplier;
                </li>
              </ol>
              in order to provide the Services, including but not limited to
              Customer Data, security access information and configuration
              services;
            </li>
            <li>
              without affecting its other obligations under this agreement,
              comply with all applicable laws and regulations with respect to
              its activities under this agreement;
            </li>
            <li>
              carry out all other Customer responsibilities set out in this
              agreement in a timely and efficient manner. In the event of any
              delays in the Customer's provision of such assistance as agreed by
              the parties, the Supplier may adjust any agreed timetable or
              delivery schedule as reasonably necessary;
            </li>
            <li>
              ensure that the Authorised Users use the Services and the
              Documentation in accordance with the terms and conditions of this
              agreement and shall be responsible for any Authorised User's
              breach of this agreement;
            </li>
            <li>
              obtain and shall maintain all necessary licences, consents, and
              permissions necessary for the Supplier, its contractors and agents
              to perform their obligations under this agreement, including
              without limitation the Services;
            </li>
            <li>
              ensure that its network and systems comply with the relevant
              specifications provided by the Supplier from time to time; and
            </li>
            <li>
              be, to the extent permitted by law and except as otherwise
              expressly provided in this agreement, solely responsible for
              procuring, maintaining and securing its network connections and
              telecommunications links from its systems to the Supplier's data
              centres, and all problems, conditions, delays, delivery failures
              and all other loss or damage arising from or relating to the
              Customer's network connections or telecommunications links or
              caused by the internet.
            </li>
          </ol>
        </span>,
      ],
    },
    {
      title: 'Charges and payment',
      id: 'charges-and-payment',
      points: [
        <span>
          The Customer shall pay the Call Handling Charges and support fees to
          the Supplier in accordance with this clause 9 and Schedule 1.
        </span>,
        <span id="clause92">
          The Customer shall from the Effective Date pay the Supplier the Call
          Handling Charges via the Customer’s monthly Amazon Web Services
          billing agreement.
        </span>,
        <span>
          Payments shall be made monthly in arrears with the first payment being
          due one month from the Effective Date. The Supplier’s determination of
          the Call Handling Charges shall be final.
        </span>,
        <span>
          If the Supplier has not received payment within 30 days after the due
          date, and without prejudice to any other rights and remedies of the
          Supplier:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              the Supplier may, without liability to the Customer, disable the
              Customer's account and access to all or part of the Services and
              the Supplier shall be under no obligation to provide any or all of
              the Services while the invoice(s) concerned remain unpaid; and
            </li>
            <li>
              interest shall accrue on a daily basis on such due amounts at an
              annual rate equal to 4% over the then current base lending rate of
              the Bank of England from time to time, commencing on the due date
              and continuing until fully paid, whether before or after judgment.
            </li>
          </ol>
        </span>,
        <span>
          All amounts and fees stated or referred to in this agreement:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>shall be payable in USD;</li>
            <li>
              are, subject to <Link to="#clause133b">clause 13.3(b)</Link>,
              non-cancellable and non-refundable;
            </li>
            <li>
              are exclusive of value added tax, which shall be added to the
              Supplier's invoice(s) at the appropriate rate.
            </li>
          </ol>
        </span>,
        <span>
          The Supplier shall be entitled to increase the Call Handling Charges
          and the support fees payable on providing the Customer 90 days notice
          (such notice may be provided by email).
        </span>,
      ],
    },
    {
      title: 'Proprietary rights',
      id: 'proprietary-rights',
      points: [
        <span>
          The Customer acknowledges and agrees that the Supplier and/or its
          licensors own all intellectual property rights in the Services and the
          Documentation. Except as expressly stated herein, this agreement does
          not grant the Customer any rights to, under or in, any patents,
          copyright, database right, trade secrets, trade names, trademarks
          (whether registered or unregistered), or any other rights or licences
          in respect of the Services or the Documentation.
        </span>,
        <span>
          The Supplier confirms that it has all the rights in relation to the
          Services and the Documentation that are necessary to grant all the
          rights it purports to grant under, and in accordance with, the terms
          of this agreement.
        </span>,
      ],
    },
    {
      title: 'Confidentiality and compliance with policies',
      id: 'compliance-with-policies',
      points: [
        <span>
          Each party may be given access to Confidential Information from the
          other party in order to perform its obligations under this agreement.
          A party's Confidential Information shall not be deemed to include
          information that:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              is or becomes publicly known other than through any act or
              omission of the receiving party;
            </li>
            <li>
              was in the other party's lawful possession before the disclosure;
            </li>
            <li>
              is lawfully disclosed to the receiving party by a third party
              without restriction on disclosure; or
            </li>
            <li>
              is independently developed by the receiving party, which
              independent development can be shown by written evidence.
            </li>
          </ol>
        </span>,
        <span>
          Subject to <Link to="#clause114">clause 11.4</Link>, each party shall
          hold the other's Confidential Information in confidence and not make
          the other's Confidential Information available to any third party, or
          use the other's Confidential Information for any purpose other than
          the implementation of this agreement.
        </span>,
        <span>
          Each party shall take all reasonable steps to ensure that the other's
          Confidential Information to which it has access is not disclosed or
          distributed by its employees or agents in violation of the terms of
          this agreement.
        </span>,
        <span id="clause114">
          A party may disclose Confidential Information to the extent such
          Confidential Information is required to be disclosed by law, by any
          governmental or other regulatory authority or by a court or other
          authority of competent jurisdiction, provided that, to the extent it
          is legally permitted to do so, it gives the other party as much notice
          of such disclosure as possible and, where notice of disclosure is not
          prohibited and is given in accordance with this{' '}
          <Link to="#clause114">clause 11.4</Link>, it takes into account the
          reasonable requests of the other party in relation to the content of
          such disclosure.
        </span>,
        <span id="clause115">
          The Customer acknowledges that details of the Services, and the
          results of any performance tests of the Services, constitute the
          Supplier's Confidential Information.
        </span>,
        <span id="clause116">
          The Supplier acknowledges that the Customer Data is the Confidential
          Information of the Customer.
        </span>,
        <span>
          No party shall make, or permit any person to make, any public
          announcement concerning this agreement without the prior written
          consent of the other parties (such consent not to be unreasonably
          withheld or delayed), except as required by law, any governmental or
          regulatory authority (including, without limitation, any relevant
          securities exchange), any court or other authority of competent
          jurisdiction.
        </span>,
        <span>
          11.8 The above provisions of this{' '}
          <Link to="#compliance-with-policies">clause 11</Link> shall survive
          termination of this agreement, however arising.
        </span>,
      ],
    },
    {
      title: 'Indemnity',
      id: 'indemnity',
      points: [
        <span>
          The Customer shall defend, indemnify and hold harmless the Supplier
          against claims, actions, proceedings, losses, damages, expenses and
          costs (including without limitation court costs and reasonable legal
          fees) arising out of or in connection with the Customer's use of the
          Services and/or Documentation, provided that:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>the Customer is given prompt notice of any such claim;</li>
            <li>
              the Supplier provides reasonable co-operation to the Customer in
              the defence and settlement of such claim, at the Customer's
              expense; and
            </li>
            <li>
              the Customer is given sole authority to defend or settle the
              claim.
            </li>
          </ol>
        </span>,
        <span id="clause122">
          The Supplier shall defend the Customer, its officers, directors and
          employees against any claim that the Customer's use of the Services or
          Documentation in accordance with this agreement infringes any patent
          effective as of the Effective Date, copyright, trade mark, database
          right or right of confidentiality, and shall indemnify the Customer
          for any amounts awarded against the Customer in judgment or settlement
          of such claims, provided that:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>the Supplier is given prompt notice of any such claim;</li>
            <li>
              the Customer provides reasonable co-operation to the Supplier in
              the defence and settlement of such claim, at the Supplier's
              expense; and
            </li>
            <li>
              the Supplier is given sole authority to defend or settle the
              claim.
            </li>
          </ol>
        </span>,
        <span>
          In the defence or settlement of any claim, the Supplier may procure
          the right for the Customer to continue using the Services, replace or
          modify the Services so that they become non-infringing or, if such
          remedies are not reasonably available, terminate this agreement on 2
          Business Days' notice to the Customer without any additional liability
          or obligation to pay liquidated damages or other additional costs to
          the Customer.
        </span>,
        <span>
          In no event shall the Supplier, its employees, agents and
          sub-contractors be liable to the Customer to the extent that the
          alleged infringement is based on:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              a modification of the Services or Documentation by anyone other
              than the Supplier; or
            </li>
            <li>
              the Customer's use of the Services or Documentation in a manner
              contrary to the instructions given to the Customer by the
              Supplier; or
            </li>
            <li>
              the Customer's use of the Services or Documentation after notice
              of the alleged or actual infringement from the Supplier or any
              appropriate authority.
            </li>
          </ol>
        </span>,
        <span>
          The foregoing and <Link to="#clause133b">clause 13.3(b)</Link> states
          the Customer's sole and exclusive rights and remedies, and the
          Supplier's (including the Supplier's employees', agents' and
          sub-contractors') entire obligations and liability, for infringement
          of any patent, copyright, trade mark, database right or right of
          confidentiality.
        </span>,
      ],
    },
    {
      title: 'Limitation of liability',
      id: 'limitation-of-liability',
      points: [
        <span id="clause131">
          Except as expressly and specifically provided in this agreement:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              the Customer assumes sole responsibility for results obtained from
              the use of the Services and the Documentation by the Customer, and
              for conclusions drawn from such use. The Supplier shall have no
              liability for any damage caused by errors or omissions in any
              information, instructions or scripts provided to the Supplier by
              the Customer in connection with the Services, or any actions taken
              by the Supplier at the Customer's direction;
            </li>
            <li>
              all warranties, representations, conditions and all other terms of
              any kind whatsoever implied by statute or common law are, to the
              fullest extent permitted by applicable law, excluded from this
              agreement; and
            </li>
            <li>
              the Services and the Documentation are provided to the Customer on
              an "as is" basis.
            </li>
          </ol>
        </span>,
        <span id="clause132">
          Nothing in this agreement excludes the liability of the Supplier:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              for death or personal injury caused by the Supplier's negligence;
              or
            </li>
            <li>for fraud or fraudulent misrepresentation.</li>
          </ol>
        </span>,
        <span>
          Subject to <Link to="#clause131">clause 13.1</Link> and{' '}
          <Link to="#clause132">clause 13.2</Link>:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              the Supplier shall not be liable whether in tort (including for
              negligence or breach of statutory duty), contract,
              misrepresentation, restitution or otherwise for any loss of
              profits, loss of business, depletion of goodwill and/or similar
              losses or loss or corruption of data or information, or pure
              economic loss, or for any special, indirect or consequential loss,
              costs, damages, charges or expenses however arising under this
              agreement; and
            </li>
            <li id="clause133b">
              the Supplier's total aggregate liability in contract (including in
              respect of the indemnity at{' '}
              <Link to="#clause122">clause 12.2</Link> ), tort (including
              negligence or breach of statutory duty), misrepresentation,
              restitution or otherwise, arising in connection with the
              performance or contemplated performance of this agreement shall be
              limited to the total Call Handling Charges paid during the 12
              months immediately preceding the date on which the claim arose.
            </li>
          </ol>
        </span>,
      ],
    },
    {
      title: 'Term and Termination',
      id: 'term-and-termination',
      points: [
        <span id="clause141">
          This agreement shall, unless otherwise terminated as provided in this{' '}
          <Link to="#term-and-termination">clause 14</Link>, commence on the
          Effective Date and shall continue until terminate by the Customer for
          any or no reason (“Term”). The Customer may terminate this agreement
          for any or no reason on notice to the Supplier. If the Customer
          terminates this agreement in accordance with this{' '}
          <Link to="#clause141">clause 14.1</Link> all sums due for Services
          provided up to the date of termination shall be paid in accordance
          with <Link to="#charges-and-payment">clause 9</Link>.
        </span>,
        <span>
          Without affecting any other right or remedy available to it, either
          party may terminate this agreement with immediate effect by giving
          written notice to the other party if:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              the other party fails to pay any amount due under this agreement
              on the due date for payment and remains in default not less than
              60 days after being notified in writing to make such payment;
            </li>
            <li>
              the other party commits a material breach of any other term of
              this agreement which breach is irremediable or (if such breach is
              remediable) fails to remedy that breach within a period of 30 days
              after being notified in writing to do so;
            </li>
            <li>
              the other party repeatedly breaches any of the terms of this
              agreement in such a manner as to reasonably justify the opinion
              that its conduct is inconsistent with it having the intention or
              ability to give effect to the terms of this agreement;
            </li>
            <li id="clause142d">
              the other party suspends, or threatens to suspend, payment of its
              debts or is unable to pay its debts as they fall due or admits
              inability to pay its debts or is deemed unable to pay its debts
              within the meaning of section 123 of the Insolvency Act 1986, as
              if the words "it is proved to the satisfaction of the court" did
              not appear in sections 123(1)(e) or 123(2) of the Insolvency Act
              1986;
            </li>
            <li>
              the other party commences negotiations with all or any class of
              its creditors with a view to rescheduling any of its debts, or
              makes a proposal for or enters into any compromise or arrangement
              with its creditors other than for the sole purpose of a scheme for
              a solvent amalgamation of that other party with one or more other
              companies or the solvent reconstruction of that other party;
            </li>
            <li>
              a petition is filed, a notice is given, a resolution is passed, or
              an order is made, for or in connection with the winding up of that
              other party other than for the sole purpose of a scheme for a
              solvent amalgamation of that other party with one or more other
              companies or the solvent reconstruction of that other party;
            </li>
            <li>
              an application is made to court, or an order is made, for the
              appointment of an administrator, or if a notice of intention to
              appoint an administrator is given or if an administrator is
              appointed, over the other party;
            </li>
            <li>
              the holder of a qualifying floating charge over the assets of that
              other party has become entitled to appoint or has appointed an
              administrative receiver;
            </li>
            <li>
              a person becomes entitled to appoint a receiver over the assets of
              the other party or a receiver is appointed over the assets of the
              other party;
            </li>
            <li id="clause142j">
              a creditor or encumbrancer of the other party attaches or takes
              possession of, or a distress, execution, sequestration or other
              such process is levied or enforced on or sued against, the whole
              or any part of the other party's assets and such attachment or
              process is not discharged within 14 days;
            </li>
            <li>
              any event occurs, or proceeding is taken, with respect to the
              other party in any jurisdiction to which it is subject that has an
              effect equivalent or similar to any of the events mentioned in{' '}
              <Link to="#clause142d">clause 14.2(d)</Link> to{' '}
              <Link to="#clause142j">clause 14.2(j)</Link> (inclusive);
            </li>
            <li>
              the other party suspends or ceases, or threatens to suspend or
              cease, carrying on all or a substantial part of its business; or
            </li>
            <li>
              the Supplier decides to discontinue provision of the Services.
            </li>
          </ol>
        </span>,
        <span>
          On termination of this agreement for any reason:
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              all licences granted under this agreement shall immediately
              terminate and the Customer shall immediately cease all use of the
              Services and/or the Documentation;
            </li>
            <li>
              the Customer shall pay all sums due in relation to the Services
              provided prior to the date of termination.
            </li>
            <li>
              each party shall return and make no further use of any equipment,
              property, Documentation and other items (and all copies of them)
              belonging to the other party;
            </li>
            <li>
              the Supplier may destroy or otherwise dispose of any of the
              Customer Data in its possession in accordance with{' '}
              <Link to="#clause57">clause 5.7(c)</Link>, unless the Supplier
              receives, no later than 10 days after the effective date of the
              termination of this agreement, a written request for the delivery
              to the Customer of the then most recent back-up of the Customer
              Data. The Supplier shall use reasonable commercial endeavours to
              deliver the back-up to the Customer within 30 days of its receipt
              of such a written request, provided that the Customer has, at that
              time, paid all fees and charges outstanding at and resulting from
              termination (whether or not due at the date of termination). The
              Customer shall pay all reasonable expenses incurred by the
              Supplier in returning or disposing of Customer Data; and
            </li>
            <li>
              any rights, remedies, obligations or liabilities of the parties
              that have accrued up to the date of termination, including the
              right to claim damages in respect of any breach of the agreement
              which existed at or before the date of termination shall not be
              affected or prejudiced.
            </li>
          </ol>
        </span>,
      ],
    },
    {
      title: 'Force majeure',
      id: 'force-majeure',
      points: [
        <span>
          The Supplier shall have no liability to the Customer under this
          agreement if it is prevented from or delayed in performing its
          obligations under this agreement, or from carrying on its business, by
          acts, events, omissions or accidents beyond its reasonable control,
          including, without limitation, availability of third party software,
          services or solutions (including but not limited to ServiceNow and
          Amazon Web Services), strikes, lock-outs or other industrial disputes
          (whether involving the workforce of the Supplier or any other party),
          failure of a utility service or transport or telecommunications
          network, act of God, war, riot, civil commotion, malicious damage,
          compliance with any law or governmental order, rule, regulation or
          direction, accident, breakdown of plant or machinery, fire, flood,
          storm or default of suppliers or sub-contractors, provided that the
          Customer is notified of such an event and its expected duration.
        </span>,
      ],
    },
    {
      title: 'Conflict',
      id: 'conflict',
      points: [
        <span>
          If there is an inconsistency between any of the provisions in the main
          body of this agreement and the Documentation the provisions in the
          main body of this agreement shall prevail.
        </span>,
      ],
    },
    {
      title: 'Variation',
      id: 'variation',
      points: [
        <span>
          Other than expressly stated in this agreement, no variation of this
          agreement shall be effective unless it is in writing and signed by the
          parties (or their Authorised representatives).
        </span>,
      ],
    },
    {
      title: 'Waiver',
      id: 'waiver',
      points: [
        <span>
          No failure or delay by a party to exercise any right or remedy
          provided under this agreement or by law shall constitute a waiver of
          that or any other right or remedy, nor shall it prevent or restrict
          the further exercise of that or any other right or remedy. No single
          or partial exercise of such right or remedy shall prevent or restrict
          the further exercise of that or any other right or remedy.
        </span>,
      ],
    },
    {
      title: 'Rights and Remedies',
      id: 'rights-and-remedies',
      points: [
        <span>
          Except as expressly provided in this agreement, the rights and
          remedies provided under this agreement are in addition to, and not
          exclusive of, any rights or remedies provided by law.
        </span>,
      ],
    },
    {
      title: 'Severance',
      id: 'severance',
      points: [
        <span id="clause201">
          If any provision or part-provision of this agreement is or becomes
          invalid, illegal or unenforceable, it shall be deemed deleted, but
          that shall not affect the validity and enforceability of the rest of
          this agreement.
        </span>,
        <span>
          If any provision or part-provision of this agreement is deemed deleted
          under <Link to="#clause201">clause 20.1</Link> the parties shall
          negotiate in good faith to agree a replacement provision that, to the
          greatest extent possible, achieves the intended commercial result of
          the original provision.
        </span>,
      ],
    },
    {
      title: 'Entire Agreement',
      id: 'entire-agreement',
      points: [
        <span>
          This agreement constitutes the entire agreement between the parties
          and supersedes and extinguishes all previous agreements, promises,
          assurances, warranties, representations and understandings between
          them, whether written or oral, relating to its subject matter.
        </span>,
        <span>
          Each party acknowledges that in entering into this agreement it does
          not rely on, and shall have no remedies in respect of, any statement,
          representation, assurance or warranty (whether made innocently or
          negligently) that is not set out in this agreement.
        </span>,
        <span>
          Each party agrees that it shall have no claim for innocent or
          negligent misrepresentation or negligent misstatement based on any
          statement in this agreement.
        </span>,
        <span>
          Nothing in this clause shall limit or exclude any liability for fraud.
        </span>,
      ],
    },
    {
      title: 'Assignment',
      id: 'assignment',
      points: [
        <span id="clause221">
          The Customer shall not, without the prior written consent of the
          Supplier, assign, transfer, charge, sub-contract or deal in any other
          manner with all or any of its rights or obligations under this
          agreement.
        </span>,
        <span>
          The Supplier may at any time assign, transfer, charge, sub-contract or
          deal in any other manner with all or any of its rights or obligations
          under this agreement.
        </span>,
      ],
    },
    {
      title: 'No partnership or agency',
      id: 'no-agency',
      points: [
        <span>
          Nothing in this agreement is intended to or shall operate to create a
          partnership between the parties, or authorize either party to act as
          agent for the other, and neither party shall have the authority to act
          in the name or on behalf of or otherwise to bind the other in any way
          (including, but not limited to, the making of any representation or
          warranty, the assumption of any obligation or liability and the
          exercise of any right or power).
        </span>,
      ],
    },
    {
      title: 'Third party rights',
      id: 'customer-obligation',
      points: [
        <span>
          Other than the right for Amazon Web Services to collect payment from
          the Customer in accordance with{' '}
          <Link to="#clause92">clause 9.2 (b)</Link>. this agreement does not
          confer any rights on any person or party (other than the parties to
          this agreement and, where applicable, their successors and permitted
          assigns) pursuant to the Contracts (Rights of Third Parties) Act 1999.
        </span>,
      ],
    },
    {
      title: 'Notices',
      id: 'notices',
      points: [
        <span>
          Any notice required to be given under this agreement shall be in
          writing and shall be delivered by hand or sent by pre-paid first-class
          post or recorded delivery post to the other party at its address set
          out in this agreement, or such other address as may have been notified
          by that party for such purposes, or sent by fax to the other party's
          fax number as set out in this agreement.
        </span>,
        <span>
          A notice delivered by hand shall be deemed to have been received when
          delivered (or if delivery is not in business hours, at 9 am on the
          first business day following delivery). A correctly addressed notice
          sent by pre-paid first-class post or recorded delivery post shall be
          deemed to have been received at the time at which it would have been
          delivered in the normal course of post. A notice sent by fax shall be
          deemed to have been received at the time of transmission (as shown by
          the timed printout obtained by the sender).
        </span>,
      ],
    },
    {
      title: 'Governing Laws',
      id: 'governing-laws',
      points: [
        <span>
          This agreement and any dispute or claim arising out of or in
          connection with it or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with the law of England.
        </span>,
      ],
    },
    {
      title: 'Jurisdiction',
      id: 'jurisdiction',
      points: [
        <span>
          <p>
            Each party irrevocably agrees that the courts of England shall have
            exclusive jurisdiction to settle any dispute or claim arising out of
            or in connection with this agreement or its subject matter or
            formation (including non-contractual disputes or claims).
          </p>
          <p>This agreement has been entered into on the Effective Date.</p>
        </span>,
      ],
    },
  ]);

  return (
    <div className="tandccontainer">
      <Seo title="EULA" />
      <h1 className="docspagetitle">RocketCX End User License Agreement</h1>
      <p>
        This End User License Agreement is entered into between you (or the
        entity for which you are acting) ( the “Customer”) and ECS Europe
        Limited, a company incorporated and registered in Scotland with company
        number SC357435 whose registered office is at Parkview House, 6 Woodside
        Place, Glasgow, G3 7QF (the “Supplier”).
      </p>
      <h2 className="subheading">Background</h2>
      <ol type="A">
        <li>
          <p>
            The Supplier has developed certain software applications and
            platforms in relation to computer telephony integration between
            Amazon Connect and ServiceNow which it makes available to
            subscribers.
          </p>
        </li>
        <li>
          <p>
            The Customer wishes to use the Supplier's service in its business
            operations.
          </p>
        </li>
        <li>
          <p>
            The Supplier has agreed to provide, and the Customer has agreed to
            take and pay for the Supplier's service subject to the terms and
            conditions of this agreement.
          </p>
        </li>
      </ol>
      <h2 className="subheading">Agreed Terms</h2>
      <ol>
        {agreedTerms.map(section => {
          return (
            <li key={section.title}>
              <Link to={`#${section.id}`}>{section.title}</Link>
            </li>
          );
        })}
      </ol>
      {agreedTerms.map((section, index) => {
        return (
          <section key={index} className={css.section} id={section.id}>
            <h2 className="subheading">
              {index + 1}. {section.title}
            </h2>
            <OL sectionIndex={index + 1}>
              {section.points.map((point, i) => (
                <LI key={i}>{point}</LI>
              ))}
            </OL>
          </section>
        );
      })}
      <section>
        <section>
          <h2 className="subheading">Schedule 1 - Call Handling Charges</h2>
          <strong>Call Handling Charges</strong>
          <p>
            The Call Handling Charges shall amount to $0.01 per call handled. A
            call handled can be either an inbound or outgoing call initiated or
            received using the Software.
          </p>
        </section>
        <section>
          <h2 className="subheading">
            Schedule 2 - Processing, Personal Data and Data Subjects
          </h2>
          <p>
            <strong>Subject matter</strong>: The subject matter of the data
            processing is the Customer Data.
          </p>
          <p>
            <strong>Duration</strong>: The duration of the processing will be
            the term for which the Supplier provides the Services to the
            Customer.
          </p>
          <p>
            <strong>Purpose</strong>: The purpose of the data processing is the
            provision of the Services
          </p>
          <p>
            <strong>Nature of the processing</strong>: As detailed in the
            Documentation.
          </p>
          <p>
            <strong>Type of Data</strong>: All Customer Data uploaded to the
            Services by the Customer.{' '}
          </p>
          <p>
            <strong>Categories of data subjects</strong>: All categories of data
            subjects included in the Customer Data
          </p>
        </section>
      </section>
    </div>
  );
};

export default EULA;
