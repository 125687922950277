import React from 'react';

import { UserPlaceholder } from 'components/Placeholders';

import css from './AdminUsers.module.scss';

const AdminUsersPlaceholder: React.FC = () => {
  return (
    <div className={css.usergrid}>
      <UserPlaceholder />
      <UserPlaceholder />
      <UserPlaceholder />
      <UserPlaceholder />
    </div>
  );
};

export default AdminUsersPlaceholder;
