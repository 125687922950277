import React, { useState } from 'react';
import { object as yupObject } from 'yup';
import { useForm } from 'react-hook-form';

import { userShape } from 'schemas/User';
import SimpleLayout from 'layouts/SimpleLayout';
import Seo from 'components/Seo';
import Clouds from 'components/Clouds';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Link from 'components/atoms/Link';

import css from './ForgotPassword.module.scss';

import useSignal from 'utils/hooks/useSignal';
import UserApi from 'api/UserApi';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import FieldError from 'components/atoms/FieldError';
import Alert from 'components/Alert';

interface FormData {
  email: string;
}

const validationSchema = yupObject<FormData>({
  email: userShape.email.label('Email'),
});

const ForgotPassword: React.FC = () => {
  const { signal } = useSignal();

  const [sentToMailAddress, setSentToMailAddress] = useState('');

  const {
    register: fieldRef,
    handleSubmit,
    getValues,
    errors: formErr,
  } = useForm<FormData>({
    validationSchema,
  });

  const formPromise = async (email: string) => {
    const result = await UserApi.forgotPassword(email, { signal });
    return result;
  };

  const { current, run: sendRequest } = useAsync(formPromise, {
    onCurrentChange({ current, data, err }, params) {
      const email = params[0];
      switch (current) {
        case AsyncState.Success:
          setSentToMailAddress(email);
      }
    },
  });

  const isSuccess = current === AsyncState.Success;

  const successMsg = isSuccess
    ? `If a RocketCX account exists for ${sentToMailAddress}, an email will be sent to reset the password`
    : null;

  const onSubmit = handleSubmit(async () => {
    const { email } = getValues();
    await sendRequest(email);
  });

  return (
    <SimpleLayout>
      <Seo title="Forgot Password" />
      <div className={css.fpcontainer}>
        <section className={css.fppanel}>
          <h2 className="pageheading">Forgot password?</h2>
          <Alert success={successMsg} />
          <section className={css.fpinputpanel}>
            <form onSubmit={onSubmit} noValidate spellCheck={false}>
              <p>Enter your registered email address</p>
              <Input name="email" type="email" ref={fieldRef} />
              <FieldError err={formErr.email} />
              <Button
                type="submit"
                kind="brand"
                className={css.fpsendlinkbtn}
                loading={current === AsyncState.Pending}
              >
                Get Password Reset Link
              </Button>
            </form>
          </section>
          <Link to="/login" className={css.fploginpagelink}>
            Login instead?
          </Link>
        </section>
      </div>
      {/* Clouds in background */}
      <Clouds />
    </SimpleLayout>
  );
};

export default ForgotPassword;
