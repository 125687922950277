import React from 'react';

import ECSLogo from 'components/ECSLogo';

const SimpleLayout: React.FC = ({ children }) => {
  return (
    <>
      {/* ECS Logo */}
      <ECSLogo marker />
      <div>{children}</div>
    </>
  );
};

export default SimpleLayout;
