import React, { forwardRef } from 'react';
import cn from 'classnames';

import css from './button.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// `React.ButtonHTMLAttributes<HTMLButtonElement>` will infer all properties of an HTML button element
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: 'brand' | 'default' | 'red';
  fullWidth?: boolean;
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      kind = 'default',
      fullWidth = false,
      loading = false,
      type = 'button',
      disabled,
      className,
      children,
      ...props
    },
    ref
  ) => {
    let classes = cn(
      css[`btn-${kind}`],
      { [css['full-width']]: fullWidth },
      className,
      'noselect'
    );

    return (
      <button
        type={type}
        disabled={disabled || loading}
        className={classes}
        {...props}
        ref={ref}
      >
        {loading ? (
          <div className={css.btnspinner}>
            {children}
            <FontAwesomeIcon
              icon="spinner"
              className={`fa-pulse ${css.btnspinnericon}`}
            />
          </div>
        ) : (
          children
        )}
      </button>
    );
  }
);

export default Button;
