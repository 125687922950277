import React from 'react';

import { LabelPlaceholder } from 'components/Placeholders';

const InstancePlaceholder: React.FC = () => {
  return (
    <section className="userform">
      <LabelPlaceholder />
      <LabelPlaceholder />
      <LabelPlaceholder />
      <LabelPlaceholder />
      <LabelPlaceholder />
      <LabelPlaceholder />
      <LabelPlaceholder />
    </section>
  );
};

export default InstancePlaceholder;
