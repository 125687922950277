import React, { forwardRef } from 'react';
import cn from 'classnames';

import css from './input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ type = 'text', className, ...props }, ref) => {
    let classes = cn(css.input, className);

    return <input type={type} className={classes} {...props} ref={ref} />;
  }
);

export default Input;
