import { useRef, useState } from 'react';

import { noop, generateKey } from 'utils/helpers';
import useKey from './useKey';

export enum AsyncState {
  Initial = 'initial',
  Pending = 'pending',
  Success = 'fulfilled',
  Error = 'rejected',
}

interface PromiseFnShape {
  (...args: any): Promise<any>;
}

type Unpromisify<T> = T extends Promise<infer U> ? U : T;

interface SetStatesContextShape<R> {
  current: AsyncState;
  data: R | null;
  err: Error | null;
}

interface SetStatesShape<F extends PromiseFnShape> {
  (
    context: SetStatesContextShape<Unpromisify<ReturnType<F>>>,
    params: Parameters<F>,
    currentRequestKey: string
  ): void;
}

interface OnCurrentChangeShape<F extends PromiseFnShape> {
  (
    context: SetStatesContextShape<Unpromisify<ReturnType<F>>>,
    params: Parameters<F>
  ): void;
}

/**
 * @property `mode` - `last` will change values only when last request's state
 * is changed, previous requests made are ignored
 */
interface UseAsyncOptions<F extends PromiseFnShape> {
  signal?: AbortSignal;
  immediate?: Parameters<F> | false;
  onCurrentChange?: OnCurrentChangeShape<F>;
  mode?: 'default' | 'last';
}

function useAsync<F extends PromiseFnShape>(
  promiseFn: F,
  {
    immediate = false,
    onCurrentChange = noop,
    signal,
    mode = 'default',
  }: UseAsyncOptions<F> = {}
) {
  const {
    keyRef: lastRequestKey,
    generateNewKey: generateNewLastRequestKey,
  } = useKey();

  const [current, _setCurrent] = useState<AsyncState>(AsyncState.Initial);
  const [key, setKey] = useState<string>(() => generateKey());

  function setCurrent(current: AsyncState) {
    _setCurrent(current);
    setKey(generateKey());
  }

  const data = useRef<Unpromisify<ReturnType<F>> | null>(null);
  const err = useRef<Error | null>(null);

  const setStates: SetStatesShape<F> = (
    { current: newCurrent, data: newData, err: newErr },
    params,
    requestKey
  ) => {
    if (mode === 'last' && requestKey !== lastRequestKey.current) return;

    // If success but later the signal is aborted
    if (signal?.aborted) return;

    data.current = newData;
    err.current = newErr;
    setCurrent(newCurrent);
    onCurrentChange(
      { current: newCurrent, data: newData, err: newErr },
      params
    );
  };

  const run = async function(...params: Parameters<F>) {
    generateNewLastRequestKey();
    const currentRequestKey = lastRequestKey.current;

    setStates(
      { current: AsyncState.Pending, data: null, err: null },
      params,
      currentRequestKey
    );

    promiseFn(...params)
      .then(res => {
        setStates(
          { current: AsyncState.Success, data: res, err: null },
          params,
          currentRequestKey
        );
      })
      .catch((e: Error) => {
        if (e.name === 'AbortError') return;
        setStates(
          { current: AsyncState.Error, data: null, err: e },
          params,
          currentRequestKey
        );
      });
  };

  const hasRanOnce = useRef(false);

  if (immediate !== false && !hasRanOnce.current) {
    hasRanOnce.current = true;
    run(...immediate);
  }

  return { current, run, key, data: data.current, err: err.current };
}

export default useAsync;
