import React, { useEffect } from 'react';

import { navigateToSection } from 'utils/navigationLogic';
import css from './About.module.scss';
import Seo from 'components/Seo';

const About: React.FC = () => {
  useEffect(() => {
    navigateToSection();
  }, []);

  const features = [
    {
      title: 'ServiceNow Integration',
      content:
        'All standard call handling features available natively within the ServiceNow interface – Answer, Ignore, Place on hold, Consult, Transfer, Conference and Manage Agent Status.',
    },
    {
      title: 'Click to Dial',
      content:
        'Click on any telephone number or contact field within ServiceNow to make an outbound call.  RocketCX Enterprise Connector intelligently handles country and dialling codes providing a one click dialling experience for outbound contact centres.   Create favourites for even faster access.',
    },
    {
      title: 'Screen Pop',
      content:
        'Display caller details along with any open incidents or cases before the call is answered, enabling the agent to provide a warm start to the call',
    },
    {
      title: 'Automated Call Logging & Recording',
      content:
        'Call logs are created automatically capturing contact and agent details, call duration, summary, notes, disposition codes, customer satisfaction score and access to a full recording of the call.',
    },
    {
      title: 'Create Record',
      content:
        'Create ServiceNow Incidents and Cases from within the RocketCX Enterprise Connector interface linking contacts, call logs and call recordings automatically.',
    },
    {
      title: 'Reporting & Dashboards',
      content:
        'Real-time service centre dashboards and historical trend reports provide the visibility supervisors need to manage their teams all within ServiceNow.',
    },
    {
      title: 'Easy To Configure',
      content:
        'Customisable disposition codes and related record lookups allow the agent experience to be fully customised.',
    },
  ];

  return (
    <div className="servicecontainer">
      <Seo title="Product Overview" />
      <h1 className="docspagetitle">
        RocketCX Enterprise Connector Product Overview
      </h1>
      <p>
        RocketCX Enterprise Connector is a computer telephony integration (CTI)
        between Amazon Connect and ServiceNow. It provides service centre agents
        with access to a comprehensive set of telephony controls within the
        ServiceNow interface meaning they don’t need to switch screens to make
        and receive calls.
      </p>
      <p>
        RocketCX Enterprise Connector includes a number of agent productivity
        features such as click to dial, screen pop and automated call logging
        and recording. It also provides service centre supervisors with reports
        and dashboards giving them complete visibility of how the service centre
        is performing.
      </p>
      <p>
        RocketCX Enterprise Connector is available to download as a ServiceNow
        plugin in the ServiceNow store.
      </p>
      <section>
        <h4 className="subheading">Features</h4>
        <ul className={css.features}>
          {features.map((feature, i) => (
            <li key={i}>
              <span className={css.featuretitle}>{feature.title}</span> -{' '}
              {feature.content}
            </li>
          ))}
        </ul>
      </section>
      <p>
        RocketCX Enterprise Connector is available for the ServiceNow IT Service
        Management, HR Service Management and Customer Service Management
        modules.
      </p>
    </div>
  );
};

export default About;
