import React, { useContext } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import NotFound from 'pages/NotFound';
import LoadingBuffer from 'components/LoadingBuffer';
import { UserContext, history } from 'App';
import { UserStateReason } from 'App/UserContext';
import { UserRole } from 'schemas/User';
import InstanceTypesContext from 'App/InstanceTypesContext';

interface RouteGuardProps extends RouteProps {
  role?: 'user' | 'admin' | 'any';
}

/**
 * Makes redirection depending on user's current authentication and authorization status
 *
 * Handles authentication - If not authenticated, user will be redirected to login page
 * Handles authorization - If a user tries to see a page that is only accessible by admin (or vice-versa), a 404 page is shown
 */
const RouteGuard: React.FC<RouteGuardProps> = ({
  role = 'any',
  children,
  ...props
}) => {
  const { isTypeListLoaded } = useContext(InstanceTypesContext);
  const { stateReason, role: currentUserRole } = useContext(UserContext);

  if (
    !isTypeListLoaded &&
    stateReason === UserStateReason.ValueBecauseValidSession
  ) {
    return <LoadingBuffer />;
  }

  switch (stateReason) {
    case UserStateReason.Initial:
      // Show loading screen till app initializes
      return <LoadingBuffer />;
    case UserStateReason.NullBecauseInvalidSession:
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {
              reason: 'You need to login first to view this page.',
              next: window.location.href.endsWith('/login')
                ? null
                : history.location,
            },
          }}
        />
      );
    case UserStateReason.NullBecauseLoggedOut:
      return (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );
    case UserStateReason.ValueBecauseValidSession:
      if (role === 'any') return <Route {...props}>{children}</Route>;
      if (currentUserRole === UserRole.User && role !== 'user') {
        return <NotFound />;
      }
      if (currentUserRole === UserRole.Admin && role !== 'admin') {
        return <NotFound />;
      }
      return <Route {...props}>{children}</Route>;
    default:
      return null;
  }
};

export default RouteGuard;
