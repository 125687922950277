import React, { useState, useContext, useMemo } from 'react';

import DefaultLayout from 'layouts/DefaultLayout';
import Seo from 'components/Seo';
import salesforceLogo from './images/salesforce.jpg';
import serviceNowLogo from './images/servicenow.jpg';
import { UserContext, history } from 'App';
import { UserRole } from 'schemas/User';
import Instance from 'schemas/Instance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/atoms/Button';
import AppSearchField from 'components/SearchField';
import NewInstance from './NewInstance';
import EmptyList from 'components/EmptyList';
import InstanceApi from 'api/InstanceApi';
import useSignal from 'utils/hooks/useSignal';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import InstancesPlaceholder from './InstancesPlaceholder';
import RenderDelay from 'components/RenderDelay';

import css from './Instances.module.scss';
import useInput from 'utils/hooks/useInput';

const appLogoMapping: { [k: string]: any } = {
  Salesforce: salesforceLogo,
  ServiceNow: serviceNowLogo,
};

const InstanceTile: React.FC<{
  instance: Instance;
  refetchInstances: Function;
}> = ({ instance, refetchInstances }) => {
  return (
    <div
      tabIndex={0}
      key={instance.id}
      className={`${css.instancetile} ${css.enabled}`}
    >
      <section onClick={() => openInstance(instance.id)}>
        <img
          src={appLogoMapping[instance.instanceTypeName]}
          alt="apptile"
          className={css.applogo}
        />
        <div className={css.namecontainer}>
          <span className={css.appname}>{instance.name}</span>
        </div>
      </section>
    </div>
  );

  function openInstance(instanceID: string) {
    history.push(`/instance/${instanceID}`);
  }
};

interface RenderContentProps {
  current: AsyncState;
  instances: Instance[];
  refetchInstances: Function;
}

const RenderContent: React.FC<RenderContentProps> = ({
  current,
  instances,
  refetchInstances,
}) => {
  switch (current) {
    case AsyncState.Initial:
    case AsyncState.Pending:
      return (
        <RenderDelay>
          <InstancesPlaceholder />
        </RenderDelay>
      );
    case AsyncState.Success:
      if (instances.length === 0) return <EmptyList item="Instances" />;
      const enabledInstances = instances.filter(instance => instance.enabled);
      const disabledInstances = instances.filter(instance => !instance.enabled);

      return (
        <section>
          {enabledInstances.length !== 0 && (
            <section className={css.appsection}>
              <h2 className={css.appsectionheader}>Enabled</h2>
              <div className={css.instancegrid}>
                {enabledInstances.map(instance => (
                  <InstanceTile
                    key={instance.id}
                    instance={instance}
                    refetchInstances={refetchInstances}
                  />
                ))}
              </div>
            </section>
          )}
          {disabledInstances.length !== 0 && (
            <section className={css.appsection}>
              <h2 className={css.appsectionheader}>Disabled</h2>
              <div className={css.instancegrid}>
                {disabledInstances.map(instance => (
                  <InstanceTile
                    key={instance.id}
                    instance={instance}
                    refetchInstances={refetchInstances}
                  />
                ))}
              </div>
            </section>
          )}
        </section>
      );
    case AsyncState.Error:
      // TODO handle err
      return null;
  }
};

// User Instances
const Instances: React.FC = () => {
  const { signal } = useSignal();

  const [isNewItemBeingAdded, setIsNewItemBeingAdded] = useState(false);
  const { role, user } = useContext(UserContext);

  const [instances, setInstances] = useState<Instance[]>([]);

  const instancesPromise = async () => {
    const userId = user?.id ?? '__no-user-id';
    const instances = await InstanceApi.list(userId, { signal });
    return instances;
  };

  const { run: sendRequest, current } = useAsync(instancesPromise, {
    immediate: [],
    onCurrentChange({ current, data, err }) {
      switch (current) {
        case AsyncState.Success:
          const instances = data!;
          setInstances(instances);
          break;
      }
    },
  });

  const search = useInput();
  const filteredInstances = useMemo(() => {
    try {
      const toMatch = search.value.trim().toLowerCase();
      return instances.filter(instance => {
        return (
          instance.name.toLowerCase().includes(toMatch) ||
          instance.instanceEndPoint.toLowerCase().includes(toMatch) ||
          instance.id.toLowerCase().includes(toMatch) ||
          instance.instanceTypeName.toLowerCase().includes(toMatch) ||
          instance.username.toLowerCase().includes(toMatch)
        );
      });
    } catch (e) {
      return [];
    }
  }, [instances, search.value]);

  // refetch on any change
  // TODO use switch or something to define render content
  // placeholder, empty state

  return (
    <DefaultLayout>
      <Seo title="Instances" />
      <section className={css.instancegridpanel}>
        <section className={css.appheader}>
          <h1 className={`userpageheading ${css.userpageheading}`}>
            Instances
          </h1>
          <span style={{ flex: '1' }}></span>
          {/* TODO add functionality to appsearchfield */}
          <AppSearchField placeholder="Search for instances" {...search} />
          {role !== UserRole.Admin && (
            <Button
              kind="default"
              className={css.appheaderbutton}
              onClick={() => setIsNewItemBeingAdded(true)}
              disabled={isNewItemBeingAdded}
            >
              <FontAwesomeIcon icon="plus" /> Create Instance
            </Button>
          )}
        </section>
        <RenderContent
          current={current}
          instances={filteredInstances}
          refetchInstances={sendRequest}
        />
      </section>
      {isNewItemBeingAdded && (
        <NewInstance
          onCreateSuccess={sendRequest}
          handleClose={() => {
            setIsNewItemBeingAdded(false);
          }}
        />
      )}
    </DefaultLayout>
  );
};

export default Instances;
