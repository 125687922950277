import { string as yupString, ref } from 'yup';

import {
  isValidPassword,
  yupInvalidPasswordMessage,
  yupInvalidUsernameMessage,
  invalidUsernameRegex,
  invalidInstanceNameRegex,
  yupInvalidInstanceNameMessage,
  yupInvalidMinMaxMessage,
  yupRequiredFieldMessage,
} from '.';

export interface Instance {
  readonly id: string;
  name: string;
  enabled: boolean;
  username: string;
  instanceEndPoint: string;
  instanceTypeId: string;
  instanceTypeName: string;
  userId: string;
  userCompany: string;
  newPassword?: string | null;
  readonly dateCreated: string;
  readonly dateUpdated: string | null;
  locked?: boolean;
}

// Local override to turn optional properties to required ones
interface __InstanceOverride extends Instance {
  newPassword: string;
}

export type InstanceNewPassword = Pick<
  __InstanceOverride,
  'id' | 'newPassword'
>;
export type InstanceCreate = Pick<
  Instance,
  'name' | 'username' | 'instanceEndPoint' | 'instanceTypeId' | 'newPassword'
>;
export type InstanceUpdate = Pick<
  Instance,
  | 'id'
  | 'name'
  | 'instanceEndPoint'
  | 'instanceTypeId'
  | 'enabled'
  | 'newPassword'
  | 'username'
>;

export const instanceShape = {
  username: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .matches(invalidUsernameRegex, yupInvalidUsernameMessage)
    .min(3, yupInvalidMinMaxMessage(3, 50))
    .max(50, yupInvalidMinMaxMessage(3, 50)),
  instanceName: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .matches(invalidInstanceNameRegex, yupInvalidInstanceNameMessage)
    .min(3, yupInvalidMinMaxMessage(3, 50))
    .max(50, yupInvalidMinMaxMessage(3, 50)),
  url: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .url(
      'Please enter a valid ${path}' /* eslint-disable-line no-template-curly-in-string */
    )
    .min(10, yupInvalidMinMaxMessage(10, 255))
    .max(255, yupInvalidMinMaxMessage(10, 255)),
  password: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .test('validPassword', yupInvalidPasswordMessage, function(
      this,
      v: string
    ) {
      return isValidPassword(v);
    })
    .min(12, yupInvalidMinMaxMessage(12, 30))
    .max(30, yupInvalidMinMaxMessage(12, 30)),
  rePassword(passwordPath: string) {
    return (
      yupString()
        .trim()
        .required(yupRequiredFieldMessage)
        // Use cutsom test (below) over `.oneOf([ref(passwordPath)], 'Passwords do not match.')`
        .test('passwordsDontMatch', 'Passwords do not match.', function(
          this,
          v: string
        ) {
          const password = this.resolve(ref(passwordPath));
          return password === v;
        })
    );
  },
};

export default Instance;
