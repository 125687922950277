import { useRef } from 'react';
import { noop } from 'utils/helpers';

interface OnValueChangeShape<T> {
  (previousValue: T, currentValue: T): void;
}

/**
 * Keeps track of previous value
 */
function usePreviousValue<T>(
  currentValue: T,
  onValueChange: OnValueChangeShape<T> = noop
) {
  const previousValue = useRef(currentValue);
  const temp = previousValue.current;

  if (!Object.is(temp, currentValue)) {
    onValueChange(temp, currentValue);
    previousValue.current = currentValue;
  }

  return temp;
}

export default usePreviousValue;
