import React, { createContext, useState } from 'react';

import InstanceType from 'schemas/InstanceType';
import InstanceApi from 'api/InstanceApi';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';

interface InstanceTypesContextShape {
  instanceTypes: InstanceType[];
  isTypeListLoaded: boolean;
  fetchInstanceTypes: Function;
}

const InstanceTypesContext = createContext<InstanceTypesContextShape>({
  instanceTypes: [],
  isTypeListLoaded: false,
  fetchInstanceTypes: () => {},
});

export const InstanceTypesContextGlobalProvider: React.FC = ({ children }) => {
  const [isTypeListLoaded, setIsTypeListLoaded] = useState(false);
  const [instanceTypes, setInstanceTypes] = useState<InstanceType[]>([]);

  const instanceTypesPromise = async () => {
    const result = await InstanceApi.typeList({
      retry: 4,
    });
    return result;
  };

  const { run: fetchInstanceTypes } = useAsync(instanceTypesPromise, {
    immediate: [],
    onCurrentChange({ current, data, err }) {
      switch (current) {
        case AsyncState.Success:
          const instanceTypes = data!;
          setInstanceTypes(instanceTypes);
          setIsTypeListLoaded(true);
          break;
      }
    },
  });

  return (
    <InstanceTypesContext.Provider
      value={{
        instanceTypes,
        isTypeListLoaded,
        fetchInstanceTypes,
      }}
      children={children}
    />
  );
};

export default InstanceTypesContext;
