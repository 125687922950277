/**
 * Scroll to the top of an element
 */
function scrollToElement(sectionRef: HTMLElement) {
  window.scroll(0, sectionRef.offsetTop);
}

/**
 * Scroll to the top of page
 */
function scrollToTop() {
  window.scrollTo(0, 0);
}

/**
 * Check if a section is already present in the URL, if it is, navigate to that section.
 */
export function navigateToSection() {
  const arrUrl = window.location.href.split('#');
  if (arrUrl.length === 3) {
    const sectionID = arrUrl[2];
    const sectionRef: any = document.getElementById(sectionID);
    if (sectionRef) scrollToElement(sectionRef);
  } else {
    scrollToTop();
  }
}
