import React, { useContext } from 'react';
import { object as yupObject, string as yupString } from 'yup';
import { useForm } from 'react-hook-form';

import { instanceShape } from 'schemas/Instance';
import InstanceTypesContext from 'App/InstanceTypesContext';
import Modal, { ModalHeading, ModalCloseReason } from 'components/Modal';
import Passwords from 'components/Passwords';
import Label from 'components/atoms/Label';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import Option from 'components/atoms/Option';
import Button from 'components/atoms/Button';
import FieldError from 'components/atoms/FieldError';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import useSignal from 'utils/hooks/useSignal';
import InstanceApi from 'api/InstanceApi';
import { UserContext } from 'App';
import Alert from 'components/Alert';
import { getErrorMessage } from 'utils/helpers';
import useAsyncErrJson from 'utils/hooks/useAsyncErrJson';

interface FormData {
  name: string;
  instanceEndPoint: string;
  instanceTypeId: string;
  username: string;
  newPassword: string;
  rePassword: string;
}

const validationSchema = yupObject<FormData>({
  name: instanceShape.instanceName.label('Name'),
  instanceEndPoint: instanceShape.url.label('Endpoint URL'),
  instanceTypeId: yupString()
    .required()
    .label('Instance type'),
  username: instanceShape.username.label('Username'),
  newPassword: instanceShape.password.label('Password'),
  rePassword: instanceShape.rePassword('newPassword').label('Confirm password'),
});

interface HandleCloseShape {
  (reason: ModalCloseReason): void;
}

interface NewInstanceProps {
  handleClose: HandleCloseShape;
  onCreateSuccess: Function;
}

const NewInstance: React.FC<NewInstanceProps> = ({
  onCreateSuccess,
  handleClose,
}) => {
  const { instanceTypes } = useContext(InstanceTypesContext);

  const { user } = useContext(UserContext);

  const { signal } = useSignal();

  const {
    register: fieldRef,
    handleSubmit,
    getValues,
    errors: formErr,
  } = useForm<FormData>({
    validationSchema,
  });

  const formPromise = async () => {
    const { id: userId } = user!;
    const values = getValues();
    const result = await InstanceApi.add(
      {
        userId,
        instance: { ...values },
      },
      { signal }
    );
    return result;
  };

  const { current, run: sendRequest, err } = useAsync(formPromise, {
    onCurrentChange({ current, data, err }) {
      switch (current) {
        case AsyncState.Success:
          // TODO show success in Instances, not in modal
          onCreateSuccess();
          handleClose(ModalCloseReason.OtherEvent);
          break;
      }
    },
  });

  const isPending = current === AsyncState.Pending;

  const errJson = useAsyncErrJson(current, err, signal);
  const errMsg = getErrorMessage(err, { errJson });

  const onSubmit = handleSubmit(async () => {
    await sendRequest();
  });

  return (
    <>
      <Modal handleClose={handleClose} dismissModalCloseEvents>
        <ModalHeading>Create new instance</ModalHeading>
        <section>
          <Alert error={errMsg} />
          <form onSubmit={onSubmit} spellCheck={false}>
            <Label text="Name" tip="Name of the instance">
              <Input name="name" ref={fieldRef} />
              <FieldError err={formErr.name} />
            </Label>
            <Label text="Endpoint URL" tip="Endpoint URL for platform instance">
              <Input name="instanceEndPoint" ref={fieldRef} />
              <FieldError err={formErr.instanceEndPoint} />
            </Label>
            <Label text="Instance type">
              <Select name="instanceTypeId" ref={fieldRef}>
                {instanceTypes.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
              </Select>
              <FieldError err={formErr.instanceTypeId} />
            </Label>
            <Label text="Username" tip="Username for the instance">
              <Input name="username" ref={fieldRef} />
              <FieldError err={formErr.username} />
            </Label>
            <Passwords
              password={{
                name: 'newPassword',
                ref: fieldRef,
                err: formErr.newPassword,
              }}
              rePassword={{
                name: 'rePassword',
                ref: fieldRef,
                err: formErr.rePassword,
              }}
            />
            <div className="btnrow">
              <Button type="submit" kind="brand" loading={isPending}>
                Create Instance
              </Button>
              <Button
                onClick={() => {
                  handleClose(ModalCloseReason.ClickedCloseButton);
                }}
              >
                Cancel
              </Button>
            </div>
          </form>
        </section>
      </Modal>
    </>
  );
};

export default NewInstance;
