import React, { useState, useEffect } from 'react';

import { table, Node } from './tableOfContents';
import {
  HashRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import { mappingTable } from './sections';
import { navigateToSection } from 'utils/navigationLogic';
import DefaultLayout from 'layouts/DefaultLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'components/atoms/Link';
import Seo from 'components/Seo';

import './UserGuide.scss';

const ContentComponent: React.FC<{ content: JSX.Element }> = props => {
  return <>{props.content}</>;
};

interface ContentCellProps {
  section: Node;
  toggleSelected: (id: string) => void;
}

const ContentCell: React.FC<ContentCellProps> = props => {
  const section: Node = props.section;
  let { url } = useRouteMatch();

  useEffect(() => {
    navigateToSection();
  }, []);

  return (
    <div>
      <Link
        to={`${url}user-guide${section.path}`}
        style={section.layerCSS}
        className="contentcell linkstopages"
        onClick={() =>
          section.nodes.length !== 0 ? toggleSelected(section.id) : null
        }
      >
        <span className="contenttitle">{section.title}</span>
        {section.nodes.length !== 0 ? (
          section.expanded ? (
            <FontAwesomeIcon icon="chevron-down" className="arrowicons" />
          ) : (
            <FontAwesomeIcon icon="chevron-right" className="arrowicons" />
          )
        ) : null}
      </Link>
      {section.nodes && section.expanded
        ? section.nodes.map((node, i) => (
            <ContentCell
              key={i}
              section={node}
              toggleSelected={toggleSelected}
            />
          ))
        : null}
    </div>
  );

  function toggleSelected(idToToggle: string) {
    props.toggleSelected(idToToggle);
  }
};

const UserGuide: React.FC = () => {
  const [tableOfContents, setNewTable] = useState(table);
  let { path } = useRouteMatch();

  return (
    <DefaultLayout>
      <Seo title="User Guide" />
      <div className="userguidecontainer">
        <section className="userguidepanel">
          <h1 className="userpageheading">User Guide</h1>
          <Router>
            <section className="userguidecontent">
              <section className="navdrawer">
                {tableOfContents.map(content => (
                  <ContentCell
                    section={content}
                    toggleSelected={toggleSelected}
                  />
                ))}
              </section>
              <section className="sectioncontent">
                <Switch>
                  {Object.keys(mappingTable).map((k, i) => (
                    <Route
                      exact
                      key={i}
                      path={`${path}${k}`}
                      render={props => (
                        <ContentComponent
                          {...props}
                          content={mappingTable[k]}
                        />
                      )}
                    />
                  ))}
                  <Route
                    path={path}
                    render={() => (
                      <ContentComponent
                        content={mappingTable['/introduction']}
                      />
                    )}
                  />
                </Switch>
              </section>
            </section>
          </Router>
        </section>
      </div>
    </DefaultLayout>
  );

  function toggleSelected(id: string) {
    const tableCopy = [...tableOfContents];
    applyDFS(tableCopy, id);
    setNewTable(tableCopy);
  }

  function applyDFS(arr: Node[], idToToggle: string) {
    arr.forEach(ele => {
      if (ele.id === idToToggle) {
        ele.expanded = !ele.expanded;
      }
      if (ele.nodes.length !== 0) {
        applyDFS(ele.nodes, idToToggle);
      }
    });
  }
};

export default UserGuide;
