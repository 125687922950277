import api, { RequestOptions, PaginatedResponse } from '.';

import Instance, {
  InstanceNewPassword,
  InstanceCreate,
  InstanceUpdate,
} from 'schemas/Instance';
import InstanceUsage from 'schemas/InstanceUsage';
import InstanceType from 'schemas/InstanceType';
import { removeIfNullOrEmpty } from 'utils/helpers';

interface AddInstanceParams {
  userId: string;
  instance: InstanceCreate;
}

interface AdminListParams {
  text?: string;
  pageNo: number;
  pageSize: number;
  enabled: boolean;
  userId?: string;
}

interface UsageListParams {
  instanceId: string;
  startFetchTime?: string;
  endFetchTime?: string;
  pageNo: number;
  pageSize: number;
}

interface UsageCountParams {
  instanceId: string;
  startFetchTime?: string;
  endFetchTime?: string;
}

class InstanceApi {
  static get(
    instanceId: string,
    options: RequestOptions = {}
  ): Promise<Instance> {
    return api.get(`instance/${instanceId}`, { ...options }).json();
  }

  // User instance list shows full list of instances and so requires no params for pagination
  static list(
    userId: string,
    options: RequestOptions = {}
  ): Promise<Instance[]> {
    return api.get(`user/${userId}/instance`, { ...options }).json();
  }

  static adminList(
    { text, pageNo, pageSize, enabled, userId }: AdminListParams,
    options: RequestOptions = {}
  ): Promise<PaginatedResponse<Instance>> {
    const filters = removeIfNullOrEmpty({ text, userid: userId });

    return api
      .get('instance', {
        ...options,
        searchParams: {
          'page-number': pageNo,
          'page-size': pageSize,
          enabled,
          ...filters,
        },
      })
      .json();
  }

  static add(
    { userId, instance }: AddInstanceParams,
    options: RequestOptions = {}
  ): Promise<Instance> {
    const json = {
      ...instance,
      userId,
    };
    return api.post(`user/${userId}/instance`, { ...options, json }).json();
  }

  static update(
    instance: InstanceUpdate,
    options: RequestOptions = {}
  ): Promise<Instance> {
    const { id, ...instanceAttrs } = instance;
    return api
      .put(`instance/${instance.id}`, { ...options, json: instanceAttrs })
      .json();
  }

  static updatePassword(
    instance: InstanceNewPassword,
    options: RequestOptions = {}
  ): Promise<Instance> {
    return InstanceApi.update(instance as Instance, options);
  }

  static delete(instanceId: string, options: RequestOptions = {}) {
    return api.delete(`instance/${instanceId}`, { ...options });
  }

  // Show all instance types
  static typeList(options: RequestOptions = {}): Promise<InstanceType[]> {
    return api.get('instance-type', { ...options }).json();
  }

  // Show instance's usage
  static usageList(
    {
      instanceId,
      startFetchTime,
      endFetchTime,
      pageNo,
      pageSize,
    }: UsageListParams,
    options: RequestOptions = {}
  ): Promise<PaginatedResponse<InstanceUsage>> {
    const filters = removeIfNullOrEmpty({
      'start-fetch-time': startFetchTime,
      'end-fetch-time': endFetchTime,
    });

    return api
      .get(`instance/${instanceId}/usage`, {
        ...options,
        searchParams: {
          'page-number': pageNo,
          'page-size': pageSize,
          'usage-type': 'per call',
          'sort-by': 'fetchTime',
          'sort-direction': 'desc',
          ...filters,
        },
      })
      .json();
  }

  static usageCount(
    { instanceId, startFetchTime, endFetchTime }: UsageCountParams,
    options: RequestOptions = {}
  ): Promise<{ count: number; pricing: number }> {
    const filters = removeIfNullOrEmpty({
      'start-fetch-time': startFetchTime,
      'end-fetch-time': endFetchTime,
    });

    return api
      .get(`instance/${instanceId}/usage/count`, {
        ...options,
        searchParams: {
          ...filters,
        },
      })
      .json();
  }
}

export default InstanceApi;
