import React, { useState, useEffect } from 'react';

interface RenderDelayProps {
  time?: number;
}

/**
 * Delays rendering of children when mounted.
 * Useful to prevent flickering of placeholders when results from backend are received within reasonable amount of time.
 */
const RenderDelay: React.FC<RenderDelayProps> = ({ children, time = 200 }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setLoaded(true);
    }, time);

    return () => window.clearTimeout(id);
  }, [time]);

  if (!loaded) return null;
  return <>{children}</>;
};

export default RenderDelay;
