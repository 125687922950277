import React, { useContext } from 'react';
import { object as yupObject, string as yupString } from 'yup';
import { useForm } from 'react-hook-form';

import SimpleLayout from 'layouts/SimpleLayout';
import Seo from 'components/Seo';
import { UserContext, UserStateReason, history } from 'App';
import AuthApi from 'api/AuthApi';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import useSignal from 'utils/hooks/useSignal';
import Button from 'components/atoms/Button';
import FieldError from 'components/atoms/FieldError';
import Input from 'components/atoms/Input';
import Label from 'components/atoms/Label/Label';
import { Redirect } from 'react-router-dom';
import Clouds from 'components/Clouds';
import Link from 'components/atoms/Link';
import Alert from 'components/Alert';
import { getErrorMessage } from 'utils/helpers';
import { UserRole } from 'schemas/User';
import { yupRequiredFieldMessage } from 'schemas';

import css from './Login.module.scss';
import LoadingBuffer from 'components/LoadingBuffer';
import useAsyncErrJson from 'utils/hooks/useAsyncErrJson';
import ReloadService from 'services/ReloadService';
import InstanceTypesContext from 'App/InstanceTypesContext';

const httpStatusMap = {
  401: 'Incorrect username or password',
};

interface FormData {
  username: string;
  password: string;
}

const validationSchema = yupObject<FormData>({
  username: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .label('Username'),
  password: yupString()
    .trim()
    .required(yupRequiredFieldMessage)
    .label('Password'),
});

interface HistoryStateParams {
  reason?: string;
  next?: Location | null;
  initialValues?: {
    username: string;
  };
}

const Login: React.FC = () => {
  const { fetchInstanceTypes, isTypeListLoaded } = useContext(
    InstanceTypesContext
  );
  const { stateReason, setUser, role } = useContext(UserContext);

  const historyState = { ...history.location.state } as HistoryStateParams;
  const { reason, next, initialValues } = historyState;

  const {
    register: fieldRef,
    handleSubmit,
    getValues,
    errors: formErr,
  } = useForm<FormData>({
    validationSchema,
    defaultValues: {
      username: initialValues?.username ?? '',
    },
  });

  const { signal } = useSignal();
  const formPromise = async () => {
    const { username, password } = getValues();
    const { userDetails: user } = await AuthApi.login(
      { username, password },
      { signal }
    );
    if (!isTypeListLoaded) fetchInstanceTypes();
    return user;
  };

  const { run: sendRequest, current, err } = useAsync(formPromise, {
    signal,
    onCurrentChange({ current, data, err }) {
      switch (current) {
        case AsyncState.Success:
          const user = data!;
          setUser(user, UserStateReason.ValueBecauseValidSession);
          ReloadService.triggerReload();
          break;
      }
    },
  });

  const isPending = current === AsyncState.Pending;
  const isSuccess = current === AsyncState.Success;

  const errJson = useAsyncErrJson(current, err, signal);
  const errMsg = getErrorMessage(err, { errJson, httpStatusMap });

  // If details of user has't been fetched yet, show loading page
  if (stateReason === UserStateReason.Initial) {
    return <LoadingBuffer />;
  }

  // Login page should redirect to instance list page if a user is already logged in
  if (
    stateReason === UserStateReason.ValueBecauseValidSession &&
    isTypeListLoaded
  ) {
    // send to `next` if present
    if (next) {
      history.push(next);
    } else if (role === UserRole.User) {
      return <Redirect to="/instances" />;
    } else if (role === UserRole.Admin) {
      return <Redirect to="/admin-instances" />;
    }
  }

  const onSubmit = handleSubmit(async () => {
    await sendRequest();
  });

  return (
    <SimpleLayout>
      <Seo title="Login" />
      {reason && <div className={css.reason}>{reason}</div>}
      <div className={css.logincontainer}>
        <section className={css.loginpanel}>
          <h1 className="pageheading">
            Log in to RocketCX<div className="ecsdot"></div>
          </h1>
          <Alert error={errMsg} />
          <form
            className={css.internalpanel}
            onSubmit={onSubmit}
            spellCheck={false}
          >
            <Label text="Username">
              <Input name="username" ref={fieldRef} />
              <FieldError err={formErr.username} />
            </Label>
            <Label text="Password">
              <Input name="password" type="password" ref={fieldRef} />
              <FieldError err={formErr.password} />
            </Label>
            <Button
              type="submit"
              kind="brand"
              className={css.loginbut}
              loading={isPending || isSuccess}
            >
              Log In
            </Button>
          </form>
          <Link className="pagelink" to="/forgot-password">
            Forgot password?
          </Link>
          <p className={css.cominfo}>
            © ECS 2020 <Link to="/docs/about">About Us</Link>{' '}
            <Link to="/docs/privacy">Privacy and Cookie Policy</Link>
          </p>
        </section>
      </div>
      {/* clouds */}
      <Clouds />
    </SimpleLayout>
  );
};

export default Login;
