import React from 'react';

import css from './Option.module.scss';

interface OptionProps extends React.OptionHTMLAttributes<HTMLOptionElement> {}

/**
 * HTML `option` element wrapper
 */
const Option: React.FC<OptionProps> = ({ children, ...props }) => {
  return (
    <option className={css.option} {...props}>
      {children}
    </option>
  );
};

export default Option;
