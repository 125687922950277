import React, { forwardRef } from 'react';

import cn from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

import css from './LinkAsButton.module.scss';

/**
 * A link that is styled to look like a button
 */
const LinkAsButton = forwardRef<Link, LinkProps>(
  ({ children, className, ...props }, ref) => {
    let classes = cn(css.linkasbutton, className);
    return (
      <Link {...props} ref={ref} className={classes}>
        {children}
      </Link>
    );
  }
);

export default LinkAsButton;
