import React from 'react';

import ContentLoader from 'react-content-loader';

import css from './Placeholders.module.scss';

export const LabelPlaceholder: React.FC = () => {
  return (
    <div className={css.labelplaceholder}>
      <ContentLoader height="70">
        <rect x="0" y="17" rx="3" ry="3" width="150" height="15" />
        <rect x="0" y="40" rx="4" ry="4" width="400" height="35" />
      </ContentLoader>
    </div>
  );
};

export const InstancePlaceholder: React.FC = () => {
  return (
    <ContentLoader height="180" width="270">
      <rect x="0" y="0" width="250" height="141" rx="5" ry="5" />
      <rect x="0" y="145" width="200" height="28" rx="6" ry="6" />
    </ContentLoader>
  );
};

export const UserPlaceholder: React.FC = () => {
  return (
    <ContentLoader width="250">
      <rect x="0" y="0" width="225" height="90" rx="5" ry="5" />
    </ContentLoader>
  );
};

/* For Usage records */
export const TimePlaceholder: React.FC = () => {
  return (
    <ContentLoader width="250" height="20">
      <rect x="0" y="0" rx="4" ry="4" width="100" height="20" />
      <rect x="110" y="0" rx="4" ry="4" width="100" height="20" />
    </ContentLoader>
  );
};

export const SingleValuePlaceholder: React.FC = () => {
  return (
    <ContentLoader height="20" width="80">
      <rect x="0" y="0" rx="4" ry="4" width="80" height="20" />
    </ContentLoader>
  );
};
