import React, { useEffect } from 'react';
import { ReleaseNotesContent } from './content/ReleaseNotes';
import { navigateToSection } from 'utils/navigationLogic';
import Seo from 'components/Seo';

const ReleaseNotes: React.FC = () => {
  useEffect(() => {
    navigateToSection();
  }, []);
  return (
    <div>
      <Seo title="Release Notes" />
      <span className="docspagetitle">
        RocketCX Enterprise Connector Release Notes
      </span>
      {ReleaseNotesContent.content}
    </div>
  );
};

export default ReleaseNotes;
