import React, { forwardRef } from 'react';

import cn from 'classnames';

import css from './Select.module.scss';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {}

/**
 * HTML `select` element wrapper
 */
const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ className, children, ...props }, ref) => {
    let classes = cn(css.select, className);

    return (
      <select className={classes} ref={ref} {...props}>
        {children}
      </select>
    );
  }
);

export default Select;
