import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createHashHistory } from 'history';
import 'normalize.css/normalize.css';

import 'styles/global.scss';

import './initializeIcons';
import 'react-dates/initialize';

import { UserContextGlobalProvider } from './UserContext';
import { InstanceTypesContextGlobalProvider } from './InstanceTypesContext';
import ReloadService from 'services/ReloadService';
import RouteGuard from 'components/RouteGuard';
import ErrorBoundary from 'components/ErrorBoundary';
import NotFound from 'pages/NotFound';
import Login from 'pages/Login';
import Signup from 'pages/Signup';
import Instances from 'pages/Instances';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import Docs from 'pages/Docs/Docs';
import UserGuide from 'pages/UserGuide';
import AdminGuide from 'pages/AdminGuide';
import UserProfile from 'pages/UserProfile';
import ChangeUserPassword from 'pages/ChangeUserPassword';
import AdminUsers from 'pages/AdminUsers';
import AdminEditUserProfile from 'pages/AdminEditUserProfile';
import AdminInstances from 'pages/AdminInstances';
import AdminUserInstances from 'pages/AdminUserInstances';
import ResetPassword from 'pages/ResetPassword';
import Instance from 'pages/Instances/Instance';

/**
 * Hash history
 */
export const history = createHashHistory();

ReloadService.initialize();

const App: React.FC = () => {
  return (
    <div>
      <InstanceTypesContextGlobalProvider>
        <UserContextGlobalProvider>
          <Router history={history}>
            <ErrorBoundary>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/login" />
                </Route>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route exact path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route exact path="/reset-password">
                  <ResetPassword />
                </Route>
                <Route exact path="/signup">
                  <Signup />
                </Route>
                <Route path="/docs">
                  <Docs />
                </Route>
                <RouteGuard path="/user-guide">
                  <UserGuide />
                </RouteGuard>
                <RouteGuard path="/administration-guide">
                  <AdminGuide />
                </RouteGuard>
                <RouteGuard role="user" exact path="/instances">
                  <Instances />
                </RouteGuard>
                <RouteGuard exact path="/instance/:instanceId">
                  <Instance />
                </RouteGuard>
                <RouteGuard exact role="admin" path="/admin-instances">
                  <AdminInstances />
                </RouteGuard>
                <RouteGuard exact role="admin" path="/admin-instances/:userId">
                  <AdminUserInstances />
                </RouteGuard>
                <RouteGuard exact role="admin" path="/users/:userId">
                  <AdminEditUserProfile />
                </RouteGuard>
                <RouteGuard exact role="admin" path="/users">
                  <AdminUsers />
                </RouteGuard>
                <RouteGuard exact path="/profile">
                  <UserProfile />
                </RouteGuard>
                <RouteGuard exact path="/profile/change-password">
                  <ChangeUserPassword />
                </RouteGuard>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </ErrorBoundary>
          </Router>
        </UserContextGlobalProvider>
      </InstanceTypesContextGlobalProvider>
    </div>
  );
};

export default App;
