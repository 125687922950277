import React from 'react';

import { InstancePlaceholder } from 'components/Placeholders';

import css from './Instances.module.scss';

const InstancesPlaceholder: React.FC = () => {
  return (
    <div className={css.instancegrid}>
      <InstancePlaceholder />
      <InstancePlaceholder />
      <InstancePlaceholder />
      <InstancePlaceholder />
      <InstancePlaceholder />
      <InstancePlaceholder />
      <InstancePlaceholder />
      <InstancePlaceholder />
    </div>
  );
};

export default InstancesPlaceholder;
