import React from 'react';

import SimpleLayout from 'layouts/SimpleLayout';
import { emitter, EmitterEvent } from 'services/EventService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import css from './ErrorBoundary.module.scss';

export enum ErrorRaisedReason {
  NetworkError = 'NetworkError',
  GenericError = 'GenericError',
}

interface Props {}

interface States {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, States> {
  state = {
    hasError: false,
  };

  setHasErrorToTrue = (params: { reason: ErrorRaisedReason }) => {
    this.setState({ hasError: true });
  };

  componentDidMount() {
    emitter.on(EmitterEvent.ErrorRaised, this.setHasErrorToTrue);
  }

  componentWillUnmount() {
    emitter.off(EmitterEvent.ErrorRaised, this.setHasErrorToTrue);
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /*
  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
  }
  */

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <SimpleLayout>
          <div className={css.errorpage}>
            <div className={css.errorpanel}>
              <FontAwesomeIcon icon="heart-broken" className={css.erroricon} />
              <div className={css.errormsg}>Something went wrong</div>
              <div className={css.errormsg1}>
                Sorry for the inconvenience, we're working on it
              </div>
            </div>
          </div>
        </SimpleLayout>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
