import React, { useEffect } from 'react';
import { object as yupObject } from 'yup';
import { useForm } from 'react-hook-form';

import Passwords from 'components/Passwords';
import Instance, { instanceShape } from 'schemas/Instance';
import Label from 'components/atoms/Label';
import Button from 'components/atoms/Button';
import useSignal from 'utils/hooks/useSignal';
import InstanceApi from 'api/InstanceApi';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import Alert from 'components/Alert';
import { goBack, getErrorMessage } from 'utils/helpers';
import useAsyncErrJson from 'utils/hooks/useAsyncErrJson';

interface FormData {
  newPassword: string;
  rePassword: string;
}

const validationSchema = yupObject<FormData>({
  newPassword: instanceShape.password.label('New password'),
  rePassword: instanceShape.rePassword('newPassword').label('Confirm password'),
});

const ChangeInstancePassword: React.FC<{
  instance: Instance;
  onFormBeingModified: (k: boolean) => void;
}> = ({ instance, onFormBeingModified }) => {
  const { signal } = useSignal();

  const {
    register: fieldRef,
    handleSubmit,
    getValues,
    errors: formErr,
    formState: { dirty },
    reset,
  } = useForm<FormData>({
    validationSchema,
  });

  useEffect(() => {
    onFormBeingModified(dirty);
  }, [dirty, onFormBeingModified]);

  const formPromise = async () => {
    const { newPassword } = getValues();

    const result = await InstanceApi.updatePassword(
      { ...instance, newPassword },
      { signal }
    );
    // TODO do something with result???
    return result;
  };

  const { current, run: sendRequest, err } = useAsync(formPromise);

  const isPending = current === AsyncState.Pending;
  const isSuccess = current === AsyncState.Success;

  const errJson = useAsyncErrJson(current, err, signal);

  const onSubmit = handleSubmit(async () => {
    await sendRequest();
    reset(
      getValues(),
      // @ts-ignore
      { dirty: false }
    );
  });

  return (
    <section>
      <Alert
        error={getErrorMessage(err, { errJson })}
        success={isSuccess ? 'Password has been updated' : null}
      />
      <form onSubmit={onSubmit} spellCheck={false}>
        <section className="userform">
          <Label text="Username">
            <p>{instance.username}</p>
          </Label>
          <Label text="Company">
            <p>{instance.userCompany}</p>
          </Label>
          <Passwords
            password={{
              name: 'newPassword',
              ref: fieldRef,
              err: formErr.newPassword,
              label: 'New password',
            }}
            rePassword={{
              name: 'rePassword',
              ref: fieldRef,
              err: formErr.rePassword,
            }}
          />
        </section>
        <div className="btnrow">
          <Button type="submit" kind="brand" loading={isPending}>
            Save Changes
          </Button>
          <Button onClick={goBack}>Cancel</Button>
        </div>
      </form>
    </section>
  );
};

export default ChangeInstancePassword;
