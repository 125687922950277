import React, { useContext } from 'react';

import { UserContext } from 'App';
import { Body, Row, Cell } from 'components/atoms/Table/Table';
import { UserRole } from 'schemas/User';
import {
  SingleValuePlaceholder,
  TimePlaceholder,
} from 'components/Placeholders';

const UsageRecord: React.FC = () => {
  const { role } = useContext(UserContext);

  return (
    <Row>
      <Cell>
        <SingleValuePlaceholder />
      </Cell>
      {role === UserRole.Admin && (
        <Cell>
          <TimePlaceholder />
        </Cell>
      )}
      <Cell>
        <TimePlaceholder />
      </Cell>
      {role === UserRole.Admin && (
        <Cell>
          <TimePlaceholder />
        </Cell>
      )}
      {role === UserRole.Admin && (
        <Cell>
          <TimePlaceholder />
        </Cell>
      )}
      {role === UserRole.Admin && (
        <Cell>
          <SingleValuePlaceholder />
        </Cell>
      )}
    </Row>
  );
};

const UsageRecordPlaceholder: React.FC = () => {
  const pageSize = 8;

  return (
    <Body>
      {[...Array(pageSize)].map((e, i) => (
        <UsageRecord key={i} />
      ))}
    </Body>
  );
};

export default UsageRecordPlaceholder;
