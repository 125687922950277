import { useRef } from 'react';

import { generateKey } from 'utils/helpers';

const useKey = () => {
  const keyRef = useRef(generateKey());

  const generateNewKey = () => {
    keyRef.current = generateKey();
  };

  return {
    keyRef,
    generateNewKey,
  };
};

export default useKey;
