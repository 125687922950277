import React from 'react';

const GContent = (
  <div>
    <h1 className="docsectiontitle">Glossary</h1>
    <section className="sectionmaterial">
      <table>
        <tr>
          <th>Terminology</th>
          <th>Definition</th>
        </tr>
        <tr>
          <td>CCP</td>
          <td>Contact Control Panel</td>
        </tr>
        <tr>
          <td>CTI</td>
          <td>Computer Telephony Integration</td>
        </tr>
        <tr>
          <td>CRM</td>
          <td>Customer Relationship Management</td>
        </tr>
        <tr>
          <td>RocketCX CCP</td>
          <td>
            RocketCX CCP refers to the RocketCX Enterprise Connector's Contact
            Control Panel.
          </td>
        </tr>
        <tr>
          <td>RocketCX</td>
          <td>RocketCX refer to the RocketCX website and usage services.</td>
        </tr>
        <tr>
          <td>RocketCX Enterprise Connector</td>
          <td>
            RocketCX Enterprise Connector for ServiceNow refers to the computer
            telephony integration (CTI) application installed on ServiceNow
            hereafter referred as RocketCX Enterprise Connector.
          </td>
        </tr>
      </table>
    </section>
  </div>
);

export const GlossaryContent = {
  content: GContent,
};
