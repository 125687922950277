import React from 'react';

const SRContent = (
  <div>
    <h1 className="docsectiontitle">Browser Requirements</h1>
    <section className="sectionmaterial">
      <p>
        RocketCX Enterprise Connector is designed to work with the latest
        version of Google Chrome.
      </p>
    </section>
  </div>
);

export const SystemRequirements = {
  content: SRContent,
};
