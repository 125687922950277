import React from 'react';
import { Link } from 'react-router-dom';
import image71a from '../images/71a.png';
import image71b from '../images/71b.png';
import image71c from '../images/71c.png';
import image72a from '../images/72a.png';
import image721a from '../images/721a.png';
import image721b from '../images/721b.png';
import image73a from '../images/73a.png';
import image74a from '../images/74a.png';
import image74b from '../images/74b.png';
import image75a from '../images/75a.png';
import Screenshot from '../Screenshot';

const TOContent = (
  <div>
    <h1 className="docsectiontitle">Telephony Operations</h1>
    <section className="sectionmaterial">
      <p>Telephony operations that can be performed by the user :</p>
      <ol>
        <li>
          <Link to="/user-guide/telephony-operations/incoming-call">
            Incoming Calls
          </Link>
        </li>
        <li>
          <Link to="/user-guide/telephony-operations/outgoing-call">
            Outgoing Calls
          </Link>
        </li>
        <li>
          <Link to="/user-guide/telephony-operations/related-record">
            Creating a related record
          </Link>
        </li>
        <li>
          <Link to="/user-guide/telephony-operations/related-user-record">
            Opening a related record and User record
          </Link>
        </li>
        <li>
          <Link to="/user-guide/telephony-operations/disposition-code">
            Disposition Codes
          </Link>
        </li>
      </ol>
    </section>
  </div>
);

const IncomingCallContent = (
  <div>
    <h1 className="docsectiontitle">Incoming Calls</h1>
    <section className="sectionmaterial">
      <p>
        When a customer calls to the Amazon Contact Centre, the call is routed
        to the next available agent based on inputs from the customer. The call
        will flash on the CCP as shown below. The agent can accept or reject the
        call by selecting the relevant button:
      </p>
      <Screenshot src={image71a} />
      <p>
        The Incoming call will display the name or contact number of the
        customer, and a user record, if one exists.
      </p>
      <p>
        The Agent can transfer a current call to another Agent in the queue.
        Also, once transferred, Agent can switch between calls using swap button
        available on CCP. In addition, Calls can be put on hold, or joined to a
        conference.
      </p>
      <Screenshot src={image71b} />
      <Screenshot src={image71c} />
    </section>
  </div>
);

const OutgoingCallContent = (
  <div>
    <h1 className="docsectiontitle">Outgoing Calls</h1>
    <section className="sectionmaterial">
      <p>
        The Agent can make outgoing calls from the Contacts tab, or by using the
        dial pad to enter a phone number plus country code. The CCP will also
        display related records for the Contact selected for an outgoing call.
      </p>
      <Screenshot src={image72a} />
    </section>
  </div>
);

const ClickToDialContent = (
  <div>
    <h1 className="docsectiontitle">Click To Dial</h1>
    <section className="sectionmaterial">
      <p>
        "Click to dial" makes calling easier and faster for your agents, with no
        time wasted on mis-dials. When the agent clicks on any phone field, the
        number is automatically dialled using the CCP. The Agent can use click
        to dial from the User table form and incident, CSM case or HR Case
        record form as shown below. Click to dial will also work if you click on
        the phone icon from the home window of ServiceNow which will open CCP
        and dial the number for the selected contact.
      </p>
      <Screenshot src={image721a} />
      <Screenshot src={image721b} />
    </section>
  </div>
);

const RelatedRecordContent = (
  <div>
    <h1 className="docsectiontitle">Creating a Related Record</h1>
    <section className="sectionmaterial">
      <p>
        The Agent can create a new Incident, CSM Case or HR Case using the +
        button next to the related record box on the CCP. This button will only
        appear on active call when a subject is provided in the Call Log
        section. The subject will be used as a short description for the related
        record.
      </p>
      <p>
        The type of record (Incident or Case) can be selected from the dropdown.
        When the + button is clicked, a new record for the customer will be
        created in ServiceNow.
      </p>
      <Screenshot src={image73a} />
    </section>
  </div>
);

const RelatedUserRecordContent = (
  <div>
    <h1 className="docsectiontitle">
      Opening a Related Record and User Record
    </h1>
    <section className="sectionmaterial">
      <p>
        The Customer related record will flash in the CCP once the agent clicks
        on the icon showed below.
      </p>
      <Screenshot src={image74a} />
      <Screenshot src={image74b} />
    </section>
  </div>
);

const DispositionCodeContent = (
  <div>
    <h1 className="docsectiontitle">Disposition Code</h1>
    <section className="sectionmaterial">
      <p>
        To conclude a call, a Disposition code is required which can be
        customized from the admin console (please refer section 10.3 in this
        document for more details). The Agent can add subject/ notes and then
        save it for each incoming/outgoing call.
      </p>
      <Screenshot src={image75a} />
    </section>
  </div>
);

export const TelephonyOperationsContent = {
  content: TOContent,
  incomingCall: IncomingCallContent,
  outgoingCall: {
    content: OutgoingCallContent,
    clickToDial: ClickToDialContent,
  },
  relatedRecord: RelatedRecordContent,
  relatedUserRecord: RelatedUserRecordContent,
  dispositionCode: DispositionCodeContent,
};
