import Cookies from 'js-cookie';

class StorageService {
  static set(key: string, value: string | object) {
    Cookies.set(key, value, { sameSite: 'strict' });
    // as no expiry is provided, it becomes a session cookie
  }

  static get(key: string) {
    return Cookies.get(key);
  }

  static getJson(key: string) {
    return Cookies.getJSON(key);
  }

  static remove(key: string) {
    Cookies.remove(key);
  }
}

export default StorageService;
