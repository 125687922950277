import React, { useState } from 'react';
import { object as yupObject } from 'yup';
import { useForm } from 'react-hook-form';

import { userShape } from 'schemas/User';
import SimpleLayout from 'layouts/SimpleLayout';
import ReCaptcha, { ReCAPTCHA } from 'react-google-recaptcha';
import Seo from 'components/Seo';
import Clouds from 'components/Clouds';
import Label from 'components/atoms/Label/Label';
import Input from 'components/atoms/Input';
import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import Passwords from 'components/Passwords';
import FieldError from 'components/atoms/FieldError';
import { history } from 'App';
import UserApi from 'api/UserApi';
import useSignal from 'utils/hooks/useSignal';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import Alert from 'components/Alert';
import { getErrorMessage } from 'utils/helpers';

import css from './SignUp.module.scss';
import useAsyncErrJson from 'utils/hooks/useAsyncErrJson';

interface FormData {
  firstname: string;
  lastname: string;
  company: string;
  email: string;
  username: string;
  newPassword: string;
  rePassword: string;
  hasAgreed: boolean;
}

type ValidationFields = Omit<FormData, 'hasAgreed'>;

const validationSchema = yupObject<ValidationFields>({
  firstname: userShape.firstname.label('First name'),
  lastname: userShape.lastname.label('Last name'),
  company: userShape.company.label('Company'),
  email: userShape.email.label('Email'),
  username: userShape.username.label('Username'),
  newPassword: userShape.password.label('Password'),
  rePassword: userShape.rePassword('newPassword').label('Confirm password'),
});

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? '';

const Signup: React.FC = () => {
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const reCaptchaRef = React.createRef<ReCAPTCHA>();

  const {
    register: fieldRef,
    handleSubmit,
    errors: formErr,
    watch,
    getValues,
  } = useForm<FormData>({
    validationSchema,
    defaultValues: {
      hasAgreed: false,
    },
  });

  const hasAgreed = watch('hasAgreed');

  const { signal } = useSignal();

  const formPromise = async () => {
    const values = getValues();
    const user = await UserApi.register(
      { ...values, recaptchaToken },
      { signal }
    );
    return user;
  };

  const { run: sendRequest, current, err } = useAsync(formPromise, {
    onCurrentChange({ current, data, err }) {
      switch (current) {
        case AsyncState.Success:
          const { username } = getValues();
          history.push({
            pathname: '/login',
            state: {
              reason:
                'Your account has been created. Please login to continue.',
              initialValues: {
                username,
              },
            },
          });
          break;
      }
    },
  });
  const isPending = current === AsyncState.Pending;
  const errJson = useAsyncErrJson(current, err, signal);
  const errMsg = getErrorMessage(err, {
    errJson,
  });

  const onSubmit = handleSubmit(async () => {
    reCaptchaRef.current?.reset();
    setRecaptchaToken(null);
    await sendRequest();
  });

  return (
    <SimpleLayout>
      <Seo title="Signup" />
      <div className={css.signupcontainer}>
        <section className={css.signuppanel}>
          <h1 className="pageheading">Create an account</h1>
          <Alert error={errMsg} />
          <form onSubmit={onSubmit} noValidate spellCheck={false}>
            <section className="userform">
              <div className={css.name}>
                <Label text="First name">
                  <Input name="firstname" ref={fieldRef} />
                  <FieldError err={formErr.firstname} />
                </Label>
                <Label text="Last name">
                  <Input name="lastname" ref={fieldRef} />
                  <FieldError err={formErr.lastname} />
                </Label>
              </div>
              <Label text="Company">
                <Input name="company" ref={fieldRef} />
                <FieldError err={formErr.company} />
              </Label>
              <Label text="Email">
                <Input type="email" name="email" ref={fieldRef} />
                <FieldError err={formErr.email} />
              </Label>
              <Label text="Username">
                <Input name="username" ref={fieldRef} />
                <FieldError err={formErr.username} />
              </Label>
              <Passwords
                password={{
                  name: 'newPassword',
                  ref: fieldRef,
                  err: formErr.newPassword,
                }}
                rePassword={{
                  name: 'rePassword',
                  ref: fieldRef,
                  err: formErr.rePassword,
                }}
              />
              <div className={css.recaptcha}>
                <ReCaptcha
                  sitekey={RECAPTCHA_SITE_KEY}
                  ref={reCaptchaRef}
                  onChange={(val: string | null) => setRecaptchaToken(val)}
                  onErrored={() => setRecaptchaToken(null)}
                  onExpired={() => setRecaptchaToken(null)}
                />
              </div>
              <div className={css.eulalink}>
                <Checkbox name="hasAgreed" ref={fieldRef} /> I have read and
                agreed to the{' '}
                <Link to="/docs/eula">End User License Agreement</Link>
              </div>
            </section>
            <Button
              type="submit"
              kind="brand"
              disabled={!hasAgreed || !recaptchaToken}
              loading={isPending}
              className={css.signupbtn}
            >
              Sign Up
            </Button>
          </form>
          <span className={css.signinlink}>
            Already have an account? <Link to="/login">Log In</Link>
          </span>
          <p className={css.policylink}>
            To learn more about how ECS collects, uses, shares and protects your
            personal data, please read ECS's{' '}
            <Link to="/docs/privacy">Privacy and Cookie Policy</Link>
          </p>
        </section>
      </div>
      {/* Clouds */}
      <Clouds />
    </SimpleLayout>
  );
};

export default Signup;
