import { CSSProperties } from 'react';

export interface Node {
  id: string;
  title: string;
  path: string;
  layerCSS: CSSProperties;
  expanded: boolean;
  nodes: Node[];
}

const FirstLayer: CSSProperties = {
  textDecoration: 'none',
};

const SecondLayer: CSSProperties = {
  marginLeft: 15,
  textDecoration: 'none',
};

const ThirdLayer: CSSProperties = {
  marginLeft: 30,
  fontSize: '0.9rem',
  textDecoration: 'none',
};

export const table: Node[] = [
  {
    id: '13',
    title: 'Glossary',
    path: '/glossary',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [],
  },
  {
    id: '1',
    title: 'Introduction',
    path: '/introduction',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [
      {
        id: '1.1',
        title: 'Features',
        path: '/introduction/features',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '1.2',
        title: 'Benefits',
        path: '/introduction/benefits',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
    ],
  },
  {
    id: '2',
    title: 'User Roles',
    path: '/user-roles',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [],
  },
  {
    id: '3',
    title: 'Browser Requirements',
    path: '/system-requirements',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [],
  },
  {
    id: '4',
    title: 'Accessing the Tool',
    path: '/accessing-tool',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [],
  },
  {
    id: '5',
    title: 'CCP Components',
    path: '/ccp-components',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [
      {
        id: '5.1',
        title: 'Status',
        path: '/ccp-components/status',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '5.2',
        title: 'Tabs',
        path: '/ccp-components/tabs',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [
          {
            id: '5.2.1',
            title: 'Favorite Contacts',
            path: '/ccp-components/tabs/fav-contacts',
            layerCSS: ThirdLayer,
            expanded: false,
            nodes: [],
          },
          {
            id: '5.2.2',
            title: 'Contacts',
            path: '/ccp-components/tabs/contacts',
            layerCSS: ThirdLayer,
            expanded: false,
            nodes: [],
          },
          {
            id: '5.2.3',
            title: 'Dial Pad',
            path: '/ccp-components/tabs/dial-pad',
            layerCSS: ThirdLayer,
            expanded: false,
            nodes: [],
          },
          {
            id: '5.2.4',
            title: 'Call History',
            path: '/ccp-components/tabs/call-history',
            layerCSS: ThirdLayer,
            expanded: false,
            nodes: [],
          },
          {
            id: '5.2.5',
            title: 'Active Call',
            path: '/ccp-components/tabs/active-call',
            layerCSS: ThirdLayer,
            expanded: false,
            nodes: [],
          },
        ],
      },
    ],
  },
  {
    id: '6',
    title: 'Telephony Operations',
    path: '/telephony-operations',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [
      {
        id: '6.1',
        title: 'Incoming Calls',
        path: '/telephony-operations/incoming-call',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '6.2',
        title: 'Outgoing Calls',
        path: '/telephony-operations/outgoing-call',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [
          {
            id: '6.2.1',
            title: 'Click To Dial',
            path: '/telephony-operations/outgoing-call/click-to-dial',
            layerCSS: ThirdLayer,
            expanded: false,
            nodes: [],
          },
        ],
      },
      {
        id: '6.3',
        title: 'Creating a Related Record',
        path: '/telephony-operations/related-record',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '6.4',
        title: 'Opening a Related Record and User record',
        path: '/telephony-operations/related-user-record',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '6.5',
        title: 'Disposition Codes',
        path: '/telephony-operations/disposition-code',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
    ],
  },
  {
    id: '7',
    title: 'Call Logs and Recordings',
    path: '/call-logs-recordings',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [
      {
        id: '7.1',
        title: 'Call Logs',
        path: '/call-logs-recordings/call-log',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '7.2',
        title: 'Call Recording Player',
        path: '/call-logs-recordings/call-recording-player',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
    ],
  },
  {
    id: '8',
    title: 'Reporting',
    path: '/reporting',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [
      {
        id: '8.1',
        title: 'Service Centre Dashboard',
        path: '/reporting/service-centre-dashboard',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '8.2',
        title: 'Supervisor Dashboard',
        path: '/reporting/supervisor-dashboard',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '8.3',
        title: 'My Dashboard',
        path: '/reporting/my-dashboard',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
    ],
  },
  {
    id: '9',
    title: 'Administration',
    path: '/administration',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [
      {
        id: '9.1',
        title: 'Amazon Connect Queue Records',
        path: '/administration/amazon-connect-queue-records',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
      {
        id: '9.2',
        title: 'Configure Admin Console',
        path: '/administration/configure-admin-console',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [
          {
            id: '9.2.1',
            title: 'Amazon Connect',
            path: '/administration/configure-admin-console/amazon-connect',
            layerCSS: ThirdLayer,
            expanded: false,
            nodes: [],
          },
          {
            id: '9.2.2',
            title: 'ServiceNow',
            path: '/administration/configure-admin-console/service-now',
            layerCSS: ThirdLayer,
            expanded: false,
            nodes: [],
          },
          {
            id: '9.2.3',
            title: 'RocketCX',
            path: '/administration/configure-admin-console/rocket-cx',
            layerCSS: ThirdLayer,
            expanded: false,
            nodes: [],
          },
        ],
      },
      {
        id: '9.3',
        title: 'Disposition Codes',
        path: '/administration/disposition-codes',
        layerCSS: SecondLayer,
        expanded: false,
        nodes: [],
      },
    ],
  },
  {
    id: '10',
    title: 'Application Logs',
    path: '/application-logs',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [],
  },
  {
    id: '12',
    title: 'Support',
    path: '/support',
    layerCSS: FirstLayer,
    expanded: false,
    nodes: [],
  },
];
