import React, { useState, useMemo } from 'react';

import css from './tabs.module.scss';

interface TabProps {
  label: string;
  initiallyActive?: boolean;
}

export const Tab: React.FC<TabProps> = ({ children }) => {
  return <div className={css.tabcontent}>{children}</div>;
};

/**
 * A simple tabs component. When `initiallyActive` is not provided in any of `Tab`, first one will be selected by default.
 *
 * @example
 * <Tabs>
 *   <Tab label="Account">
 *     <UserProfile />
 *   </Tab>
 *   <Tab label="Projects" initiallyActive>
 *     <Projects />
 *   </Tab>
 *   <Tab label="Billing">
 *     <BillingInfo />
 *   </Tab>
 * </Tabs>
 */
const Tabs: React.FC<{ disabled?: boolean }> = ({ children, disabled }) => {
  const tabs = useMemo(() => {
    // All code below can be replaced with just `React.Children.toArray(children).filter(React.isValidElement)`
    // Have to code like this because of TS complaining about mismatched types
    const childrenArray = React.Children.toArray(children);
    const tabComponents = [];
    for (const child of childrenArray) {
      if (!React.isValidElement(child)) continue;
      tabComponents.push(child);
    }
    return tabComponents;
  }, [children]);

  const tabsLabel = useMemo(() => tabs.map(tab => tab.props.label), [tabs]);

  const [activeIndex, setActiveIndex] = useState(() => {
    for (const [i, tab] of tabs.entries()) {
      if (tab.props.initiallyActive) return i;
    }
    return 0;
  });

  return (
    <div className={css.tabs}>
      <div className={css.tabbtns}>
        {tabsLabel.map((label, i) => (
          <button
            className={`${css.tab} noselect ${
              activeIndex === i ? css.tabisselected : ''
            }`}
            key={i}
            onClick={() => setActiveIndex(i)}
            disabled={disabled ?? false}
          >
            {label}
          </button>
        ))}
      </div>
      {tabs[activeIndex]}
    </div>
  );
};

export default Tabs;
