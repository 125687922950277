import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import Tabs, { Tab } from 'components/atoms/Tabs';
import Info from '../Info';
import InstanceUsage from '../Usage';
import ChangeInstancePassword from '../ChangeInstancePassword';
import Instance from 'schemas/Instance';
import DefaultLayout from 'layouts/DefaultLayout';
import Seo from 'components/Seo';
import useAsync, { AsyncState } from 'utils/hooks/useAsync';
import InstanceApi from 'api/InstanceApi';
import InstancePlaceholder from '../InstancePlaceholder';
import { UserContext } from 'App';
import { UserRole } from 'schemas/User';
import BackButton from 'components/BackButton';
import UnsavedChanges from 'components/UnsavedChanges/UnsavedChanges';
import Alert from 'components/Alert';
import { goBack, getErrorMessage } from 'utils/helpers';
import useAsyncErrJson from 'utils/hooks/useAsyncErrJson';

import css from './Instance.module.scss';

const Container: React.FC = ({ children }) => {
  return (
    <DefaultLayout>
      <Seo title="Instance" />
      <div className={css.instancecontainer}>{children}</div>
    </DefaultLayout>
  );
};

const initialFetchHttpMap = {
  404: 'Instance not found',
  403: "You don't have access to this instance",
};

const InstanceDetails: React.FC = () => {
  const { instanceId } = useParams<{ instanceId: string }>();

  const [instance, setInstance] = useState<Instance | null>(null);
  const [showUnsavedChangesPopup, setUnsavedChangesPopup] = useState<boolean>(
    false
  );
  const [infoDirtyCheck, setInfoDirtyCheck] = useState<boolean>(false);
  const [changePasswordDirtyCheck, setChangePasswordDirtyCheck] = useState<
    boolean
  >(false);

  const { role } = useContext(UserContext);

  const initialFetchPromise = async () => {
    const result = await InstanceApi.get(instanceId);
    return result;
  };

  const { current, err: initialFetchErr } = useAsync(initialFetchPromise, {
    immediate: [],
    onCurrentChange({ current, data, err }) {
      switch (current) {
        case AsyncState.Success:
          const instance = data!;
          setInstance(instance);
          break;
      }
    },
  });
  const initialFetchErrJson = useAsyncErrJson(current, initialFetchErr);

  if (Boolean(initialFetchErr)) {
    return (
      <Container>
        <h2 className="userpageheading">Instance</h2>
        <Alert
          error={getErrorMessage(initialFetchErr, {
            errJson: initialFetchErrJson,
            httpStatusMap: initialFetchHttpMap,
          })}
        />
      </Container>
    );
  }

  if (instance === null)
    return (
      <Container>
        <h1 className="userpageheading">Instance</h1>
        <InstancePlaceholder />
      </Container>
    );

  return (
    <Container>
      <h1 className="userpageheading">
        <BackButton
          onClick={() => {
            if (infoDirtyCheck || changePasswordDirtyCheck)
              setUnsavedChangesPopup(true);
            else goBack();
          }}
        />
        Instance - {instance.name}
      </h1>
      <Tabs disabled={infoDirtyCheck || changePasswordDirtyCheck}>
        <Tab label="Config">
          <Info
            instance={instance}
            onFormBeingModified={dirty => setInfoDirtyCheck(dirty)}
          />
        </Tab>
        <Tab label="Usage">
          <InstanceUsage instance={instance} />
        </Tab>
        {role === UserRole.User && (
          <Tab label="Change Password">
            <ChangeInstancePassword
              instance={instance}
              onFormBeingModified={dirty => setChangePasswordDirtyCheck(dirty)}
            />
          </Tab>
        )}
      </Tabs>
      {showUnsavedChangesPopup && (
        <UnsavedChanges handleClose={() => setUnsavedChangesPopup(false)} />
      )}
    </Container>
  );
};

export default InstanceDetails;
