import React from 'react';

const IntroContent = (
  <div>
    <h1 className="docsectiontitle">Overview</h1>
    <section className="sectionmaterial">
      <p>
        <ul>
          <li>
            <strong>AWS Marketplace Subscribers Guide</strong>:{' '}
            <a
              href="https://docs.aws.amazon.com/marketplace/latest/buyerguide/what-is-marketplace.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://docs.aws.amazon.com/marketplace/latest/buyerguide/what-is-marketplace.html
            </a>
          </li>
          <li>
            <strong>
              RocketCX Enterprise Connector Installation and Configuration Guide
              1.0.0
            </strong>
            :{' '}
            <a href="https://rocketcx.s3.amazonaws.com/EnterpriseConnectorforServiceNow/v1/Documents/Public/RocketCX+Installation+and+Configuration+Guide+1.0.0.docx">
              https://rocketcx.s3.amazonaws.com/EnterpriseConnectorforServiceNow/v1/Documents/Public/RocketCX+Installation+and+Configuration+Guide+1.0.0.docx
            </a>
          </li>
          <li>
            <strong>EULA</strong>:{' '}
            <a href="https://admin.rocket-cx.com/#/docs/eula">
              https://admin.rocket-cx.com/#/docs/eula
            </a>{' '}
          </li>
          <li>
            <strong>Privacy and Cookie Policy</strong>:{' '}
            <a href="https://admin.rocket-cx.com/#/docs/privacy">
              https://admin.rocket-cx.com/#/docs/privacy
            </a>
          </li>
        </ul>
      </p>
    </section>
  </div>
);

export const AdditionalContent = {
  content: IntroContent,
};
