import React from 'react';
import { PluginAdministration } from './ReleaseFeatures/PluginAdministration';
import { ControlPanel } from './ReleaseFeatures/ContactControlPanel';
import { LegacyDashboards } from './ReleaseFeatures/LegacyDashboards';
import { ServiceCentreDashboard } from './ReleaseFeatures/ServiceCentreDashboard';
import { Features } from './ReleaseFeatures/Features';

export const ReleaseFeatures = (
  <section id="release-features">
    <h2 className="sectionsubtitle">2. Release Features</h2>
    <p>
      RocketCX Enterprise Connector is a computer telephony integration (CTI)
      between Amazon Connect and ServiceNow. It provides service centre agents
      with access to a comprehensive set of telephony controls within the
      ServiceNow interface meaning they don’t need to switch screens to make and
      receive calls. RocketCX Enterprise Connector includes several agent
      productivity features such as click to dial, screen pop and automated call
      logging and recording. It also provides service centre supervisors with
      reports and dashboards giving them complete visibility of how the service
      centre is performing.
    </p>
    {Features}
    {PluginAdministration}
    {ControlPanel}
    {LegacyDashboards}
    {ServiceCentreDashboard}
  </section>
);
