import api, { RequestOptions, PaginatedResponse, BasicResponse } from '.';
import User, {
  SubscriptionStatus,
  UserNewPassword,
  UserCreate,
  UserUpdate,
} from 'schemas/User';
import { removeIfNullOrEmpty } from 'utils/helpers';

interface UserListParams {
  text?: string;
  subscriptionStatus: SubscriptionStatus | null;
  pageNo: number;
  pageSize: number;
}

class UserApi {
  static get(userId: string, options: RequestOptions = {}): Promise<User> {
    return api.get(`user/${userId}`, { ...options }).json();
  }

  /**
   * Admin action - list all users
   */
  static list(
    { text, subscriptionStatus, pageNo, pageSize }: UserListParams,
    options: RequestOptions = {}
  ): Promise<PaginatedResponse<User>> {
    const filters = removeIfNullOrEmpty({
      text,
      'subscription-status': subscriptionStatus,
    });

    return api
      .get('user', {
        ...options,
        searchParams: {
          'page-number': pageNo,
          'page-size': pageSize,
          ...filters,
        },
      })
      .json();
  }

  /**
   * Admin action - add a user
   */
  static add(user: UserCreate, options: RequestOptions = {}): Promise<User> {
    return api
      .post('user', {
        ...options,
        json: user,
      })
      .json();
  }

  static update(user: UserUpdate, options: RequestOptions = {}): Promise<User> {
    const { id, ...userAttrs } = user;
    return api
      .put(`user/${id}`, {
        ...options,
        json: userAttrs,
      })
      .json();
  }

  /**
   * Change password by providing old and new ones
   */
  static updatePassword(
    user: UserNewPassword,
    options: RequestOptions = {}
  ): Promise<User> {
    return UserApi.update(user as User, options);
  }

  // Commented out the API for user deletion
  /*
    static delete(userId: string, options: RequestOptions = {}) {
      return api.delete(`user/${userId}`, { ...options })
    }
  */

  /**
   * Signup API
   */
  static register(
    user: UserCreate,
    options: RequestOptions = {}
  ): Promise<BasicResponse> {
    return api.post('registration', { ...options, json: user }).json();
  }

  /**
   * Get a reset password link by email
   */
  static forgotPassword(
    email: string,
    options: RequestOptions = {}
  ): Promise<BasicResponse> {
    return api
      .post('password-reset-request', { ...options, json: { email } })
      .json();
  }

  /**
   * Reset an account's password using verification token
   */
  static resetPassword(
    params: { password: string; verificationToken: string },
    options: RequestOptions = {}
  ): Promise<BasicResponse> {
    const { password, verificationToken } = params;
    return api
      .post('password-reset', {
        ...options,
        json: { password, verificationToken },
      })
      .json();
  }
}

export default UserApi;
